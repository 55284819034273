import {styled, TextField} from "@mui/material";

export const MuiTextField = styled(TextField)(({theme}) => ({
  // height: '56px',
  maxHeight:'56px',
  // minHeight:'56px',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#424242',
   // minHeight:'56px',
    alignContent: 'center',
  },
  '& .MuiOutlinedInput-root': {
    maxHeight:'56px'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
}));
