import {Grid} from "@mui/material";
import CollapsibleCard from "./CollapsibleCard";
import {useTranslation} from "../../../providers/TranslationProvider";
import {MuiTextDate} from "./styles/QAWidget";
import React from "react";


const QAHistory = ({
                     history,
                     widgetId,
                     onDeleteHistory,
                     onSelectHistory,
                     selectedHistory,
                     updateHistoryItem,
                   }) => {
  const {t} = useTranslation();

  const handleCardSelected = (item) => {
    const newSelection = selectedHistory?.userQuery !== item?.userQuery ? item : null;
    if (onSelectHistory) {
      onSelectHistory(newSelection);
    }
  }

  const getDate = (date) => {
    if (!date) return t('long_time_ago')
    const [datePart, timePart] = date.split(" ");


    const [day, month, year] = datePart.split("/").map(Number);

    const [hours, minutes, seconds] = timePart ? timePart.split(":").map(Number) : [0, 0, 0];
    const dateInput = new Date(year, month - 1, day, hours, minutes);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const isToday =
      today.getDate() === dateInput.getDate() &&
      today.getMonth() === dateInput.getMonth() &&
      today.getFullYear() === dateInput.getFullYear();

    const isBetweenYesterdayAndThirtyDaysAgo =
      dateInput >= thirtyDaysAgo && dateInput <= yesterday;

    return isToday ? t('today') : isBetweenYesterdayAndThirtyDaysAgo ? `${day}/${month}/${year}` : t('long_time_ago');
  };


  return (
    <>
      {(history && history.length > 0) && (
        <Grid container
              direction='column'
              sx={{
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                maxHeight: '100%',
                paddingLeft: '0px',
              }}
              rowGap={'0px'}
        >
          {history.map((item, index) => {
            return (
              <Grid direction='column' container item
                    sx={{width: '100%', overflowX: 'hidden'}}>
                {(index === 0 || getDate(item.createdAt) !== getDate(history[index - 1].createdAt)) && (
                  <MuiTextDate sx={{marginTop:index===0?'10px':'24px'}}>
                    {getDate(item.createdAt)}
                  </MuiTextDate>
                )}
                <CollapsibleCard
                  key={`qa-history-${index}-${item.policyId}`}
                  index={index}
                  item={item}
                  expand={selectedHistory?.userQuery === item.userQuery}
                  widgetId={widgetId}
                  onDelete={onDeleteHistory || null}
                  onCardSelected={handleCardSelected}
                  updateHistoryItem={updateHistoryItem || null}
                />
              </Grid>
            )
          })}
        </Grid>
      )
      }
    </>
  )
}

export default QAHistory;
