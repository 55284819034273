import {createTheme} from '@mui/material/styles';

const themeCrowdView = createTheme({
  palette: {
    warning: {
      main: '#FF9800', // Customize the warning color (e.g., orange)
      light: '#FFB74D',
      dark: '#F57C00',
    },
  },
});
export default themeCrowdView
