import * as React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import useOrganization from 'api/hooks/useOrganization';
import logo from '../../../../assets/img/logo.0c7a85ba.png';
import {useTheme} from 'components/providers/CustomThemeProvider';
import {
  MuiContainerNavBar,
  MuiGridContainer,
  MuiGridImgContainer,
  MuiImage,
  MuiTitle,
} from './styles/organization';


const Organization = () => {
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const { data, isLoading } = useOrganization(orgId);
  const { setColors } = useTheme();
  const isSpecificationView = location.pathname.split('/').includes('specification')

  React.useEffect(() => {
    if (data) setColors(data.attrs.primary_color, data.attrs.secondary_color);
  }, [data]);

  return (
    <MuiContainerNavBar id={'OrgContainer'}>
      <MuiGridContainer container >
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <MuiTitle>{data?.name}</MuiTitle>
            {isLoading ? (
              <>
                <MuiImage src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImage src={data?.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <MuiGridContainer item xs={isSpecificationView ? 12 : 9}>
          <Outlet />
        </MuiGridContainer>
      </MuiGridContainer>
    </MuiContainerNavBar>
  );
};

export default Organization;
