import {Grid, Link, styled} from '@mui/material';

export const MuiGridSelectBar = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),

  '& .MuiTypography-caption': {
    color: theme.palette.text.secondary,
  },

  '& .MuiButton-label': {
    ...theme.typography.caption,
  },
}));

export const MuiLink  = styled(Link)(({ theme }) => ({
  ...theme.typography.caption,
  cursor: 'pointer',
}));

