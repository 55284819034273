import {
  Autocomplete,
  IconButton, lighten,
  styled,
  Switch,
  TextField
} from "@mui/material";
import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {darken} from "@mui/material/styles";

export const MuiSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: '3px 0px 3px 3px',
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      padding:0,
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.palette.primary.main,
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb': {
        width: 22,
        height: 22,
        backgroundColor:'#FFFFFF'
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    backgroundColor:darken(theme.palette.primary.main,0.3),
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: lighten(theme.palette.primary.main,0.8),
    border:`1px solid ${darken(theme.palette.primary.main,0.5)}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export const MuiAutocomplete = styled(
  (props) => (
    <Autocomplete
      {...props}
      multiple={false}
      variant="outlined"
      getOptionLabel={(option) => option.label || option.propName}
      isOptionEqualToValue={(option, value) => option.propName === value.propName}
      filterSelectedOptions
      renderInput={props.renderInput}
    />
  )
)(({theme}) => ({
  height: '56px',
  flex:1,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#325D6C', // Custom border color
      borderWidth: '1px', // Custom border width
    },
    '&:hover fieldset': {
      borderColor: '#143440', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#254957', // Border color when focused
    },
  },
  '& .MuiInputBase-root': {
    height: '56px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '4px 4px 0 0',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  },
  '& .MuiInputLabel-shrink': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
  '&.MuiInputLabel-root':{
    fontFamily: 'Montserrat',
    fontSize: '16px !important',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  }
}));

export const MuiInfoIconButton = styled(IconButton)(({theme}) => ({
  width: '16px',
  height: '16px',
  marginLeft:'8px'
}));

export const MuiInfoOutlinedIcon = styled(InfoOutlinedIcon)(({theme}) => ({
  width: '16px',
  height: '16px',
  color: '#9E9E9E',
}));

export const MuiQuestionInfoTextField = styled(TextField)(({theme}) => ({
  minHeight: '56px',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440',
    alignItems:'center'
  },
  '& .MuiInputBase-root': {
    display: 'flex',
    alignItems:'center',
  },
  '& .MuiInputLabel-shrink': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
  '&.MuiInputLabel-root':{
    fontFamily: 'Montserrat',
    fontSize: '16px !important',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440',
  },
  '& .MuiInputBase-inputMultiline':{
    alignContent:'center',
  },
}));
