import axios from './axios/axiosInstance';
import apiPaths from './apiPaths';

export const uploadCollectionS3 = (data, onError, OnSuccess) => {
  return axios.post(apiPaths.upload_collection_s3, data);
};

export const getImportJob = (id) => {
  return axios.get(`${apiPaths.get_import_job}?id=${id}`);
};

