import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import {useTranslation} from "../../../providers/TranslationProvider";
import ListItemIcon from "@mui/icons-material/ListAlt";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import {useEffect, useState} from "react";
import {QAHint} from "./QAHint";

export const QAHints = ({
                          setQuestion,
                          setIsLoading,
                          hints
                        }) => {
  const hintColor = [
    {
      backgroundColor: '#FFCDD2',
      color: '#F44336'
    },
    {
      backgroundColor: '#D1C4E9',
      color: '#673AB7'
    },
    {
      backgroundColor: '#B3E5FC',
      color: '#03A9F4'
    },
    {
      backgroundColor: '#F0F4C3',
      color: '#9E9D24'
    }
  ]
  const genericHints = {
    english: [
      {
        title: 'About topic analysis',
        question: 'What are the main topics discussed in the responses?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About respondents' perceptions",
        question: "Are there predominant feelings (positive, negative, neutral) in the responses?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About improvements and actions",
        question: "What types of suggestions or recommendations do participants make most frequently?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    spanish: [
      {
        title: 'Acerca del análisis de temas',
        question: '¿Cuáles son los temas principales discutidos en las respuestas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Acerca de las percepciones de los encuestados',
        question: '¿Existen sentimientos predominantes (positivos, negativos, neutrales) en las respuestas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Acerca de mejoras y acciones',
        question: '¿Qué tipos de sugerencias o recomendaciones hacen los participantes con mayor frecuencia?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    french: [
      {
        title: "À propos de l'analyse des sujets",
        question: 'Quels sont les principaux sujets discutés dans les réponses ?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'À propos des perceptions des répondants',
        question: 'Y a-t-il des sentiments prédominants (positifs, négatifs, neutres) dans les réponses ?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'À propos des améliorations et actions',
        question: 'Quels types de suggestions ou recommandations les participants font-ils le plus souvent ?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    portuguese: [
      {
        title: 'Sobre a análise de tópicos',
        question: 'Quais são os principais tópicos discutidos nas respostas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Sobre as percepções dos respondentes',
        question: 'Existem sentimentos predominantes (positivos, negativos, neutros) nas respostas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Sobre melhorias e ações',
        question: 'Quais tipos de sugestões ou recomendações os participantes fazem com mais frequência?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    arabic: [
      {
        title: 'About topic analysis',
        question: 'What are the main topics discussed in the responses?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About respondents' perceptions",
        question: "Are there predominant feelings (positive, negative, neutral) in the responses?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About improvements and actions",
        question: "What types of suggestions or recommendations do participants make most frequently?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
  };
  const {t, lng} = useTranslation();
  const [hintCardsContent, setHintCardsContent] = useState([]);


  useEffect(() => {
    setIsLoading(true);
    setHintCardsContent([]);
    if (hints?.length > 0) {
      setHintCardsContent(hints.map((hint, index) => {
        return {
          ...hint,
          icon: (
            <Box sx={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              width: '26px',
              height: '26px',
              minHeight: '26px',
              borderRadius: '50%',
              backgroundColor: hintColor[index % hintColor.length].backgroundColor,
            }}>
              <img
                src={`data:image/svg+xml;base64,${hint.icon}`}
                alt={'hint'}
                style={
                  {
                    color: hintColor[index % hintColor.length].color,
                    width: '16px',
                    height: '16px'
                  }
                }
              />
            </Box>
          )
        };
      }));
      setIsLoading(false);
    } else {
      setHintCardsContent(genericHints[lng] || genericHints.english);
      setIsLoading(false);
    }
  }, []);


  const getHintsDescription = () => {
    let originalText = t('qa_hints_description');
    const wordsToBold = {
      english: ["qualitative questions", "deeper information", "perceptions", "relevant patterns"],
      spanish: ["preguntas cualitativas", "información más detallada", "percepciones", "patrones relevantes"],
      portuguese: ["perguntas qualitativas", "informações mais profundas", "percepcões", "padrões relevantes"],
      french: ["questions qualitatives", "informations plus approfondies", "perceptions", "modèles pertinents"],
      arabic: ["qualitative questions", "deeper information", "perceptions", "relevant patterns"]
    }
    const regex = new RegExp(`(${wordsToBold[lng].join("|")})`, "gi");
    return originalText.split(regex).map((part, index) => {
      if (wordsToBold[lng].some(word => word.toLowerCase() === part.toLowerCase())) {
        return (
          <Box key={index} component="span" fontWeight="fontWeightBold">
            {part}
          </Box>
        );
      }
      // Otherwise, return the text as normal
      return part;
    })
  }

  return (
    <Grid container direction='row' columnGap={'16px'} rowGap={'16px'} sx={{
      width: '100%'
    }}>
      {hintCardsContent.length > 0 ? hintCardsContent.map((item, index) => (
        <Grid container item sx={{width: '45%', minWidth: '150px'}}
              key={`hint-card-${index}`}>
          <QAHint
            title={item.title}
            question={item.question}
            icon={item.icon}
            setQuestion={setQuestion}
          />
        </Grid>
      )) : (
        <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
        }}>
          <CircularProgress color={'primary'}/>
        </Box>
      )}
      <Grid sx={{width: '100%', mt: '24px'}}>
        <Typography sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: 400,
          textAlign: 'center',
          color: '#616161',
          letterSpacing: '0.0025em'
        }}>{getHintsDescription()}</Typography>
      </Grid>
    </Grid>
  )
}
