import {styled} from "@mui/material";

export const MuiEmptyTopicsHeadingContainer=styled("div")(({theme}) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  display: 'inline', // Change this to 'inline' or 'inline-block'
  alignItems: 'center',
  textAlign: 'center',
  letterSpacing: '0.0025em',
  color: "#616161",
  whiteSpace: 'normal', // Ensure text can wrap when needed
  wordWrap: 'break-word', // Ensure long words can break onto the next line
}));

export const MuiHighlightedText = styled("span")(({theme}) => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase'
}))
