import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import axios from '../../api/axios/axiosInstance';
import apiPaths from '../../api/apiPaths';
import {status200} from '../../api/status.utils';
import TokenInterceptor from '../common/TokenInterceptor';
import {useTranslation} from 'components/providers/TranslationProvider';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup
} from 'firebase/auth';

const AuthContext = React.createContext();

const AuthProvider = ({children}) => {
  const getAccessToken = React.useCallback(() => localStorage.getItem('accessToken'), []);
  const [isPending, setIsPending] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(!!getAccessToken());
  const [openDialog, setOpenDialog] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState(
    localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : ''
  );
  const [engineToken, setEngineToken] = React.useState(
    localStorage.getItem('engineToken') ? localStorage.getItem('engineToken') : ''
  )
  const [engineTokenExpiration, setEngineTokenExpiration] = React.useState(
    localStorage.getItem('engineTokenExpiration') ? localStorage.getItem('engineTokenExpiration') : ''
  )
  const navigate = useNavigate();
  const {setForceFetch} = useTranslation();

  const addUser = (user) => {
    const {_id: value, ...restUser} = user;
    const userNewReplacedId = {...restUser, id: value};
    localStorage.setItem('user', JSON.stringify(userNewReplacedId));
  };

  const getUser = React.useCallback(() => JSON.parse(localStorage.getItem('user')), []);

  const removeUser = () => localStorage.removeItem('user');

  const addTokens = (accessToken, refreshToken) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  const addEngineToken = (engineToken, engineTokenExpiration) => {
    localStorage.setItem('engineToken', engineToken);
    localStorage.setItem('engineTokenExpiration', engineTokenExpiration);
    setEngineToken(engineToken);
    setEngineTokenExpiration(engineTokenExpiration);
  };

  const getRefreshToken = React.useCallback(() => localStorage.getItem('refreshToken'), []);

  const getEngineToken = React.useCallback(() => localStorage.getItem('engineToken')
    , []);

  const removeTokensInLocalStorage = React.useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, []);

  const login = React.useCallback(
    (data, redirectUrl, onError) => {
      removeTokensInLocalStorage();
      setIsPending(true);
      axios
        .post(apiPaths.login, data, status200)
        .then((response) => {
          const {accessToken: accToken, refreshToken, user} = response.data;

          if (user.added_by_admin) {
            setIsPending(false);
            return navigate('/reset', {state: {accessToken: accToken, user}});
          }

          addUser(user);
          addTokens(accToken, refreshToken);
          setAccessToken(accToken);
          setIsAuthenticated(true);
          setIsPending(false);
          navigate(user.type === 'admin' || user.type === 'super_admin'
          || user.orgs_user_level.length !== 0
            ? '/orgs' : `/orgs/${user.org_id}/scenarios`);
          setForceFetch();

          return null;
        })
        .catch((e) => {
          onError && onError(e?.data?.error || 'error');
          setIsPending(false);
        });


    },
    [navigate, setForceFetch, removeTokensInLocalStorage]
  );

  useEffect(() => {
    const token = localStorage.getItem('engineToken');
    const tokenExpiration = localStorage.getItem('engineTokenExpiration');
    if (token && tokenExpiration) {
      const expirationDate = new Date(tokenExpiration);
      if (expirationDate > new Date()) {
        setEngineToken(token);
        setEngineTokenExpiration(tokenExpiration);
      }else{
        localStorage.removeItem('engineToken');
        localStorage.removeItem('engineTokenExpiration');
        setEngineToken(null);
        setEngineTokenExpiration(null);
        axios.request({
          method: 'post',
          url: apiPaths.engine_token,
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }).then(resp=>{
          const tokenData = resp.data;
          if (tokenData.token) {
            addEngineToken(tokenData.token,tokenData.expiration_date);
          }
        })
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      axios.request( {
        method: 'post',
        url: apiPaths.engine_token,
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((resp) => {
        const tokenData = resp.data;
        if (tokenData.token) {
          addEngineToken(tokenData.token,tokenData.expiration_date);
        }
      })

    }
  }, [isAuthenticated, accessToken])

  const updateTokens = React.useCallback(async () => {
    return axios
      .post(apiPaths.refreshToken, {refreshToken: getRefreshToken()})
      .then((resp) => {
        removeTokensInLocalStorage();
        addTokens(resp.data.accessToken, resp.data.refreshToken);
        return true;
      })
      .catch((e) => {
        removeTokensInLocalStorage();
        removeUser();
        return false;
      });
  }, []);

  const logout = React.useCallback(() => {
    setIsAuthenticated(false);
    removeTokensInLocalStorage();
    setAccessToken(null);
    removeUser();
    navigate('/login');
  }, [navigate]);

  const forgotPassword = React.useCallback((data, onError, onSuccess) => {
    setIsPending(true);
    axios
      .post(apiPaths.forgotPassword, data, status200)
      .then((resp) => {
        setIsPending(false);
        onSuccess();
        return resp.data;
      })
      .catch((e) => {
        setIsPending(false);
        onError(e.data.error);
      });
    //TODO
  }, []);

  const loginWithSocial = React.useCallback(
    (provider, redirectUrl, onError) => {
      setIsPending(true);
      const auth = getAuth();
      auth.useDeviceLanguage();
      let signInWith;

      if (provider === 'google') {
        const googleProvider = new GoogleAuthProvider();
        signInWith = signInWithPopup(auth, googleProvider);
      } else {
        const microsoftProvider = new OAuthProvider('microsoft.com');
        signInWith = signInWithPopup(auth, microsoftProvider);
      }

      signInWith
        .then((result) => {
          let credential = null;

          if (provider === 'google') {
            credential = GoogleAuthProvider.credentialFromResult(result);
          } else {
            credential = OAuthProvider.credentialFromResult(result);
          }

          const token = credential.accessToken;
          const data = {token, user: result.user, provider};
          axios
            .post(apiPaths.loginSocial, data, status200)
            .then((response) => {
              const {accessToken: accToken, refreshToken, user} = response.data;
              addUser(user);
              addTokens(accToken, refreshToken);
              setAccessToken(accToken);
              setIsAuthenticated(true);
              setIsPending(false);
              navigate(redirectUrl);
              setForceFetch();
            })
            .catch((e) => {
              if (e.data) {
                if (e.data.error === 'login_user_not_allowed') {
                  setOpenDialog(true);
                } else {
                  onError(e.data.error);
                }
              }

              setIsPending(false);
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          let credential;

          if (provider === 'google') {
            credential = GoogleAuthProvider.credentialFromError(error);
          } else {
            credential = OAuthProvider.credentialFromError(error);
          }

          let message = '';
          switch (errorCode) {
            case 'auth/network-request-failed':
              message = 'network_request_failed';
              break;
            case 'auth/popup-closed-by-user':
              message = 'popup_closed_by_user';
              break;
            default:
              break;
          }

          setIsPending(false);
          onError(error.message ? error.message : message);
          console.log({errorCode, errorMessage, email, credential});
        });
    },
    [navigate, setForceFetch]
  );

  React.useEffect(() => {
    setIsAuthenticated(Boolean(getAccessToken()));
    setIsPending(false);
  }, [getAccessToken]);

  const value = React.useMemo(
    () => ({
      login,
      logout,
      forgotPassword,
      isAuthenticated,
      isPending,
      getAccessToken,
      getRefreshToken,
      getEngineToken,
      updateTokens,
      user: getUser(),
      loginWithSocial,
      openDialog,
      setOpenDialog,
    }),
    [
      login,
      logout,
      isAuthenticated,
      isPending,
      forgotPassword,
      getAccessToken,
      getRefreshToken,
      getEngineToken,
      updateTokens,
      getUser,
      loginWithSocial,
      openDialog,
      setOpenDialog,
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      <TokenInterceptor accessToken={accessToken} engineToken={getEngineToken}>{children}</TokenInterceptor>
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export {AuthContext, useAuth};
export default AuthProvider;
