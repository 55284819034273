import {Grid} from "@mui/material";
import {TopicForm} from "./TopicForm";
import {TopicActions} from "./TopicActions";
import * as React from "react";

export const Subtopic = ({
                           topic,
                           index,
                           handleTopicChange,
                           handleDelete,
                           answerVariable,
                           collection,
                           dataRegion
                         }) => {

  return (
    <Grid container item direction='row' sx={{
      height: '83px',
      backgroundColor: '#F0F0F0',
      pl: '12px',
      pr: '12px'
    }} alignContent='center' alignItems='center'>
      {/*<Grid item sx={{mr: '12px',ml:'44px',}} alignContent='center' xs={"auto"}>*/}
      {/*  {topic.icon ? (*/}
      {/*    <TopicIcon base64={topic.icon}/>*/}
      {/*  ) : null}*/}
      {/*</Grid>*/}
      <Grid item xs={true}>
        <TopicForm topic={topic} index={index} isRootTopic={false}
                   handleTopicChange={handleTopicChange}/>
      </Grid>
      <Grid item sx={{width: '90px'}}>
        <TopicActions topic={topic} index={index} isRootTopic={false}
                      rootIsConditioned={false}
                      handleDelete={handleDelete}
                      handleGenerateSubtopics={null}
                      answerVariable={answerVariable} collection={collection}
                      dataRegion={dataRegion}/>
      </Grid>
    </Grid>
  )
}
