import {
  Autocomplete, Button,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import {alpha, darken} from "@mui/material/styles";
import * as React from "react";

export const MUIToggleGroup = styled(ToggleButtonGroup)(({theme}) => {
  return {
    height: '47px',
    maxWidth: '100%',
    borderRadius: '24px',
    border: `1px solid ${theme.palette.primary.main}`,
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    padding: '4px',
    gap: '4px'
  }
});

export const MuiSelectedToggleButton = styled(ToggleButton)(({theme}) => {
  return {
    height: '39px',
    width: '281px',
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0.001em',
    textTransform: 'none',
    gap: '10px',
    '.MuiToggleButton-root': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      borderRadius: '20px',
      padding: '4.5px 12px',
      // maxWidth:'170px'
    },
    '&.MuiToggleButton-root:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  };
});

export const MuiToggleButton = styled(ToggleButton)(({theme}) => {
  const textColor = darken(theme.palette.primary.main, 0.2);
  return {
    width: '281px',
    backgroundColor: 'transparent',
    color: textColor,
    borderColor: 'transparent',
    height: '39px',
    borderRadius: '20px',
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0.004em',
    textTransform: 'none',
    gap: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: textColor,
      borderRadius: '20px',
      height: '39px',
    },
    '.MuiToggleButton-root': {
      borderRadius: '20px'
    }
  };
});

export const MuiTooltipTypography = styled(Typography)(({theme}) => {
  return {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0.004em",
  };
});

export const MuiAutocomplete = styled(
  (props) => (
    <Autocomplete
      {...props}
      multiple={false}
      variant="outlined"
      filterSelectedOptions
      renderInput={props.renderInput}
    />
  )
)(({theme}) => ({
  height: '56px',
  flex: 1,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#325D6C', // Custom border color
      borderWidth: '1px', // Custom border width
    },
    '&:hover fieldset': {
      borderColor: '#143440', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#254957', // Border color when focused
    },
  },
  '& .MuiInputBase-root': {
    height: '56px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '4px 4px 0 0',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.005em',
    color: '#424242',
    height: '20px',
    padding: '0px'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  }
}));

export const MuiUploadButton = styled(Button)(({theme}) => ({
  height: '26px',
  fontFamily: 'Montserrat',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '12px',
  letterSpacing: '0.0025em',
  textTransform: 'uppercase',
  borderRadius: '13px',
  border: `1px solid ${darken(theme.palette.primary.main, 0.3)}`,
  color:darken(theme.palette.primary.main, 0.3)
}))
