import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  styled,
  Typography
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export const MuiIconButton = styled(IconButton)(({theme}) => ({
  color: theme.palette.text.secondary,
  margin: theme.spacing(-0.75, 0)
}));

export const MuiLinearProgress = styled(LinearProgress)(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: theme.spacing(0.25)
}));

export const MuiBoxRoot = styled(Box)(({theme}) => ({
  position: 'relative',
  maxWidth: '100%',
  padding: ({margin}) => (margin !== undefined ? margin : theme.spacing(2, 1.5))
}));

export const MuiBoxContent = styled(Box)(({theme}) => ({
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  width: '100%',
  maxWidth:'100%',
  overflowX: 'hidden'
}));

export const MuiExpandLess = styled(ExpandLess)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.text.secondary
}));

export const MuiExpandMore = styled(ExpandMore)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.text.secondary
}));

export const MuiTypography = styled(Typography)(({theme}) => ({
  wordBreak: 'break-word',
  textTransform: 'none',
  fontWeight:700,
  fontStyle:'bold',
  fontSize: '14px',
  marginLeft: '7px',
  fontFamily:'Raleway',
  color:'#424242',
  overflow: 'hidden',
}));

export const MuiGridHeader = styled(Grid)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  padding: 0
}));

export const MuiGridActions = styled(Grid)(({theme}) => ({
  display: 'flex',
  marginLeft: theme.spacing(1),
  height: 24
}));

export const MuiPaper = styled(Paper)(({theme}) => ({
  marginTop: theme.spacing(6),
  maxHeight: theme.spacing(21),
  minWidth: theme.spacing(16)
}));
