import {useEffect, useRef, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import {debounce} from "lodash";

function useContainerDimensions() {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = ref.current;

    const resizeObserver = new ResizeObserver(debounce((entries) => {
      entries.forEach(entry => {
        const newWidth = entry.contentRect.width;
        const newHeight = entry.contentRect.height;

        // Check if the new dimensions differ from the old ones by more than 10px
        if (newWidth>0 && newHeight>0) {
          setDimensions({ width: newWidth, height: newHeight });
        }
      });
    },250));

    resizeObserver.observe(observeTarget);

    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, []);

  return [ref, dimensions];
}

export {
  useContainerDimensions
}
