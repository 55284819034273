import * as React from 'react';
import _ from 'lodash';
import {Box, Button, Grid, Paper, Typography} from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useTranslation} from '../../../../../../providers/TranslationProvider';
import TopicImageManager from "./art/ImageGeneration";
import QuestionInfo from "./general/QuestionInfo";
import {TopicManagement} from "./topics/TopicManagement";
import {AISegments} from "./aiSegments/AISegments";
import {
  labelStepStyle,
  MuiBoxContainer,
  MuiBtnNext,
  MuiCustomStepIcon
} from "./styles/question";


export default function Question({
                                   question,
                                   questionIndex,
                                   existingQuestionsCount,
                                   nextQuestion,
                                   dataRegion,
                                   parentCandidates,
                                   variables,
                                   handleQuestionChange,
                                   collection,
                                   weight,
                                   analysisObjective,
                                   contextDescription,
                                   audienceDescription
                                 }) {
  const {t} = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if (canEnterStep(step - 1)) {
      setActiveStep(step);
    }
  };

  const canEnterStep = (step) => {
    if (step >= 0 && !question.name) return false;
    if (step >= 0 && !question.answerVar) return false;
    if (step >= 1 && !question.topics || !question.topics.length === 0) return false;
    if (step >= 1 && !question.classified) return false;
    if (step >= 3 && question.customSegmentations && question.customSegmentations?.segmentations?.length > 0) {
      if (!question.customSegmentations.segmentations.reduce(cs => cs.classificationPerformed, true)) return false;
    }
    return true
  }
  const questionValid = () => {
    return canEnterStep(activeStep);
  }

// Stepper Form Children
  const steps = question ? [
    {
      label: `${t('survey_question')}`,
      visible: true,
      description: (
        <Grid container spacing={2}>
          <QuestionInfo
            question={question}
            variables={variables}
            handleQuestionChange={handleQuestionChange}
          />
        </Grid>
      ),
      dataCy: 'step-policy-name',
    },
    {
      label: t('discussed_topics'),
      dataCy: 'step-topic-inference',
      visible: true,
      description: (
        <TopicManagement
          question={question}
          variables={variables}
          handleQuestionChange={handleQuestionChange}
          dataRegion={dataRegion}
          collection={collection}
          parentCandidates={parentCandidates}
          weight={weight}
          analysisObjective={analysisObjective}
          contextDescription={contextDescription}
          audienceDescription={audienceDescription}
        />
      )
    },
    {
      label: 'Topic images',
      dataCy: 'step-image-section',
      visible: true,
      description: (
        <TopicImageManager policy={question} dataset={collection}
                           answerVar={question.answerVar}
                           updatePolicy={(updatedPolicy) => {
                             const localPolicy = _.cloneDeep(question);
                             console.log("Updated policy", updatedPolicy);
                             localPolicy.topics = updatedPolicy.topics;
                             localPolicy.imageSettings = updatedPolicy.imageSettings;
                             handleQuestionChange(localPolicy);
                           }}
                           analysisObjective={analysisObjective}
                           contextDescription={contextDescription}
                           audienceDescription={audienceDescription}
        />
      )
    },
    {
      label: t('custom_segmentation'),
      dataCy: 'step-category-section',
      visible: true,
      description: (
        <AISegments question={question} collection={collection}
                    dataRegion={dataRegion}
                    handleQuestionChange={handleQuestionChange}/>
      )
    },

  ] : [];

  const NextStepControl = () => {
    if (activeStep < steps.length - 1) {
      return (
        <MuiBtnNext
          color='primary'
          variant="outlined"
          disabled={!questionValid()}
          onClick={handleNext}>
          {t('continue')}
        </MuiBtnNext>
      )
    } else if (questionIndex < existingQuestionsCount - 1) {
      return (
        <MuiBtnNext
          color='primary'
          variant="outlined"
          disabled={!questionValid()}
          onClick={() => nextQuestion(questionIndex + 1)}
        >
          {t('next_question') || 'Next Question'}
        </MuiBtnNext>
      )
    }
    return null;
  }
  return (
    <>
      <MuiBoxContainer id={'stepper-box'} sx={{width: '100% !important', maxWidth: '100% !important'}}>
        <Grid container direction='column' sx={{width:'100%',maxWidth:'100%'}}>
          <Grid item xs={true}>
            <Stepper activeStep={activeStep} sx={{paddingLeft: 1, paddingRight: 1}}
                     orientation="horizontal">
              {steps.filter(step => step.visible).map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    StepIconComponent={MuiCustomStepIcon}
                    data-cy={step.dataCy}
                    onClick={handleStep(index)}
                    style={{cursor: 'pointer'}}
                    sx={{
                      '& .MuiStepIcon-text': {
                        fill: '#FFFFFF',
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        fontFamily: 'Montserrat'
                      }
                    }}
                  >
                <span style={{
                  ...labelStepStyle,
                  color: index === activeStep ? '#212121' : '#9E9E9E'
                }}
                >
                  {step.label}
                </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          {steps?.length > 0 && (
            <Grid container spacing={1} id={'step-content-container'} item
                  xs={true} sx={{
              paddingRight: '8px',
              paddingTop: '8px',
            }}>
              <Grid item xs={12} id={'step-description-container'}>
                <Box sx={{marginLeft:'16px'}}>{steps[activeStep].description}</Box>
              </Grid>
              <Grid
                style={{marginTop: '24px', paddingBottom: '10px'}}
                container item xs={12} justifyContent="flex-end">
                <Button
                  style={{marginLeft: 6, fontFamily:'Montserrat'}}
                  variant="text"
                  disabled={activeStep === 0}
                  onClick={handleBack}>
                  {t('go_back')}
                </Button>
                {<NextStepControl/>}
              </Grid>
            </Grid>
          )}
          {(steps.length > 0 && activeStep === steps.length) && (
            <Grid item xs={true} lg={true} md={true}>
              <Paper square elevation={0} sx={{p: 3}}>
                <Typography>All steps completed</Typography>
              </Paper>
            </Grid>
          )}
        </Grid>

      </MuiBoxContainer>
    </>
  );
}
