import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import CategoryWidgetUI from './CategoryWidgetUI';
import WrapperWidgetUI from '../WrapperWidgetUI';
import {
  setAnalysisVisualizationType,
  setReferenceAggTopicSegment,
  setSegmentationFilter,
  setUpdateQA
} from '../../../../store/appSlice';
import {status200} from '../../../../api/status.utils';
import {useTheme} from "@mui/material";
import {MuiInfoOutlinedIcon} from '../../styles/categoryWidgetUI';
import {useCache} from "../../../providers/CacheContext";
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";
import Compare from '@mui/icons-material/CompareArrows';
import PopoverDescription from "../PopoverDescription";

/**
 * Renders a <CategoryWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {string | string[]} [props.operationColumn] - Name of the data source's column to operate with. If not defined it will default to the one defined in `column`. If multiples are provided, they will be merged into a single one using joinOperation property.
 * @param  {string} props.operation - Operation to apply to the operationColumn. Must be one of those defined in `AggregationTypes` object.
 * @param  {Function} [props.formatter] - Function to format each value returned.
 * @param  {Object} [props.labels] - Overwrite category labels.
 * @param  {boolean} [props.filterable] - Enable/disable widget filtering capabilities. Enabled by default.
 * @param  {boolean} [props.searchable] - Enable/disable widget searching capabilities. Enabled by default.
 * @param  {boolean} [props.global] - Enable/disable the viewport filtering in the data fetching.
 * @param  {Function} [props.onError] - Function to handle error messages from the widget.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 * @param  {Object} [props.noDataAlertProps] - Extra props to pass to [NoDataAlert]()
 * @param  {Object} [props.droppingFeaturesAlertProps] - Extra props to pass to [NoDataAlert]() when dropping feature
 */
function CategoryWidget(props) {
  const {
    id,
    title,
    column,//si
    widgetType = 'category',
    answerVar,
    dataSource,//si
    dataRegion='US',
    wrapperProps,//si
    enableSegmentationAggregation,
    weight,//si
    policyId,
    description
  } = props;
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [location, setLocation] = useState(null);
  const folded = useSelector(state => state.app.widgetFoldedState[id]);
  const activeCategoryFilters = useSelector(state => state.app.segmentationFilter);
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeInsightFilters = useSelector(state => state.app.insightsFilter);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const referenceAggTopicSegment = useSelector(state => state.app.referenceAggTopicSegment);
  const [policyReferenceSegment, setPolicyReferenceSegment] = useState(referenceAggTopicSegment[policyId] || null);
  const analysisVisualizationType = useSelector(state => state.app.analysisVisualizationType);
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const theme = useTheme();
  const {cache, setCacheData} = useCache();

  const fetchData = async (data) => {
    const cache_key = JSON.stringify(data);
    if (cache[cache_key]) {
      return cache[cache_key]
    }
    const respData = await axiosEngineInstance.post(enginePaths.aggregate_category, data, status200).then((resp) => {
      return resp.data
    });
    setCacheData(cache_key, respData)
    return respData
  }

  useEffect(async () => {
    if (folded) {
      return
    }
    setIsLoading(true);
    let localSegmentationFilter = {...activeCategoryFilters, ...(activeInsightFilters[policyId] || {})}
    delete localSegmentationFilter[column];
    let localStatisticFilter = {...activeStatisticFilters}
    let localSimilarityQuery = similarityQuery[policyId] || {}
    let localSimilarityFilters = activeSimilarityFilters[policyId] || {}
    if (treeMapFilter) {
      Object.keys(treeMapFilter).forEach(key => {
        Object.assign(localSegmentationFilter, treeMapFilter[key])
      });
    }
    let localData = await new Promise((resolve) => {
      const requestData = {
        surveyId: dataSource,
        attribute: column,
        segmentation: localSegmentationFilter,
        statistics: localStatisticFilter,
        weight: weight,
        questionId: answerVar,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilters,
        region: dataRegion||"US",
      };
      let response = fetchData(requestData).then((resp) => {
        return resp
      }).catch((error) => {
        console.log("Error fetching categories for ", column, ": ", error);
        return {
          rows: []
        };
      })
      resolve(response)
    });
    if (localData && localData.rows?.length > 0) {
      setData(localData.rows);
    }
    setIsLoading(false);
  }, [dataSource, column, weight, activeCategoryFilters, activeStatisticFilters, selectedCategories, activeSimilarityFilters, similarityQuery, treeMapFilter, activeInsightFilters, folded]);

  useEffect(() => {
    if (column && activeCategoryFilters) {
      let localSegmentationFilter = {...activeCategoryFilters}
      let found = false;
      for (var prop in localSegmentationFilter) {
        if (prop === column) {
          found = true;
          setSelectedCategories([...localSegmentationFilter[prop].map((d) => `${d}`)]);
        }
      }
      if (!found) {
        setSelectedCategories([]);
      }
    }

  }, [column, activeCategoryFilters])


  const handleSelectedCategoriesChange = (categories) => {
    let localSegmentationFilter = {...activeCategoryFilters}
    if (categories.length > 0) {
      localSegmentationFilter[column] = data.map((d) => d.name).filter((d) => categories.includes(`${d}`));
    } else {
      delete localSegmentationFilter[column];
    }
    dispatch(setSegmentationFilter(localSegmentationFilter));
    dispatch(setUpdateQA(true));
    setSelectedCategories(categories);
  }

  const updateReferenceAggTopicSegment = () => {
    const updatedReferenceAggTopicSegment = {
      ...referenceAggTopicSegment
    }
    let currentValue = updatedReferenceAggTopicSegment[policyId] || null
    let newValue = currentValue !== column ? column : null
    setPolicyReferenceSegment(newValue);
    dispatch(setReferenceAggTopicSegment({[policyId]: newValue}))
    if (analysisVisualizationType[policyId] !== "bars") {
      const updatedAnalysisVisualizationType = {
        ...analysisVisualizationType
      }
      updatedAnalysisVisualizationType[policyId] = "bars"
      dispatch(setAnalysisVisualizationType(updatedAnalysisVisualizationType))
    }
  }

  useEffect(() => {
    if (enableSegmentationAggregation) {
      let currentValue = referenceAggTopicSegment[policyId] || null;
      if (currentValue !== policyReferenceSegment) {
        setPolicyReferenceSegment(currentValue);
      }
    }
  }, [referenceAggTopicSegment])

  useEffect(() => {
    if(analysisVisualizationType[policyId] !== "bars"){
      let currentValue = null;
      if (currentValue !== policyReferenceSegment) {
        setPolicyReferenceSegment(currentValue);
        const updatedReferenceAggTopicSegment = {
          ...referenceAggTopicSegment
        }
        updatedReferenceAggTopicSegment[policyId] = null
        dispatch(setReferenceAggTopicSegment(updatedReferenceAggTopicSegment))
      }
    }
  }, [analysisVisualizationType]);

  wrapperProps.actions = [{
    id: 'a3',
    icon: <MuiInfoOutlinedIcon
      color={"grey"}/>,
    action: () => setShowDescription(prevState => !prevState),
    setModalLocation: setLocation,
    disabled: false,
    show: description !== '',
  }];
  if (enableSegmentationAggregation) {
    wrapperProps.actions.push({
      id: 'a4',
      icon: <Compare
        color={policyReferenceSegment === column ? "primary" : "#616161"}
        sx={{cursor: 'pointer',width:'16px',height:'16px'}}/>,
      action: () => updateReferenceAggTopicSegment(),
      disabled: false,
      show: true,
      // backgroundColor: policyReferenceSegment === column ? "#E0E0E0" : "transparent",
    })
  }
  return (
    <WrapperWidgetUI
      title={title}
      widgetId={id}
      isLoading={isLoading} {...wrapperProps}
      filterEnabled={selectedCategories?.length > 0}
      showDescription={showDescription}
      setShowDescription={setShowDescription}
      location={location}
      description={description}
    >
      {(data && data.length > 0) && (
        <>
          <CategoryWidgetUI
            data={data.map((d) => {
              return {name: `${d.name}`, value: d.value}
            })}
            selectedCategories={selectedCategories}
            onSelectedCategoriesChange={handleSelectedCategoriesChange}
            color={{
              type: "simple",
              simpleColor: theme.palette.secondary.main,
              customColors: [],
              enabled: true
            }}//poner color fijo o de la organizacion que puede estar en redux o algun provider
            widgetId={id}
            filterable={policyReferenceSegment !== column}
            isReferenceSegment={policyReferenceSegment === column}
            maxItems={7}
            searchable={false}
            order={widgetType === "segments" ? "ranking" : "fixed"}
          />
        </>
      )}
      {/* </WidgetWithAlert> */}
    </WrapperWidgetUI>
  );
}

CategoryWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  wrapperProps: PropTypes.object,
  noDataAlertProps: PropTypes.object
};

CategoryWidget.defaultProps = {
  labels: {},
  wrapperProps: {},
  noDataAlertProps: {}
};

export default CategoryWidget;
