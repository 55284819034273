import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  ThemeProvider,
  Typography
} from '@mui/material';
import themeCrowdView from 'theme/crowdView';
import {useTheme} from 'components/providers/CustomThemeProvider';

import {
  DialogStyle,
  DialogTitleStyle,
  MuiDialogActions,
  TypographyStyle
} from './styles/modal';

const DialogTitle = (
  ({ children, classes, onClose, isDelete, ...props }) => (
    <MuiDialogTitle {...props}>
      <Typography sx={TypographyStyle} className={isDelete}>
        {children}
      </Typography>
    </MuiDialogTitle>
  )
);

const Modal = ({ open, onClose, title, children,
                 actions, isDelete, maxWidth, widthInPixels=null }) => {
  const { theme } = useTheme();
  return (
    <ThemeProvider theme={themeCrowdView}>
      <Dialog
        sx={DialogStyle}
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : 'xs'}
        fullWidth
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        PaperProps={{
          style: {
            borderRadius: 28,
            width: widthInPixels,
            maxWidth: widthInPixels,
          },
        }}
      >{ title && (
        <DialogTitle onClose={onClose} isDelete={isDelete} id='scroll-dialog-title'
                     sx={DialogTitleStyle}>
            {title}
        </DialogTitle>
      )}
        <DialogContent>{children}</DialogContent>
        <ThemeProvider theme={theme}>
          <MuiDialogActions>{actions}</MuiDialogActions>
        </ThemeProvider>
      </Dialog>
    </ThemeProvider>
  );
};

export default Modal;
