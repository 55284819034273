import {Button, styled} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const MuiStartIcon = styled(AddIcon)(({theme}) => ({
  color: '#005F64'
}));

export const MuiAddButton = styled(Button)(({theme}) => ({
  borderRadius: '12px',
  border: '2px dashed #E0E0E0',
  maxHeight: '64px',
  height:'64px',
  width: '99%',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  lineHeight: '17px',
  fontStyle: 'normal',
  fontWeight: '500',
  textTransform: 'uppercase',
  color: '#005F64',
  margin: '10px 10px 10px 0px'
}))


export const MuiEmptyTopicsHeadingContainer=styled('div')(({theme}) => ({
  fontFamily:"Raleway",
  fontStyle:"normal",
  fontWeight:500,
  fontSize:'14px',
  lineHeight:'16px',
  display:'inline',
  alignItems:'center',
  textAlign:'center',
  letterSpacing:'0.001em',
  color: "#616161",
  whiteSpace: 'normal', // Ensure text can wrap when needed
  wordWrap: 'break-word', // Ensure long words can break onto the next line
}));

export const MuiHighlightedText = styled('span')(({theme}) => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase'
}))
