import * as React from 'react';

import { ThemeProvider } from '@mui/material';
import Modal from 'components/common/Modal';
import themeCrowdView from 'theme/crowdView';
import {useTranslation} from 'components/providers/TranslationProvider';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../providers/AuthProvider';
import axios from '../../../../api/axios/axiosInstance';

import {useSnackbar} from 'notistack';
import {OPTION_UPDATE_VARIABLE_DATA} from '../../../../utils/constants';
import {useCollection} from '../../../../api/hooks/useCollection';
import apiPaths from '../../../../api/apiPaths';
import {status200} from '../../../../api/status.utils';
import {MuiButton} from './styles/updateModal';
import {ConfigureLabelsDescriptions} from "./creation/ConfigureLabelsDescriptions";

const UpdateModal = ({ orgId, option, dataset, open, onClose }) => {
  const [variables, setVariables] = React.useState([]);
  const location = useLocation();
  const { user } = useAuth();
  const userId =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const { data: collection } = useCollection({
    user_id: userId,
    collection: dataset.collection,
    enabled: option === OPTION_UPDATE_VARIABLE_DATA,
  })

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()

  const onSave = async () => {
    axios
      .post(
        apiPaths.set_dataset_label,
        { user_id: orgId, datasetName: dataset.name, variables },
        status200
      )
      .then((resp) => {
        if (resp.status === 200) {
          const data = resp.data

          if (data.status === 'ok') {
            onClose();
          } else {
            enqueueSnackbar(data.msg, { variant: 'error' })
          }
        }
      }).catch(() => {
        enqueueSnackbar('Something was wrong', { variant: 'error' })
      });
  };

  React.useEffect(() => {
    if (collection && collection.variables) {
      setVariables(collection.variables)
    }
  }, [collection])

  const dataSetForm = (
    <ThemeProvider theme={themeCrowdView}>
      <ConfigureLabelsDescriptions
        variables={variables}
        updateVariables={setVariables}
      />
    </ThemeProvider>
  );

  const actions = (
    <>
      <MuiButton
        onClick={onClose}
        color='primary'
        variant='text'
        data-cy='close_upload_dataset'>
        {t('cancel_btn')}
      </MuiButton>
      <MuiButton
        disabled={!variables}
        onClick={() => onSave()}
        color='primary'
        variant='outlined'
        data-cy='save_upload_dataset'>
        {t('save_generic_btn')}
      </MuiButton>
    </>
  );

  return (
        <Modal
          open={open}
          onClose={onClose}
          maxWidth={'sm'}
          widthInPixels={900}
          title={'Update Dataset ' + dataset.name}
          actions={actions}>
          {dataSetForm}
        </Modal>
  );
};

export default UpdateModal;
