import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    forceOAuthLogin: false, // enable for an initial Login screen
    userTranslations: {},
    requestPolicies: [],
    globalLoading: false,
    policiesToAggregate: [],
    targetCollection: null,
    targetScheme: {},
    activePoliciesTab: null,
    segmentationFilter: {},
    statisticFilter: {},
    widgetFoldedState: {},
    similarityQuery: {},
    similarityFilter: {},
    insightsFilter: {},
    qaEnabled: {},
    treeMapFilter: {},
    referenceAggTopicSegment: {},
    analysisVisualizationType: {},
    selectedScenarioTab: null,
    updateQA: false,
    treeMapColorScheme: "material_colors",
    treemapLoadingProgress: {},
    treemapTotalLoadingProgress: {},
    qaHints:{},
  },
  reducers: {
    setPrivateLoading: (state, action) => {
      state.privateLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setUserTranslations: (state, action) => {
      const {lng, allTranslations} = action.payload;
      let translations = {};

      if (allTranslations) {
        for (const translation of allTranslations) {
          translations[translation.key] = translation[lng];
        }
      }

      state.userTranslations = translations;
    },
    setWidgetFoldedState: (state, action) => {
      const {widgetId, foldedState} = action.payload;
      state.widgetFoldedState[widgetId] = foldedState;
    },
    setRequestPolicies: (state, action) => {
      state.requestPolicies = action.payload;
    },
    setSelectedScenarioTab: (state, action) => {
      state.selectedScenarioTab = action.payload
    },
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
    setPoliciesToAggregate: (state, action) => {
      state.policiesToAggregate = action.payload;
    },
    setTargetCollection: (state, action) => {
      state.targetCollection = action.payload;
    },
    setTargetScheme: (state, action) => {
      state.targetScheme = action.payload;
    },
    setTargetSchemeProperty: (state, action) => {
      state.targetScheme = {
        ...state.targetScheme,
        [action.payload.key]: action.payload.value
      }
    },
    setActivePoliciesTab: (state, action) => {
      state.activePoliciesTab = action.payload
    },

    setSegmentationFilter: (state, action) => {
      state.segmentationFilter = action.payload
    },
    setInsightsFilter: (state, action) => {
      state.insightsFilter = action.payload
    },
    setTreeMapFilter: (state, action) => {
      state.treeMapFilter = action.payload
    },
    setStatisticFilter: (state, action) => {
      state.statisticFilter = action.payload
    },
    setSimilarityFilter: (state, action) => {
      state.similarityFilter = action.payload
    },

    setSimilarityQuery: (state, action) => {
      state.similarityQuery = action.payload
    },
    setQaEnabled: (state, action) => {
      state.qaEnabled = action.payload
    },
    setUpdateQA: (state, action) => {
      state.updateQA = action.payload
    },
    setQAHints: (state, action) => {
      state.qaHints = action.payload
    },

    setTreeMapColorScheme: (state, action) => {
      state.treeMapColorScheme = action.payload
    },
    setReferenceAggTopicSegment: (state, action) => {
      state.referenceAggTopicSegment = action.payload
    },
    setAnalysisVisualizationType: (state, action) => {
      state.analysisVisualizationType = action.payload
    },
    setTreemapLoadingProgress: (state, action) => {
      const {id, progress} = action.payload
      state.treemapLoadingProgress[id] = progress
    },
    setTreemapTotalLoadingProgress: (state, action) => {
      const {id, progress} = action.payload
      state.treemapTotalLoadingProgress[id] = progress
    }
  },
});

export default slice.reducer;

export const setError = (payload) => ({type: 'app/setError', payload});

export const setPrivateLoading = (payload) => ({
  type: 'app/setPrivateLoading',
  payload
});

export const setSegmentationFilter = (payload) => ({
  type: 'app/setSegmentationFilter', payload
});
export const setTreeMapFilter = (payload) => ({
  type: 'app/setTreeMapFilter', payload
})

export const setSelectedScenarioTab = (payload) => ({
  type: 'app/setSelectedScenarioTab', payload
})
export const setSimilarityQuery = (payload) => ({
  type: 'app/setSimilarityQuery', payload
})

export const setQaEnabled = (payload) => ({
  type: 'app/setQaEnabled', payload
})

export const setQAHints = (payload) => ({
  type: 'app/setQAHints', payload
})
export const setStatisticFilter = (payload) => ({
  type: 'app/setStatisticFilter', payload
});

export const setSimilarityFilter = (payload) => ({
  type: 'app/setSimilarityFilter', payload
});

export const setInsightsFilter = (payload) => ({
  type: 'app/setInsightsFilter', payload
})

export const setUpdateQA = (payload) => ({
  type: 'app/setUpdateQA', payload
})
export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});

export const setUserTranslations = (payload) => ({
  type: 'app/setUserTranslations',
  payload,
});

export const setTreeMapColorScheme = (payload) => ({
  type: 'app/setTreeMapColorScheme',
  payload,
})
export const setWidgetFoldedState = (payload) => ({
  type: 'app/setWidgetFoldedState',
  payload,
})


export const setTargetCollection = (payload) => ({
  type: 'app/setTargetCollection',
  payload,
})

export const setTargetScheme = (payload) => ({
  type: 'app/setTargetScheme',
  payload,
})

export const setActivePoliciesTab = (payload) => ({
  type: 'app/setActivePoliciesTab',
  payload,
})

export const setReferenceAggTopicSegment = (payload) => ({
  type: 'app/setReferenceAggTopicSegment',
  payload,
})

export const setAnalysisVisualizationType = (payload) => ({
  type: 'app/setAnalysisVisualizationType',
  payload,
})

export const setTreemapLoadingProgress = (payload) => ({
  type: 'app/setTreemapLoadingProgress',
  payload
})
export const setTreemapTotalLoadingProgress = (payload) => ({
  type: 'app/setTreemapTotalLoadingProgress',
  payload
})

