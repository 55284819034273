import * as React from "react";
import {useState} from "react";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import {
  MuiAutocomplete,
  MuiInfoIconButton,
  MuiInfoOutlinedIcon,
  MuiSegmentCaption,
  MuiSegmentTitle
} from "./styles/segmentationParameters";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  inputStyle,
  labelInputStyle,
  MuiTextField
} from "./styles/generalParameters";
import {MuiGridField} from "../styles/configurationSteps";
import {fetchCollection} from "../../../../../../api/hooks/useCollection";
import {setTargetCollection} from "../../../../../../store/appSlice";
import {useDispatch} from "react-redux";


const ContextualizationParameters = ({
                                  scheme,
                                  orgId,
                                  setScheme
                                }) => {
  const {t} = useTranslation();
  const initValues = [
    {id:1, propName:t('survey')},
    {id:2, propName:t('interview')}
  ]
  const [anchorContextualizationInfo, setAnchorContextualizationInfo] = useState(null);
  const [infoText, setInfoText] = useState('');
  const [collectionMethod, ] = React.useState(initValues)
  const [selectedCollectionMethod, setSelectedCollectionMethod] = useState([]);

  const dispatch = useDispatch()

  const showDataDescriptionInfo = (event) => {
    let defaultText = t('information_smart_functions');
    setInfoText(t('information_smart_functions') || defaultText);
    setAnchorContextualizationInfo(event.currentTarget);
  }

  const showGoalsInfo = (event) => {
    let defaultText = t('information_smart_functions');
    setInfoText(t('information_smart_functions') || defaultText);
    setAnchorContextualizationInfo(event.currentTarget);
  }

  const closeContextualizationInfo = () => setAnchorContextualizationInfo(null);

  const handleChange = async (event) => {
    if (event.target.name === 'dataset') {
      const localCollection = await fetchCollection({
        user_id: orgId,
        collection: event.target.value
      })
      if (localCollection) {
        dispatch(setTargetCollection(localCollection))
      }
    }
    setScheme(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))
  };

  React.useEffect(() => {
    if (scheme) {
      let localSelectedCollectionMethod = scheme.collection_method;
      if (localSelectedCollectionMethod) {
        setSelectedCollectionMethod(
          collectionMethod.filter(d => localSelectedCollectionMethod.includes(d.propName))
        );
      }
    }
  }, [scheme, collectionMethod]);

  return (
    <Box style={{padding: "8px", width: '100%'}}>
      <Grid container direction="row" sx={{width: '100%'}}
            alignContent={'center'}>
        <Grid container item xs={6} direction='column'
              sx={{paddingRight: '16px'}}>
          <Grid item sx={{
            mb: '8px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <MuiSegmentTitle
              variant="h6">{t('data_description')}</MuiSegmentTitle>
            <MuiInfoIconButton onClick={showDataDescriptionInfo}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Grid>
          <Grid item sx={{mb: '16px'}}>
            <MuiSegmentCaption>
              {t('information_smart_functions')}
            </MuiSegmentCaption>
          </Grid>
          <Grid item container direction={"column"} sx={{maxWidth: '100%'}} rowGap={'16px'}>
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <MuiTextField
                  fullWidth
                  multiline
                  maxRows={2}
                  id="contextDescription"
                  label={t('population_content')}
                  name="contextDescription"
                  value={scheme.contextDescription}
                  onChange={async (e) => await handleChange(e)}
                  sx={inputStyle}
                />
              </FormControl>
            </Grid>
            <MuiGridField item>
              <FormControl fullWidth variant="outlined">
                <MuiAutocomplete
                  id="recollection_method"
                  multiple
                  options={collectionMethod}
                  value={selectedCollectionMethod}
                  onChange={(e, values) => {
                    setSelectedCollectionMethod(values);
                    const local_scheme = {...scheme};
                    local_scheme.collection_method = values?.map(d => d.propName);
                    setScheme(local_scheme);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('collection_method')}
                      InputLabelProps={{style: labelInputStyle}}
                    />
                  )}
                />
              </FormControl>
            </MuiGridField>
          </Grid>
        </Grid>
        <Grid container item xs={6} direction='column'
              sx={{paddingLeft: '10px'}}>
          <Grid item sx={{
            mb: '8px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '100%'
          }}>
            <MuiSegmentTitle
              variant="h6">{t('goals')}</MuiSegmentTitle>
            <MuiInfoIconButton onClick={showGoalsInfo}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Grid>
          <Grid item sx={{mb: '16px', maxWidth: '100%'}}>
            <MuiSegmentCaption>
              {t('information_smart_functions')}
            </MuiSegmentCaption>
          </Grid>
          <Grid item container direction={'column'} sx={{maxWidth: '100%'}} rowGap={'16px'}>
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <MuiTextField
                  fullWidth
                  id="analysisObjective"
                  label={t('objective_analysis')}
                  name="analysisObjective"
                  value={scheme.analysisObjective}
                  onChange={async (e) => await handleChange(e)}
                  multiline
                  maxRows={2}
                  sx={inputStyle}
                />
              </FormControl>
            </Grid>
            <Grid item >
              <FormControl fullWidth variant="outlined">
                <MuiTextField
                  fullWidth
                  id="audienceDescription"
                  label={t('audience_description')}
                  name="audienceDescription"
                  value={scheme.audienceDescription}
                  onChange={async (e) => await handleChange(e)}
                  multiline
                  maxRows={2}
                  sx={{...inputStyle, marginTop:'24px' }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorContextualizationInfo)}
        anchorEl={anchorContextualizationInfo}
        onClose={closeContextualizationInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{position: 'relative', width: '420px', padding: '10px'}}>
          <Grid container direction='row'>
            <Grid item sx={{padding: '5px', maxWidth: '374px'}}>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '15px',
                letterSpacing: '0.004em',
                color: '#616161',
                fontStyle: 'normal',
                marginLeft: '5px'
              }} variant="caption">
                {infoText}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={closeContextualizationInfo}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  width: '15px',
                  height: '15px'
                }}
              >
                <CloseIcon sx={{width: '15px', height: '15px'}}/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  )
}

export default ContextualizationParameters;
