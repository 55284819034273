import React, {useEffect, useState} from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import {Box, Divider, Grid, ListItemIcon, Menu, Tooltip} from "@mui/material";
import {
  boxAnswerStyles,
  gridContainerStyles,
  gridFilterStyles,
  iconCommonStyles,
  MuiCard,
  MuiCardActions,
  MuiChip,
  MUIFilterIconButton,
  MuiMenuItem,
  MuiTypographyLabel,
  typographyAnswerStyles
} from './styles/collapsibleCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {CopyAll, FilterList, Refresh} from "@mui/icons-material";
import ClearFilterIcon from "@mui/icons-material/FilterListOff"
import * as _ from "lodash";
import {
  setInsightsFilter,
  setSegmentationFilter,
  setSimilarityFilter,
  setSimilarityQuery,
  setStatisticFilter,
  setTreeMapFilter
} from "../../../../store/appSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "../../../providers/CustomThemeProvider";
import color from "color";
import {darken} from "@mui/material/styles";
import MoreVert from "@mui/icons-material/MoreVert";

const CollapsibleCard = ({
                           item,
                           index,
                           expand = false,
                           onDelete,
                           onCardSelected,
                           updateHistoryItem
                         }) => {
  const [expanded, setExpanded] = useState(expand);

  const [externalFilterChanged, setExternalFilterChanged] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const segmentationFilter = useSelector(state => state.app.segmentationFilter);
  const similarityFilter = useSelector(state => state.app.similarityFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const statisticFilter = useSelector(state => state.app.statisticFilter);
  const insightsFilter = useSelector(state => state.app.insightsFilter);
  const [openContextMenu, setOpenContextMenu] = useState(null);
  const dispatch = useDispatch();
  const {theme} = useTheme();
  const handleExpandClick = () => {
    let localExpand = !expanded;
    setExternalFilterChanged(localExpand);
    setExpanded(localExpand);
    if (onCardSelected) {
      onCardSelected(localExpand ? item : null);
    }
  };


  useEffect(() => {
    setExpanded(expand);
    setExternalFilterChanged(expand);
  }, [expand]);


  const QAResponse = ({message}) => {
    const prependIconToFirstParagraph = (htmlContent, icon) => {
      // Check if the content starts with a <p> tag and prepend the icon to its content
      const startsWithPTag = htmlContent.trim().startsWith("<p>");
      if (startsWithPTag) {
        // Insert the icon right after the opening <p> tag
        return htmlContent.replace("<p>", `<p>${icon} `);
      }
      // If the content does not start with a <p> tag, just prepend the icon
      return `${icon} ${htmlContent}`;
    }

    let htmlContent = prependIconToFirstParagraph(message.replace('\n', ''), '✨')
    return (
      <Typography
        component="div"
        dangerouslySetInnerHTML={{__html: htmlContent}}
        sx={typographyAnswerStyles}
      />
    )
  }

  useEffect(() => {
    if (item && item.filters && item.filters.length > 0) {
      const localFiltersApplied = item.filters.map(filter => {
        let filterApplied = false;
        if (filter.type === "segmentation" && filter.value) {
          for (const key in segmentationFilter) {
            if (segmentationFilter[key] === filter.value[key]) {
              filterApplied = true;
              break
            }
          }
        }
        if (filter.type === "statistic" && filter.value) {
          for (const key in statisticFilter) {
            if (statisticFilter[key] === filter.value[key]) {
              filterApplied = true;
              break
            }
          }
        }
        if (filter.type === "treemap" && filter.value) {
          filterApplied = _.isEqual(filter.value, treeMapFilter[item.policyId]);
        }
        if (filter.type === "similarity") {
          const value = _.cloneDeep(filter.value);
          let localSimilarityQuery = filter.similarityQuery || item.userQuery;
          if (Object.hasOwn(value?.distance, "low") && Object.hasOwn(value?.distance, "high")) {
            value.distance = {
              low: Math.floor(value.distance.low),
              high: Math.ceil(value.distance.high)
            }
          }
          let currentSimilarityFilter = null;
          if (similarityFilter[item.policyId]?.distance?.low && similarityFilter[item.policyId]?.distance?.high) {
            currentSimilarityFilter = {
              distance: {
                low: Math.floor(similarityFilter[item.policyId]?.distance?.low),
                high: Math.ceil(similarityFilter[item.policyId]?.distance?.high)
              }
            }
          }
          const similarityFiltersEqual = _.isEqual(value, currentSimilarityFilter);
          filterApplied = localSimilarityQuery === similarityQuery[item.policyId] && currentSimilarityFilter && similarityFiltersEqual
        }
        if (filter.type === "insights") {
          filterApplied = _.isEqual(filter.value, insightsFilter[item.policyId])
        }
        console.log(filter.type, " applied:", filterApplied);
        return filterApplied;
      }).reduce((a, b) => a && b, true);
      if (!localFiltersApplied) {
        setFiltersApplied(localFiltersApplied);
      }
    }
  }, [segmentationFilter, similarityFilter, similarityQuery, statisticFilter, insightsFilter, treeMapFilter]);


  const applyHistoryFilter = () => {
    let localSegmentationFilter = {}
    let localInsightsFilter = {}
    let localStatisticFilter = {}
    let localTreeMapFilter = {}
    if (item && item.filters && item.filters.length > 0) {
      item.filters.forEach(filter => {
        if (filter.type === "segmentation" && filter.value) {
          localSegmentationFilter = {...localSegmentationFilter, ...filter.value}
        }
        if (filter.type === "statistic" && filter.value) {
          localStatisticFilter = {...localStatisticFilter, ...filter.value}
        }
        if (filter.type === "treemap" && filter.value) {
          localTreeMapFilter = {...localTreeMapFilter, ...filter.value}
        }
        if (filter.type === "similarity") {
          const value = _.cloneDeep(filter.value);
          if (Object.hasOwn(value?.distance, "low") && Object.hasOwn(value?.distance, "high")) {
            let localSimilarityQuery = {};
            localSimilarityQuery[item.policyId] = filter.similarityQuery || item.userQuery;
            dispatch(setSimilarityQuery(localSimilarityQuery));
            value.distance = {
              low: parseFloat(value.distance.low.toFixed(3)),
              high: parseFloat(value.distance.high.toFixed(3))
            }
          }
          dispatch(setSimilarityFilter({[item.policyId]: value}))
        }
        if (filter.type === "insights") {
          localInsightsFilter = {...localInsightsFilter, ...filter.value}
        }
      });

      dispatch(setSegmentationFilter(localSegmentationFilter));
      dispatch(setInsightsFilter({[item.policyId]: localInsightsFilter}))
      dispatch(setStatisticFilter(localStatisticFilter));

      let globalTreeMapFilter = {...treeMapFilter};
      globalTreeMapFilter[item.policyId] = localTreeMapFilter;
      dispatch(setTreeMapFilter(globalTreeMapFilter));
      setFiltersApplied(true);
    }

  }

  const clearFilters = () => {
    if (item && item.filters && item.filters.length > 0) {
      let localSegmentationFilter = _.cloneDeep(segmentationFilter)
      let localStatisticFilter = _.cloneDeep(statisticFilter)
      let localTreeMapFilter = _.cloneDeep(treeMapFilter)
      let localSimilarityFilter = _.cloneDeep(similarityFilter)
      let localSimilarityQuery = _.cloneDeep(similarityQuery)
      let localInsightsFilter = _.cloneDeep(insightsFilter)
      item.filters.forEach(filter => {
        if (filter.type === "segmentation" && filter.value) {
          for (const key in filter.value) {
            delete localSegmentationFilter[key]
          }
        }
        if (filter.type === "statistic" && filter.value) {
          for (const key in filter.value) {
            delete localStatisticFilter[key]
          }
        }
        if (filter.type === "treemap" && filter.value) {
          for (const key in filter.value) {
            delete localTreeMapFilter[item.policyId][key]
          }
          dispatch(setTreeMapFilter(localTreeMapFilter));
        }
        if (filter.type === "similarity") {
          const value = _.cloneDeep(filter.value);
          if (Object.hasOwn(value?.distance, "low") && Object.hasOwn(value?.distance, "high")) {
            localSimilarityQuery[item.policyId] = "";
            localSimilarityFilter[item.policyId] = {};
          }

        }
        if (filter.type === "insights") {
          for (const key in filter.value) {
            delete localInsightsFilter[item.policyId][key]
          }
          dispatch(setInsightsFilter(localInsightsFilter));
        }
      });
      dispatch(setSegmentationFilter(localSegmentationFilter));
      dispatch(setStatisticFilter(localStatisticFilter));
      setFiltersApplied(false);
    }
  }

  return (
    <MuiCard key={`history-card-${item.policyId}-${index}`} sx={{borderRadius:'12px'}}>
      <Tooltip title={item.userQuery} placement={"top"}
               key={`tt-history-card-${item.userQuery}-${index}`}>
        <MuiCardActions disableSpacing
                        key={`history-card-action-${item.userQuery}-${index}`}
                        expanded={expanded} sx={{pt:'8px',pr:'4px'}}>
          <Grid direction='row' container sx={{width: '100%'}} wrap={'nowrap'}>
            <Grid item xs={'auto'} alignContent={'center'}>
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                size="small"
                sx={{
                  width: '18px',
                  height: '18px',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
              >
                {expanded ? (
                  <KeyboardArrowUpIcon sx={iconCommonStyles}/>
                ) : (
                  <KeyboardArrowDownIcon sx={iconCommonStyles}/>
                )}
              </IconButton>
            </Grid>
            <Grid item xs={true}
                  sx={{alignItems: 'center', ml:'8px'}}
                  onClick={handleExpandClick}
                  alignContent={'center'}
            >
              <MuiTypographyLabel
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: 'normal', // Allow wrapping
                  overflow: 'visible',
                  width: '100%',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '500',
                  letterSpacing: '0.005em',
                  lineHeight: '17px',
                  fontStyle: 'normal',
                  color: '#424242',
                  cursor: 'pointer'
                }}
              >
                {item.userQuery}
              </MuiTypographyLabel>
            </Grid>
            <Grid
              item
              xs={'auto'}
              sx={{
                justifyContent: 'flex-end',
                pt:0
              }}
              alignContent={'flex-start'}
            >
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(evt) => {
                  setOpenContextMenu(evt.currentTarget);
                }}
                sx={{
                  width: '16px',
                  height: '16px',
                  border: 'none',
                  mt: -1,
                  padding:0
                }}
              >
                <MoreVert sx={{
                  color: '#616161',
                  width: '16px',
                  height: '16px'
                }}/>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={openContextMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(openContextMenu)}
                onClose={() => setOpenContextMenu(null)}
                sx={{
                  '& .MuiMenu-paper': {
                    borderRadius: '8px',
                    width: '125px',
                    padding: 0,
                  },
                }}
              >
                {externalFilterChanged && (
                  <MuiMenuItem
                    sx={{
                      color: '#000000',
                    }}
                    onClick={async () => {
                      setOpenContextMenu(null)
                      await updateHistoryItem();
                    }}
                  >
                    <ListItemIcon>
                      <Refresh
                        sx={{color: '#757575', height: '18px', width: '18px'}}/>
                    </ListItemIcon>
                    Refresh
                  </MuiMenuItem>
                )}
                <MuiMenuItem
                  sx={{
                    color: '#000000',
                  }}
                  onClick={() => {
                    setOpenContextMenu(null);
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = item.aiAnswer;
                    const answerText = tempDiv.textContent || tempDiv.innerText || '';
                    const text = `${item.userQuery}\n\n${answerText}`;
                    navigator.clipboard.writeText(text).then(() => {
                      console.log('copied')
                    });
                  }}
                >
                  <ListItemIcon>
                    <CopyAll
                      sx={{color: '#757575', height: '18px', width: '18px'}}/>
                  </ListItemIcon>
                  Copy
                </MuiMenuItem>
                <MuiMenuItem
                  sx={{
                    color: '#FF4429',
                  }}
                  onClick={onDelete ? async () => {
                    setOpenContextMenu(null)
                    onDelete(item.userQuery)
                  } : (() => console.warn("no delete function provided"))}
                >
                  <ListItemIcon>
                    <DeleteIcon sx={{
                      color: '#FF4429', height: '18px',
                      width: '18px'
                    }}/>
                  </ListItemIcon>
                  Delete
                </MuiMenuItem>
              </Menu>
            </Grid>
          </Grid>
        </MuiCardActions>
      </Tooltip>
      {expanded && (
        <CardContent sx={{paddingTop: 0, pb: "5px !important"}}
                     key={`history-card-content-${item.userQuery}-${index}`}>
          <Grid container sx={gridContainerStyles}>
            <Box sx={boxAnswerStyles}>
              {/* HTML content container with indent */}
              <Box sx={{flex: 1}}>
                <QAResponse message={item.aiAnswer}/>
              </Box>
            </Box>
            {item.filters?.length > 0 && (
              <Divider sx={{width: '100%', mb: '16px'}}/>)}
            <Grid container item xs={12} style={gridFilterStyles}
                  direction={'column'} rowGap={'8px'}>
              {item.filters?.length > 0 && (
                <>
                  <Grid container item xs={true} sx={{width: '100%'}}
                        alignContent='center'
                        justifyContent='center'>
                    <Grid item xs={6} alignItems={'center'}>
                      <Typography sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        lineHeight: '16px',
                        letterSpacing: '0.0025em',
                        color: '#254957'
                      }}>
                        Applied filters
                      </Typography>
                    </Grid>
                    <Grid container xs={6} item alignContent='center'
                          justifyContent='flex-end'>
                      <MUIFilterIconButton
                        key={`history-filter-apply-filters`}
                        onClick={() => !filtersApplied ? applyHistoryFilter() : clearFilters()}
                        variant="outlined"
                        filtersApplied={filtersApplied}
                      >
                        {!filtersApplied ? (
                          <FilterList sx={{
                            color: filtersApplied ? darken(theme.palette.primary.main, 0.3) : '#757575',
                            width: '16px',
                            height: '16px'
                          }}/>
                        ) : (
                          <ClearFilterIcon sx={{
                            color: filtersApplied ? darken(theme.palette.primary.main, 0.3) : '#757575',
                            width: '16px',
                            height: '16px'
                          }}/>
                        )}
                        {/*Replicate scenario*/}
                      </MUIFilterIconButton>
                    </Grid>
                  </Grid>
                  <Grid container item direction='row' xs={true} display="flex"
                        flexWrap="wrap"
                        sx={{rowGap: '10px', columnGap: '5px', width: '100%'}}
                        flexDirection="row"
                        justifyContent="flex-start">
                    {item.filters?.map((filter, index) => (
                      <HistoryFilter
                        filter={filter}
                        index={index}
                        key={`history-filter-${filter.type}-${index}`}
                        sx={{
                          backgroundColor: filtersApplied ? color(theme.palette.primary.main).lighten(0.8).hex() : '#EEEEEE',
                        }}
                      />
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}
    </MuiCard>
  );
}

export default CollapsibleCard;

const HistoryFilter = ({filter, index, ...props}) => {
  return (
    <Tooltip title={filter.prettyText} placement={"top"}
             key={`tt-history-filter-${filter.type}-${index}`}>
      <MuiChip
        key={`history-filter-${filter.type}-${index}`}
        label={filter.prettyText}
        variant="outlined"
        {...props}
      />
    </Tooltip>
  );
}
