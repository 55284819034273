// External library imports
import * as React from 'react';
import {Grid, IconButton, InputAdornment, Typography} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useLocation, useNavigate} from 'react-router-dom';

// Internal component and function imports
import {useTranslation} from 'components/providers/TranslationProvider';
import resetPassword from 'api/resetPassword';
import changePassword from 'api/changePassword';
import Toast from 'components/common/Toast';

import {
  gridResetContainerStyle,
  inputAdornmentStyle,
  langContainer,
  MuiButtonColored,
  MuiDivPaper,
  MuiForm,
  MuiGridContainerButtons,
  MuiTextField,
  MuiTypographyReset,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon,
  inputPasswordConfirmation,
} from './styles/login';
import {MdLockOutline} from "react-icons/md";
import LanguageSelector from "../../common/LanguageSelector";

const schema = yup.object().shape({
  password: yup
    .string().min(4, 'min_4_characters').matches(/^(?=.*[A-Z])(?=.*[0-9])/,
      'match_password_validation').required('required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'password_match'),
});

const ResetPassword = () => {
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isPending, setIsPending] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [initialParam, setInitialParam] = React.useState({ accessToken: null, user: null });

  const { state } = useLocation();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const {handleSubmit, formState: { errors, isValid }} = methods;
  const { accessToken, user } = initialParam;

  const handleCloseToast = () => setOpenToast(false);

  const handleError = (e) => {
    setError(t('password_reset_token_error'));
    setOpenToast(true);
    setIsPending(false);
  };

  const handleSuccess = () => {
    navigate('/login');
    setIsPending(false);
  };

  const onSubmit = (data) => {
    setIsPending(true);

    if (token) {
      const item = {
        password: data.password,
        token: token,
      };
      resetPassword(item, handleError, handleSuccess);
    } else {
      const item = {
        user: user.email,
        password: data.password,
        accessToken: accessToken,
      };
      changePassword(item, handleError, handleSuccess);
    }
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    setToken(query ? query.get('token') : '');
  }, []);

  React.useEffect(() => {
    if (state) {
      setInitialParam(state);
    }
  }, [state]);

  return (
    <MuiDivPaper>
      <Grid item sx={langContainer}>
        <LanguageSelector />
      </Grid>
      <Grid container sx={gridResetContainerStyle}>
        <Grid item>
          <MuiTypographyReset>
            {t('reset_password')}
          </MuiTypographyReset>
        </Grid>
        <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />
        <Grid item sx={{marginBottom:'15px'}}>
          <Typography fontFamily='Montserrat' fontSize={12} letterSpacing={0.4} fontWeight={400}>
            {t('send_password_info')}
          </Typography>
        </Grid>
        <FormProvider {...methods}>
          <MuiForm onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}  sx={{width: '328px'}}>
              <Grid item xs={12}>
                <MuiTextField
                  autoFocus
                  size="small"
                  placeholder="***********"
                  variant='outlined'
                  fullWidth
                  label={t('password')}
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  error={t(errors.password?.message)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={inputPasswordConfirmation}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdLockOutline style={inputAdornmentStyle}/>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <MuiVisibilityOffIcon /> : <MuiVisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{marginTop:'-10px'}}>
                <MuiTextField
                  autoFocus
                  size="small"
                  placeholder="***********"
                  variant='outlined'
                  fullWidth
                  label={t('confirm_password')}
                  name='passwordConfirmation'
                  type={showConfirmPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  error={t(errors.passwordConfirmation?.message)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={inputPasswordConfirmation}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdLockOutline style={inputAdornmentStyle}/>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? <MuiVisibilityOffIcon /> : <MuiVisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <MuiGridContainerButtons container>
              <MuiGridContainerButtons item>
                <MuiButtonColored
                  loading={isPending}
                  type='submit'
                  disabled={!isValid || isPending}
                >
                  {isPending ? t('saving...') : t('send_password')}
                </MuiButtonColored>
              </MuiGridContainerButtons>
            </MuiGridContainerButtons>
          </MuiForm>
        </FormProvider>
      </Grid>
    </MuiDivPaper>
  );
};

export default ResetPassword;
