import * as React from 'react';
import {
  Button as MuiButton,
  Divider,
  Grid,
  MenuItem,
  ThemeProvider
} from '@mui/material'
import * as yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useLocation} from 'react-router-dom';

import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import {SelectField, TextField} from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';
import {useTranslation} from 'components/providers/TranslationProvider';
import themeCrowdView from 'theme/crowdView';
import useUpdateScheme from 'api/hooks/useUpdateScheme';
import useCreateScheme from 'api/hooks/useCreateScheme';
import useMaps from 'api/hooks/useMaps';
import {useAuth} from 'components/providers/AuthProvider';
import {MuiContainer} from './styles/formModal';


const selectInputValues = [
    {
        value: 'Descriptive Spatial Analysis',
        label: 'Descriptive Spatial Analysis',
    },
    {
        value: 'Active Search for Beneficiaries',
        label: 'Active Search for Beneficiaries',
    },
    {
        value: 'Infrastructure Improvement',
        label: 'Infrastructure Improvement',
    },
];

const schema = yup.object().shape({
    // TODO: Traduction
    name: yup.string().min(4).max(80, 'This field can not exceed 80 characters').required('Required'),
    map: yup.string().required('Required'),
    scenarioType: yup.string().required('Required'),
});

const FormModal = ({scheme, action, open, onClose}) => {
    const {t} = useTranslation();

    const location = useLocation();
    const {user} = useAuth();

    const Id =
        location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];

    const {
        mutateAsync: updateScheme,
        status: updateStatus,
        error: updateError,
        isLoading: updateIsLoading,
    } = useUpdateScheme();
    const {
        mutateAsync: createScheme,
        status: createStatus,
        error: createError,
        isLoading: createIsLoading,
    } = useCreateScheme();

    const [openToast, setOpenToast] = React.useState(false);
    const [error, setError] = React.useState('');
    const {data, isLoading: isLoadingMap} = useMaps(Id);

    // eslint-disable-next-line
    const getDefaultValues = () => {
        return scheme
            ? {
                name: scheme.name,
                map: scheme.map,
            }
            : {};
    };

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: {errors, isValid},
    } = methods;

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    const updScheme = (data) => {
        const schemeToUpdate = {
            schemes: {
                ...scheme,
                name: data.name,
                scenarioType: data.scenarioType,
                map: data.map,
            },
            user_id: Id,
        };
        updateScheme(schemeToUpdate);
    };

    const newScheme = (data) => {
        const schemeToCreate = {
            scheme: {
                id: getRandomId(),
                name: data.name,
                scenarioType: data.scenarioType,
                map: data.map,
            },
            user_id: Id,
        };
        createScheme(schemeToCreate);
    };

    const getRandomId = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

    const onSubmit = handleSubmit((data) =>
        action === 'update' ? updScheme(data) : newScheme(data)
    );

    React.useEffect(() => {
        if (updateStatus === 'success') {
            onClose();
        }
        if (updateStatus === 'error') {
            console.log(updateError);
            setError(t(updateError.data.error));
            setOpenToast(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus]);

    React.useEffect(() => {
        if (createStatus === 'success') {
            onClose();
        }
        if (createStatus === 'error') {
            setError(t(createError.data.error));
            setOpenToast(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createStatus]);

    const formScheme = (
        <MuiContainer display='flex'>
            <Toast
                message={error}
                handleClose={handleCloseToast}
                severity='error'
                horizontal='center'
                vertical='top'
                open={openToast}
            />
            <ThemeProvider theme={themeCrowdView}>
                <Grid item xs={12}>
                    <SelectField
                        name='scenarioType'
                        value=''
                        label={t('scenario_type_label')}
                        variant='outlined'
                        fullWidth
                    >
                        <Divider light/>
                        {selectInputValues.map((o, i) => (
                            <MenuItem key={o.value} disabled={i !== 0} value={o.value}>
                                {o.label}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        name='name'
                        margin='dense'
                        type='text'
                        //TODO: Traduction
                        label='Scenario name'
                        // label={t('scenario_name')}
                        variant='outlined'
                        fullWidth
                        error={errors.name?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectField
                        name='map'
                        select
                        label='Place select a Map'
                        value=''
                        error={errors.map?.message}
                        variant='outlined'
                        // TODO: Traduction
                        // label={t('scenario_type_label')}
                        fullWidth
                    >
                        <Divider light/>
                        {data?.maps.map((m) => (
                            <MenuItem key={`${m.name}-${m.url}`} value={m.url}>
                                {m.name}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
            </ThemeProvider>
        </MuiContainer>
    );

    const actions = (
        <>
            <MuiButton onClick={onClose} color='primary' variant='text'>
                {t('cancel_btn')}
            </MuiButton>
            <Button
                onClick={onSubmit}
                loading={updateIsLoading || createIsLoading || isLoadingMap}
                disabled={!isValid || updateIsLoading || createIsLoading || isLoadingMap}
            >
                {t('save_changes')}
            </Button>
        </>
    );

    return (
        <FormProvider {...methods}>
            <form>
                <Modal
                    open={open}
                    onClose={onClose}
                    // TODO: Traduction t('update') and t('create_scenario')
                    title={action === 'update' ? `${'Update'} ${scheme?.name}` : `${'New Scenario'}`}
                    actions={actions}
                >
                    {formScheme}
                </Modal>
            </form>
        </FormProvider>
    );
};

export default FormModal;
