import {Autocomplete, styled, TextField} from "@mui/material";
import * as React from "react";

export const MuiTextField = styled(TextField)(({theme}) => ({
  height: '56px',
  maxWidth:'100%',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  },
  '& .MuiInputLabel-shrink': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
  '&.MuiInputLabel-root':{
    fontFamily: 'Montserrat',
    fontSize: '16px !important',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  }
}));

export const MuiDescriptionTextField = styled(TextField)(({theme}) => ({
  minHeight: '217px',
  maxWidth: '692px',
  width:'100%',
  borderRadius: '4px 4px 0px 0px',
  alignSelf: 'end',
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  },
  '& .MuiInputLabel-shrink': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
  '&.MuiInputLabel-root':{
    fontFamily: 'Montserrat',
    fontSize: '16px !important',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  },
  '& legend': {
    fontSize:10,
  },
}));


export const MuiAutocomplete = styled(
  (props) => (
    <Autocomplete
      {...props}
      multiple={false}
      variant="outlined"
      getOptionLabel={(option) => option.label || option.propName}
      isOptionEqualToValue={(option, value) => option.propName === value.propName}
      filterSelectedOptions
      renderInput={props.renderInput}
    />
  )
)(({theme}) => ({
  height: '56px',
  flex:1,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#325D6C', // Custom border color
      borderWidth: '1px', // Custom border width
    },
    '&:hover fieldset': {
      borderColor: '#143440', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#254957', // Border color when focused
    },
  },
  '& .MuiInputBase-root': {
    height: '56px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '4px 4px 0 0',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  },
  '& .MuiInputLabel-shrink': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
  '&.MuiInputLabel-root':{
    fontFamily: 'Montserrat',
    fontSize: '16px !important',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#143440'
  }
}));

export const inputStyle = {
  '& legend': {
    fontSize:10,
  },
}

export const labelInputStyle = {
  color: '#424242',
  fontSize:12,
  fontFamily:'Montserrat',
}
