import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
// import AddIcon from '@material-ui/icons/Add';
import {useTranslation} from 'components/providers/TranslationProvider';
import {usePermissions} from 'components/hooks/usePermissions';

import FormModal from './FormModal';
// Style and asset imports
import {MuiButtonSpacing} from '../styles/scenario';

const NewScenario = (props) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('create');
    setOpen(createQuery === 'scheme');
  }, [query]);

  const handleClose = () => {
    query.delete('create');
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  // TODO finish this component
  return (
    <>
      {usePermissions('create_schemes') && (
        <MuiButtonSpacing
          variant='outlined'
          color='primary'
          startIcon={<AddIcon />}
          // onClick={() => (window.location.href = props.urlBase + '&open=' + newScenario)}
          onClick={() => navigate(`${location.pathname}/create/specification`)}
        >
          {t('new_scenario')}
        </MuiButtonSpacing>
      )}
      {open && <FormModal action='create' open={open} onClose={handleClose} />}
    </>
  );
};
export default NewScenario;
