import {tooltipStyles} from "../styles/nivoTreeMap";
import {useTooltip} from "@nivo/tooltip";
import * as React from "react";
import {createElement, useCallback} from "react";
import {animated} from "react-spring";
import * as _ from "lodash";
import {getContrastTonality} from "../../../../../../utils/colorPaletteUtils";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import color from "color";
import {Typography} from "@mui/material";

function getContrastColor(colorInput) {
  return getContrastTonality(colorInput) === "light" ? "#FFFFFF" : '#212121'
}

const CustomTooltip = ({bar, isSegmented}) => {
  const {t} = useTranslation();
  if (!bar) {
    return null;
  }

  return (
    <div style={{
      maxWidth: '500px',
      backgroundColor: '#FFFFFF',
      gap: '0px',
      padding: 5,
      borderRadius: '4px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.1)',
      pointerEvents: 'none',
      zIndex: 999999
    }}>
      <div style={{display: 'flex', alignItems: 'center'}}>

        <div style={{
          ...tooltipStyles,
          backgroundColor: bar.backgroundColor
        }}
        ></div>
        <Typography variant={'body1'} sx={{
          fontSize: '16px',
          fontFamily: 'Montserrat',
          fontWeight: 600,
          marginLeft: '5px'
        }}>{(!isSegmented) ? bar.data.tooltipText : bar.data[`tooltipText_${bar.id}`]}</Typography>
      </div>
      {(!!bar.data.aiSummary) && (
        <div>
          <Typography variant={'body1'} sx={{
            fontSize: '16px',
            fontFamily: 'Montserrat',
            fontWeight: 500
          }}>{`${t('ai_summary_prefix')}: ${bar.data.aiSummary}`}</Typography>
        </div>
      )}
    </div>

  );
}
const CustomBarComponent = (props) => {
  const {showTooltipFromEvent, hideTooltip} = useTooltip();
  const {
    bar,
    style,
    keys,
    selectedNodeData,
    updateSelectedNodeData,
    selectedTopic,
    selectedParentNodeData,
    updateSelectedParentNodeData
  } = props;
  const adjustedHeight = Math.max(0, bar.height);
  const borderRadius = bar.width > 10 ? 10 : 0;

  const handleMouseEnter = useCallback((event) => {
    showTooltipFromEvent(createElement(CustomTooltip, {
      bar: bar.data,
      isSegmented: keys?.length > 1
    }), event, {
      bottom: event.clientY
    });
    props.onMouseEnter?.(bar, event);
  }, [bar]);

  const handleMouseMove = useCallback((event) => {
    showTooltipFromEvent(createElement(CustomTooltip, {
      bar: bar.data,
      isSegmented: keys?.length > 1
    }), event, {
      bottom: event.clientY
    });
    props.onMouseMove?.(bar, event);
  }, [bar]);


  const handleMouseLeave = useCallback((event) => {
    hideTooltip();
    props.onMouseLeave?.(bar, event);
  }, [bar]);

  const handleMouseClick = (event) => {
    let newVar = _.cloneDeep(bar.data);
    newVar.data.color=bar.color;
    if (newVar.data.isParent) {
      updateSelectedParentNodeData(newVar);
    }
    if(selectedNodeData?.data?.isParent && selectedNodeData.id!==newVar.id){
      updateSelectedParentNodeData(selectedNodeData)
    }else if(selectedParentNodeData&&isSelected){
      newVar = selectedParentNodeData;
      updateSelectedParentNodeData(newVar)
    }else if(isSelected){
      newVar = null;
    }
    updateSelectedNodeData(newVar);
    props.onMouseClick?.(bar, event);
  }
  const isSelected = selectedNodeData ? bar.data.id === selectedNodeData.id && bar.data.indexValue === selectedNodeData.indexValue : false
  let barStyle = {
    opacity: 0.95,
    strokeWidth: 1,
    stroke: bar.color
  };

  if (selectedNodeData&&!selectedTopic?.isParent) {
    barStyle = {
      opacity: isSelected ? 0.95 : 0.4,
      strokeWidth: isSelected ? 5 : 1,
      stroke: isSelected ? color(bar.color).darken(0.2).hex() : bar.color
    };
  }

  return (
    <animated.g
      width={bar.width}
      height={bar.height}
      x={bar.x}
      y={bar.y}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      cursor='pointer'
    >
      <animated.path
        d={style.width.to((width) => `
        M${bar.x},${bar.y + adjustedHeight}
        L${bar.x},${bar.y}
        L${bar.x + width - borderRadius},${bar.y}
        Q${bar.x + width},${bar.y} ${bar.x + width},${bar.y + borderRadius}
        L${bar.x + width},${bar.y + adjustedHeight - borderRadius}
        Q${bar.x + width},${bar.y + adjustedHeight} ${bar.x + width - borderRadius},${bar.y + adjustedHeight}
        Z
      `)}
        fill={style.color}
        {...barStyle}
      >
      </animated.path>
      {props.shouldRenderLabel && (
        <text
          x={bar.x + (bar.width / 2)}
          y={bar.y + (bar.height / 2)}
          textAnchor={'middle'}
          dominantBaseline={'middle'}
          fontSize={'12px'}
          fontWeight={'500'}
          fontFamily={'Montserrat'}
          fontStyle={'normal'}
          fill={getContrastColor(bar.color)}
        >
          {props.label}
        </text>
      )}
    </animated.g>
  )
    ;
};

export default CustomBarComponent;
