import {
  Box,
  Button,
  Grid,
  IconButton,
  lighten,
  styled,
  Typography
} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BackupIcon from '@mui/icons-material/Backup';
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

export const MuiButton = styled(Button)(({ theme }) => ({
  width:'185px',
  height: '38px',
  fontFamily: 'Montserrat',
  fontSize:'14px',
  fontWeight:500,
  padding: '10px 24px 10px 16px',
  borderRadius: '20px'
}));

export const MuiSpaceDashboardOutlinedIcon = styled(SpaceDashboardIcon)(({ theme }) => ({
  height: '18px',
  width: '18px',
}));

export const MuiTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize:'21px',
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500,
  color:'#616161',
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontSize:'29px',
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:700,
  color:'#212121',
  marginLeft:'-10px'
}));

export const MuiBoxPopover = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '420px',
  padding: '10px'
}));

export const MuiGridPopover = styled(Box)(({ theme }) => ({
  padding: '5px',
  maxWidth: '374px'
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  width: '15px',
  height: '15px'
}));

export const MuiSubTitle = styled(Typography)(({ theme }) => ({
  fontSize:'21px',
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500,
  color:'#616161',
}));

export const MuiInfoTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '15px',
  letterSpacing: '0.004em',
  color: '#616161',
  fontStyle: 'normal',
  marginLeft: '5px'
}));

export const MuiButtonAction = styled(Button)(({ theme }) => ({
  padding: '10px 10px 10px 24px',
  borderRadius: 20,
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary,
  width : '100%',
  height: 37,
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: 0.25,
}));

export const MuiCommonButton = styled(Button)(({ theme }) => ({
  borderColor:lighten(theme.palette.primary.main, 0.1),
  borderRadius:20,
  height:'38px',
  minWidth:'223px',
  padding:'10px 24px 10px 16px',
  gap:8,
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    borderColor:lighten(theme.palette.primary.main, 0.1),
  },
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: '0.0025em',
  textTransform:'uppercase',
  lineHeight:'17px',
  textWrap:'nowrap'
}));

export const MuiGridField = styled(Grid)(({ theme }) => ({
  marginTop:'24px',
  display:'flex',
  alignItems:'center'
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  fontSize:'14px',
  fontFamily:'Montserrat',
  textAlign:'center',
  fontWeight:500,
  color:lighten(theme.palette.primary.main, 0.2),
}));

export const MuiCloudDownloadIcon = styled(CloudDownloadIcon)(({ theme }) => ({
  color: lighten(theme.palette.primary.main, 0.2)
}));

export const MuiBackupIcon = styled(BackupIcon)(({ theme }) => ({
  color: lighten(theme.palette.primary.main, 0.2)
}));


export const descriptionStyle = {
  '& .MuiInputBase-root': {
    height: 217,
    maxWidth: 692,
    width:'100%'
  },
  '.MuiInputLabel-root': {
    fontFamily:'Montserrat',
    fontSize:'12px',
    fontWeight:400,
    color:'#254957'
  },
  '.MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 400,
    color: '#212121'
  }
};

export const closeStyle = {
  width: '15px', height: '15px'
};
