import {Box, Typography} from "@mui/material";

export const QAHint = ({icon, title, question, setQuestion}) => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      minHeight: '168px',
      padding: '12px',
      gap: '8px',
      backgroundColor: '#F5F5F5',
      borderRadius: '20px',
      border: '1px solid #EEEEEE',
      cursor: 'pointer',
    }}
         onClick={() => {
           console.log("Setting question", question);
           setQuestion(question);
         }}
    >
      {icon && icon}
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontWeight: 600,
          fontStyle: 'normal',
          textAlign: 'left',
          letterSpacing: '0.005em',
          lineHeight: '17px',
          color: '#616161',
          cursor: 'pointer'
        }}
        onClick={() => {
          console.log("Setting question", question);
          setQuestion(question);
        }}
      >{title}</Typography>

      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          textAlign: 'left',
          color: '#616161',
          lineHeight: '15px',
          letterSpacing: '0.005em',
          cursor: 'pointer'
        }}
        onClick={() => {
          console.log("Setting question", question);
          setQuestion(question);
        }}
      >{question}</Typography>
    </Box>
  );
}
