import {Button, lighten, styled, TextField} from "@mui/material";
import {darken} from "@mui/material/styles";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";

export const MuiOutlinedButton = styled(Button)(({theme}) => ({
  gap: '8px',
  padding: '4px 12px 4px 8px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '26px',
  width: '99px',
  border: `1px solid ${darken(theme.palette.primary.main,0.3)}`,
  borderRadius: '13px',
  color: darken(theme.palette.primary.main,0.3),
  fontFamily: 'Montserrat',
  fontStyle: 'medium',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '12px',
  letterSpacing: '0.0025em',
  textTransform:'uppercase',
}));

export const MuiTextField = styled(TextField)(({theme}) => ({
  height: '56px',
  maxHeight:'56px',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#424242',
    minHeight:'56px',
    alignContent: 'center',
  },
  '& .MuiOutlinedInput-root': {
    maxHeight:'56px'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
}));

export const MuiModalControls = styled(Button)(({theme}) => ({
  gap: '8px',
  padding: '4px 12px 4px 8px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '37px',
  width: '112px',
  border: `1px solid ${darken(theme.palette.primary.main,0.3)}`,
  borderRadius: '18px',
  color: darken(theme.palette.primary.main,0.3),
  fontFamily: 'Montserrat',
  fontStyle: 'medium',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0.0025em',
  textTransform:'uppercase',
}))

const MuiAddCategoryIcon = styled(AddIcon)(({theme}) => ({
  width: '18px',
  height: '18px',
  color: darken(theme.palette.primary.main, 0.3)
}));

export const MuiAddCategoryButton = styled((props) => {
  return (
    <Button
      {...props}
      variant='outlined'
      startIcon={<MuiAddCategoryIcon/>}
    ></Button>
  )
})(({theme}) => ({
  color: darken(theme.palette.primary.main, 0.3),
  height: '38px',
  width:'100%',
  padding: '10px 24px 10px 16px',
  alignContent: 'center',
  borderRadius: '20px',
  border: `1px dashed ${lighten(theme.palette.primary.main, 0.3)}`
}))
