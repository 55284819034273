import {Box, styled, Tabs, ThemeProvider} from '@mui/material';
import {
  MuiGridBox,
  MuiTab,
  MuiToolbar
} from '../organization/styles/organization';
import themeCrowdView from '../../../../theme/crowdView';
import {NavLink, useLocation} from 'react-router-dom';
import * as React from 'react';
import {useTranslation} from '../../../providers/TranslationProvider';
import {usePermissions} from '../../../hooks/usePermissions';
import {useTheme} from '../../../providers/CustomThemeProvider';


const TabsCustoms = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { theme: currentTheme } = useTheme();
  const { hasPermissionFor } = usePermissions();

  const isSpecificationView = location.pathname.split('/').includes('specification')
  const isWorkspaceView = location.pathname.split('/').includes('workspace')
  const prefixPath = location.pathname.split('/').slice(0, 3).join('/');

  const tabStyle = {
    color: currentTheme.palette.primary.main,
  }
  const MuiTabs = styled(Tabs)(({ theme }) => ({
    marginTop: theme.spacing(-4),
    padding: theme.spacing(0),
    borderBottom:'1px solid',
    borderColor: currentTheme.palette.secondary.main,
    '& .Mui-selected': {
      fontSize: 14,
      fontFamily:'Montserrat',
      color: currentTheme.palette.secondary.main,
      transition: 'font-size 10ms, font-weight 10ms',
    },
    '& .MuiTabs-indicator': {
      height: 3,
      width: '0',
      borderRadius: '10px 10px 0 0',
      transition: 'width 0.3s',
      backgroundColor: currentTheme.palette.secondary.main,
      marginBottom: theme.spacing(0)
    },
    '& .MuiTabs-indicator[data-indicator-color="secondary"]': {
      backgroundColor: currentTheme.palette.secondary.main,
    },
  }));

  const activeTab = location.pathname.split('/')[3];
  const createTab = (label, value, to) => ({ label, value, to });

  const initializeTabs = () => {
    const tabs = [
      createTab(t('scenarios'), 'scenarios', '/scenarios'),
    ];
    if (hasPermissionFor('create_datasets')) {
      tabs.push(createTab(t('datasets'), 'datasets', '/datasets'));
    }
    if (hasPermissionFor('create_users') && !isWorkspaceView) {
      tabs.push(createTab(t('users'), 'users', '/users'));
    }
    return tabs;
  };
  const getTabValue = () => activeTab || 'scenarios';
  const tabs = initializeTabs();
  return (
    <>
      {!isSpecificationView && (
        <Box my={3} display="flex" justifyContent="center" sx={{height:'40px'}}>
          <MuiToolbar>
            <MuiGridBox container spacing={4}>
              <ThemeProvider theme={themeCrowdView}>
                <MuiTabs
                  value={getTabValue()}
                  orientation='horizontal'
                >
                  {tabs.map((t) => (
                    <MuiTab
                      style={tabStyle}
                      label={t.label}
                      value={t.value}
                      key={t.value}
                      component={NavLink}
                      to={`${prefixPath}${t.to}`}
                      data-cy={t.value}
                    />
                  ))}
                </MuiTabs>
              </ThemeProvider>
            </MuiGridBox>
          </MuiToolbar>
        </Box>
      )}
    </>
  )
}

export default TabsCustoms;
