const enginePaths = {
  aggregate_answers: '/aggregation/answers',
  aggregate_topics_segments: '/aggregation/label-segments',
  aggregate_list: "/aggregation/list",
  aggregate_category: "/aggregation/categorical",
  aggregate_statistic: "/aggregation/statistic",
  query_similarity: "/query-similarity",
  global_subtopic_inference: "/topics/multitopic/subtopics",
  topic_inference: "/topics",
  subtopics: "/topics/subtopics",
  qa: "/query",
  qa_hints: "/query-hints",
  partial_classification: "/classify/partial-classification/llm",
  classification: "/classify/llm"
}

export default enginePaths;
