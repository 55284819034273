import {Box, CircularProgress, Divider, Grid, Typography} from "@mui/material";
import axiosEngineInstance
  from "../../../../../../../../api/axios/axiosEngineInstance";
import enginePaths from "../../../../../../../../api/enginePaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useEffect, useState} from "react";

export const ClassificationSample = ({
                                       answerVariable,
                                       collection,
                                       dataRegion,
                                       topic
                                     }) => {

  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    let segmentationValues = [topic.topic]
    if (topic.subtopics?.length > 0) {
      segmentationValues = topic.subtopics.map(st => st.topic)
    }
    const data = {
      surveyId: collection,
      questionId: answerVariable,
      statistics: {},
      segmentation: {
        [`${answerVariable}_label`]: segmentationValues
      },
      similarity_query: "",
      similarity: {},
      audioVariable: null,
      statVars: null,
      currentPage: 0,
      itemsPerPage: 5,
      region: dataRegion || "US"
    };
    try {
      const response = await axiosEngineInstance.post(enginePaths.aggregate_list + '?nocache=true', data, status200);
      const responseData = response?.data?.rows || [];
      setAnswers(responseData);
    } catch (error) {
      console.log("Error fetching List widget data", error);
      setAnswers([])
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [answerVariable]);

  return (
    <Grid container direction='column' sx={{width: '100%'}}
          alignContent='flex-start' justifyContent='center'
          justifyItems='center' wrap={'nowrap'}
          rowSpacing={'1px'}>
      {(isLoading) ? (
        <Grid item>
          <Box
            sx={{width: '140px', height: '140px'}}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <CircularProgress/>
          </Box>
        </Grid>
      ) : (
        <Box sx={{maxHeight: '440px', overflowY: 'auto'}}>
          {answers?.length > 0 ? (answers.map((answer, index) => {
            return (
              <Grid container item key={`answer-${index}`}
                    sx={{
                      width: '100%',
                      backgroundColor: '#FAFAFA',
                      paddingTop: '16px',
                      paddingBottom: '16px'
                    }}>
                <Typography sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  color: '#424242',
                  textAlign: 'justify',
                  fontStyle: 'italic',
                  fontWeight: '400',
                  lineHeight: '20px',
                  letterSpacing: "0.004em",
                  marginBottom: '10px',
                  marginTop: '10px',
                }}>
                  "{answer[answerVariable]}"
                </Typography>
                {(index !== answers.length - 1) &&
                  <Divider sx={{width: '100%'}}/>}
              </Grid>
            )
          })) : (
            <Typography sx={{
              fontFamily: "Montserrat",
              fontSize: '18px',
              textAlign: 'center',
              height: '100%',
              width: '100%',
              fontWeight: 500,
              letterSpacing: '0.004em'
            }}>No quotes found for this topic</Typography>
          )}
        </Box>
      )
      }
    </Grid>
  );
}
