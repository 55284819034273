import {Grid, Typography} from "@mui/material";
import {
  IconContainer,
  MuiCancelActionButton,
  MuiNextActionButton
} from "./styles/datasetUsageConfirmation";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import {useLocation} from "react-router-dom";
import {setTargetCollection} from "../../../../../store/appSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "../../../../providers/TranslationProvider";

export const DatasetUsageConfirmation = ({onClose, collectionInfo}) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleGoToBoard = () => {
    const newLocation = location.pathname.replace("datasets", "scenarios/create/specification");
    const protocol = window.location.protocol
    const domain = window.location.host
    window.location.href = `${protocol}//${domain}${newLocation}`;
  }
  return (
    <Grid container sx={{width: '100%', height: '100%'}} direction='column'
          rowGap={'16px'}>
      <Grid item alignItems='center' alignContent='center'
            justifyContent={'center'} justifyItems={'center'}>
        <IconContainer>
          <ViewComfyIcon sx={{
            width: '18px',
            height: '18px',
            color: 'primary.main'
          }}/>
        </IconContainer>
      </Grid>
      <Grid container item direction='row' alignItems='center' alignContent='center'>
        <Typography sx={{
          fontFamily: 'Raleway',
          fontSize: '25px'
        }}>
          {t('dataset_creation_redirection_title')}
        </Typography>
      </Grid>
      <Grid container item direction='row' columnGap={'4px'} xs={true} sx={{maxHeight:'16px'}} justifyContent='flex-start' alignContent='center'>
        <Typography sx={{
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontWeight: 500,
          color: '#424242',
          textAlign:'left',
          lineHeight:'16px',
          verticalAlign: 'middle'
        }}>{t('name')}:</Typography>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 400,
            color: '#424242',
            textAlign:'left',
            lineHeight:'16px',
            verticalAlign: 'middle'
          }}
        >{collectionInfo.name}</Typography>
      </Grid>
      <Grid container item xs={true} direction='row' columnGap={'8px'} sx={{maxHeight:'40px'}} justifyContent='center'
            alignContent='center'>
        <MuiCancelActionButton sx={{
          height: '37px',
          width: '201px',
          fontFamily: 'Montserrat',
          fontSize: '14px',
          letterSpacing: '0.0025em',
          borderRadius: '20px',
          padding: '10px 24px',
        }}
                onClick={() => {
                  dispatch(setTargetCollection(null));
                  onClose();
                }}
        >
          {t('continue_to_list')}
        </MuiCancelActionButton>
        <MuiNextActionButton
          variant='outlined'
          sx={{
            height: '37px',
            width: '201px',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            letterSpacing: '0.0025em',
            borderRadius: '20px',
            padding: '10px 24px'
          }}
          onClick={handleGoToBoard}
        >
          {t('create_board')}
        </MuiNextActionButton>
      </Grid>
    </Grid>
  )
}
