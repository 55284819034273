import {Grid, Paper} from '@mui/material';
import Toast from '../../../../common/Toast';
import * as _ from 'lodash';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../../providers/AuthProvider';
import useScheme from '../../../../../api/hooks/useScheme';
import AnalysisPage from './AnalysisPage';
import {useLoading} from '../../../../providers/LoadingProvider';
import {
  arrowLeftStyle,
  divTabStyle,
  fabIconInsigthsStyle,
  gridContainerTreeMapStyle,
  gridInsightsStyle,
  gridItemStyle,
  MuiContainer,
  MuiPaper,
  MuiTab,
  MuiTabs,
  MuiToolbar,
  paperWidgetsStyle,
  SubordinateQuestionIcon,
  titleTabStyle,
  treeMapGridStyle,
  treePageStyle
} from './styles/treeView';
import {useDispatch, useSelector} from "react-redux";
import {
  setInsightsFilter,
  setSegmentationFilter,
  setSelectedScenarioTab,
  setSimilarityFilter,
  setSimilarityQuery,
  setStatisticFilter,
  setTargetScheme,
  setTreeMapFilter,
  setTreemapLoadingProgress,
  setWidgetFoldedState
} from '../../../../../store/appSlice';
import FilterIcon from "@mui/icons-material/FilterList";
import AIPanel from '../../../../common/widgets/AIPanel';
import {useTranslation} from '../../../../providers/TranslationProvider';
import Fab from '@mui/material/Fab';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Segments from '../../../../common/widgets/Segments';
import {AudioControlProvider} from "../../../../providers/VoiceProvider";
import {CacheProvider} from "../../../../providers/CacheContext";
import ColorPaletteSelector from "./ColorPaletteSelector";
import axiosInstance from "../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../api/apiPaths";
import {status200} from "../../../../../api/status.utils";

//remember add to state the scheme name
const AnalysisView = () => {
  const [severity, setSeverity] = React.useState('success')
  const [toastMsg, setToastMsg] = React.useState(null)
  const [openToast, setOpenToast] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [tabs, setTabs] = React.useState([]);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const {t, lng} = useTranslation();
  const {user} = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const localTreeMapFilter = useSelector(state => state.app.treeMapFilter);
  const localSegmentationFilter = useSelector(state => state.app.segmentationFilter);
  const localStatisticFilter = useSelector(state => state.app.statisticFilter);
  const localInsightsFilter = useSelector(state => state.app.insightsFilter);
  const [insights, setInsights] = useState({});
  const [widgets, setWidgets] = useState({});
  const [showInsightsCard, setShowInsightsCard] = useState(true);
  const [treeMapSize, setTreeMapSize] = useState(8);
  const [colapseInsightsRightPosition, setColapseInsightsRightPosition] = useState(24.7);
  const analysisVisualizationType = useSelector(state => state.app.analysisVisualizationType);
  const gridInsightsRef = React.useRef(null);
  const orgId =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const scenario = useScheme({
    user_id: orgId,
    scheme_id: location.pathname.split('/')[4],
  });
  const {setIsLoading} = useLoading();
  let scheme = {};
  let collection = {};

  const prevTreeMapFilter = useRef(localTreeMapFilter);
  const prevSegmentationFilter = useRef(localSegmentationFilter);
  const prevStatisticFilter = useRef(localStatisticFilter);

  const updateBoardState = async (payload) => {
    await axiosInstance.post(apiPaths.boardState, payload, status200).then((resp) => resp.data).catch((error) => {
      console.log('Error updating user board state', error);
    });
  }

  useEffect(async () => {
    const currentTargetScheme = scenario?.data?.scheme;

    // Function to check if filters have actually changed
    const filtersChanged = (
      !_.isEqual(localTreeMapFilter, prevTreeMapFilter.current) ||
      !_.isEqual(localSegmentationFilter, prevSegmentationFilter.current) ||
      !_.isEqual(localStatisticFilter, prevStatisticFilter.current)
    );

    if (Object.keys(currentTargetScheme).length > 0 && currentTargetScheme.id && filtersChanged) {
      console.log("Entered updateBoardState");

      const updatedBoardState = {
        treeMapFilter: localTreeMapFilter,
        segmentationFilter: localSegmentationFilter,
        statisticFilter: localStatisticFilter,
        insightsFilter: localInsightsFilter
      };

      if (!_.isEqual(updatedBoardState, scenario?.data?.boardState)) {
        await updateBoardState({
          orgId: orgId,
          schemeId: scenario?.data?.scheme?.id,
          boardState: updatedBoardState
        });
      }

      // Update previous filter refs with current values
      prevTreeMapFilter.current = localTreeMapFilter;
      prevSegmentationFilter.current = localSegmentationFilter;
      prevStatisticFilter.current = localStatisticFilter;
    }
  }, [localTreeMapFilter, localSegmentationFilter, localStatisticFilter, scenario?.data?.scheme]);

  const handleCloseToast = () => {
    setOpenToast(false);
    setSeverity('success')
    setToastMsg(null);
  };

  useEffect(() => {
    if (tabs.length > 0 && activeTab >= 0 && tabs[activeTab]?.config?.policy?.id) {
      const vt = analysisVisualizationType[tabs[activeTab]?.config?.policy?.id]
      if (vt === 'art') {
        setShowInsightsCard(false);
      } else {
        setShowInsightsCard(true);
      }
    }
  }, [analysisVisualizationType]);

  const handleTabsChange = (event, newValue) => {
    setActiveTab(newValue);
    if (tabs && newValue >= 0 && tabs[newValue]?.config) {
      dispatch(setSelectedScenarioTab(tabs[newValue].config.policy.id))
    }
  };

  const initChartConfig = React.useCallback(() => {
    let newTabs = [];

    if (scenario && scenario.data) {
      scheme = scenario.data.scheme;
      collection = scenario.data.collection;
      dispatch(setTargetScheme(scenario.data.scheme))
      const boardState = scenario.data.scheme?.boardState?.[user.id];
      if (boardState) {
        if (boardState.treeMapFilter && boardState.treeMapFilter !== localTreeMapFilter) {
          dispatch(setTreeMapFilter(boardState.treeMapFilter || {}));
        }
        if (boardState.segmentationFilter && boardState.segmentationFilter !== localSegmentationFilter) {
          dispatch(setSegmentationFilter({...boardState.segmentationFilter} || {}));
        }
        if (boardState.statisticFilter && boardState.statisticFilter !== localStatisticFilter) {
          dispatch(setStatisticFilter(boardState.statisticFilter || {}));
        }
        if (boardState.insightsFilter && boardState.insightsFilter !== localInsightsFilter) {
          dispatch(setInsightsFilter(boardState.insightsFilter || {}));
        }
      }
      if (scheme && collection && Object.keys(collection).length > 0) {
        let categoricalVars = collection.variables.filter((v) => v.category === 'categorical');
        let statisticVars = collection.variables.filter((v) => v.category === 'statistic');
        let answerVars = collection.variables.filter((v) => v.category === 'answer');
        const hasAudio = scheme.policies.some((p) => !!p.audioVar);
        // NORMAL POLICIES
        newTabs = scheme.policies.map((d, i) => {
          dispatch(setTreemapLoadingProgress({
            id: d.id,
            progress: 0
          }));
          return {
            id: 'tab-' + i,
            policy_id: d.id,
            policy_name: 'pol_' + i,
            name: d.name,
            type: 'normal',
            config: {
              policy: d,
              isSegmentedPolicy: d.enabledSegmentClassification || !!d.parentPolicy,
              weight: scheme.weight,
              collection_name: scheme.dataset,
              collection_region: collection.region,
              segmentationVars: categoricalVars,
              statisticVars: statisticVars,
              selectedSegmentationVars: scheme.segmentation_variables || [],
              selectedStatisticVars: scheme.statistic_variables || [],
              answerVarLabel: answerVars.filter((v) => v.propName === d.answerVar)[0].label,
              hasAudio: hasAudio,
              analysisObjective: scheme.analysisObjective,
              contextDescription: scheme.contextDescription,
              audienceDescription: scheme.audienceDescription,
            },
          };
        });
      }
      setTabs(newTabs);
    }


  }, [scenario.data.scheme, scenario.data.collection]);

  const initializeTabWidgets = (tabData, tabIndex) => {
    let localWidgetData = [];
    let categoryWidgetData = tabData.config.selectedSegmentationVars.map((seg_var) => {
      let segmentationLabel = seg_var
      tabData.config.segmentationVars.map((sVar) => {
        if (sVar.propName === seg_var) {
          segmentationLabel = sVar.label;
        }
        return true;
      })

      let widgetInfo = {
        id: `${seg_var}`,
        name: segmentationLabel,
        column: seg_var,
        answerVar: tabData.config.policy.answerVar,
        dataSource: tabData.config.collection_name,
        dataRegion: tabData.config.collection_region,
        weight: tabData.config.weight,
        enableSegmentAggregation: !tabData.config.isSegmentedPolicy || tabData.config.policy.parentPolicy !== null,
        type: "category",
        foldedState: true,
        visible: true,
        policyId: tabData.config.policy.id,
        policyTabIndex: tabIndex,
        description: tabData.config.segmentationVars.find((v) => v.propName === seg_var)?.description || ''
      };
      dispatch(setWidgetFoldedState({
        widgetId: `${seg_var}`,
        foldedState: true
      }));
      return widgetInfo;
    });
    let statisticWidgetData = tabData.config.selectedStatisticVars.map((stat) => {
      let statisticLabel = stat
      tabData.config.statisticVars.map((sVar) => {
        if (sVar.propName === stat) {
          statisticLabel = sVar.label;
        }
        return true;
      });
      let widgetInfo = {
        id: `${stat}`,
        name: statisticLabel,
        column: stat,
        answerVar: tabData.config.policy.answerVar,
        dataSource: tabData.config.collection_name,
        dataRegion: tabData.config.collection_region,
        weight: tabData.config.weight,
        type: "histogram",
        foldedState: true,
        visible: true,
        policyId: tabData.config.policy.id,
        policyTabIndex: tabIndex,
        description: tabData.config.segmentationVars.find((v) => v.propName === stat)?.description || ''
      };
      dispatch(setWidgetFoldedState({
        widgetId: `${stat}`,
        foldedState: true
      }));
      return widgetInfo;
    });
    localWidgetData = [...localWidgetData, ...categoryWidgetData, ...statisticWidgetData];
    return localWidgetData;
  }

  const initializeWidgets = () => {
    if (activeTab >= 0 && tabs[activeTab] && Object.keys(widgets).length === 0) {
      dispatch(setSegmentationFilter({}));
      dispatch(setStatisticFilter({}));
      dispatch(setTreeMapFilter({}));
      const localWidgets = {};
      tabs.forEach((tabData, index) => {
        localWidgets[index] = initializeTabWidgets(tabData, index);
      })
      setWidgets(localWidgets);
    }
  }
  const initializeInsights = () => {
    if (activeTab >= 0 && tabs[activeTab] && Object.keys(insights).length === 0) {
      dispatch(setInsightsFilter({}));
      dispatch(setSimilarityFilter({}));
      dispatch(setSimilarityQuery({}));
      const localInsights = {};
      tabs.forEach((tabData, index) => {
        let localWidgetData = [];
        const similarityWidgetData = {
          id: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_similarity`,
          name: t('semantic_analysis'),
          column: tabData.config.policy.answerVar,
          dataSource: tabData.config.collection_name,
          dataRegion: tabData.config.collection_region,
          weight: tabData.config.weight,
          type: "similarity",
          folded_state: true,
          visible: true,
          policyId: tabData.config.policy.id,
          segmentationVariables: tabData.config.segmentationVars.map(segVar => {
            return {
              name: segVar.propName,
              label: segVar.label
            }
          }),
          statisticVariables: tabData.config.statisticVars.map(statVar => {
            return {
              name: statVar.propName,
              label: statVar.label
            }
          }),
          answerVarLabel: tabData.config.answerVarLabel,
          policyTabIndex: index
        };
        dispatch(setWidgetFoldedState({
          widgetId: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_similarity`,
          foldedState: true
        }))
        localWidgetData.push(similarityWidgetData);
        const segmentationVariables = tabData.config.segmentationVars.map(segVar => {
          return {
            name: segVar.propName,
            label: segVar.label,
            description: segVar.description,
            category: segVar.category
          }
        });

        const listWidgetData = {
          id: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_list`,
          name: tabData.config.answerVarLabel,
          column: tabData.config.policy.answerVar,
          audioColumn: tabData.config.policy.audioVar || null,
          title: "Quotes",
          dataSource: tabData.config.collection_name,
          dataRegion: tabData.config.collection_region,
          policyId: tabData.config.policy.id,
          statVars: tabData.config.statisticVars.length > 0 ? tabData.config.statisticVars : null,
          type: "list",
          folded_state: false,
          visible: true,
          policyTabIndex: index
        }
        localWidgetData.push(listWidgetData);
        dispatch(setWidgetFoldedState({
          widgetId: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_list`,
          foldedState: false
        }))
        if (tabData.config.policy.customSegmentations?.enabled && tabData.config.policy.customSegmentations?.segmentations?.length > 0) {
          const policy = tabData.config.policy;
          const customCatWidgetData = policy.customSegmentations?.segmentations?.filter(segmentation => segmentation.classificationPerformed).map((segmentation) => {
            dispatch(setWidgetFoldedState({
              widgetId: segmentation.classificationVariable,
              foldedState: false
            }));
            segmentationVariables.push({
              name: segmentation.classificationVariable,
              label: segmentation.label
            });
            return {
              id: segmentation.classificationVariable,
              name: segmentation.label,
              column: segmentation.classificationVariable,
              answerVar: tabs[activeTab].config.policy.answerVar,
              dataSource: tabs[activeTab].config.collection_name,
              dataRegion: tabs[activeTab].config.collection_region,
              weight: tabs[activeTab].config.weight,
              categories: segmentation.categories,
              type: "segments",
              foldedState: false,
              visible: true,
              policyId: policy.id,
              policyTabIndex: index,
            };

          })
          if (customCatWidgetData?.length > 0) {
            localWidgetData = [...localWidgetData, ...customCatWidgetData];
          }

        }
        const qaWidgetData = {
          id: `${tabData.config.policy.answerVar}_${tabData.config.policy.id}_similarity`,
          name: t('semantic_analysis'),
          column: tabData.config.policy.answerVar,
          dataSource: tabData.config.collection_name,
          dataRegion: tabData.config.collection_region,
          weight: tabData.config.weight,
          type: "qa",
          foldedState: false,
          visible: true,
          policyId: tabData.config.policy.id,
          segmentationVariables: segmentationVariables,
          statisticVariables: tabData.config.statisticVars.map(statVar => {
            return {
              name: statVar.propName,
              label: statVar.label
            }
          }),
          answerVarLabel: tabData.config.answerVarLabel,
          policyTabIndex: index,
          analysisObjective: tabData.config.analysisObjective,
          contextDescription: tabData.config.contextDescription,
          audienceDescription: tabData.config.audienceDescription,
          originalQuestionText: tabData.config.policy.longName,
          mainTopics: tabData.config.policy.topics,
          hints: tabData.config.policy.hints
        };
        localWidgetData.push(qaWidgetData);
        localInsights[index] = localWidgetData;
      });

      setInsights(localInsights);
    }
  }


  const handleToggleInsightsCard = () => {
    setShowInsightsCard(!showInsightsCard);
  };

  React.useEffect(() => {
    setIsLoading(scenario.isLoading);
  }, [scenario.isLoading]);

  React.useEffect(() => {
    if (scenario.data.scheme && scenario.data.collection && Object.keys(scenario.data.collection).length > 0) {
      initChartConfig();
    }
  }, [scenario.data.scheme, localTreeMapFilter]);

  React.useEffect(() => {
    initializeWidgets();
    initializeInsights();
  }, [tabs, lng]);

  useEffect(() => {
    if (tabs && activeTab >= 0 && tabs[activeTab]?.config) {
      dispatch(setSelectedScenarioTab(tabs[activeTab].config.policy.id))
    }
  }, [activeTab, tabs]);

  useEffect(() => {
    if (showInsightsCard) {
      setTreeMapSize(8);
    } else {
      setTreeMapSize(12);
    }
    let widgetData = widgets[activeTab]
    if (!widgetData || widgetData.length === 0) {
      setColapseInsightsRightPosition(33.0);
    } else {
      setColapseInsightsRightPosition(24.7);
    }

  }, [widgets, insights, showInsightsCard]);

  useEffect(() => {
    // Function to update window height
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const anyAudio = tabs.reduce((acc, t) => t.config.hasAudio || acc, false);
  return (
    <MuiContainer>
      <Toast
        message={toastMsg}
        handleClose={handleCloseToast}
        severity={severity}
        horizontal='left'
        vertical='bottom'
        open={openToast}
      />
      {scenario.data.scheme.dataset !== '' && (
        <CacheProvider>
          <MuiToolbar>
            {(tabs[activeTab] !== undefined && widgets && widgets[activeTab]?.length > 0) && (
              <Grid item xs={widgets[activeTab]?.length > 0 ? 3 : 0}
                    style={gridItemStyle}>
                <Paper elevation={0} style={{
                  ...paperWidgetsStyle,
                  height: windowHeight - 74
                }}>
                  {tabs.map((tab, index) => {
                    return (
                      <div
                        style={{
                          display: activeTab === index ? 'block' : 'none',
                          overflow: 'hidden',
                          maxHeight: windowHeight - 85
                        }}
                        id={`${t.policy_id}_widgets-${index}`}
                        key={`${t.policy_id}_widgets-${index}`}>
                        <Segments widgets={widgets[index]}
                                  policyId={tab.policy_id}
                                  height={windowHeight - 85}
                        />
                      </div>
                    )
                  })}
                </Paper>
              </Grid>
            )}
            <Grid container item xs={widgets[activeTab]?.length > 0 ? 9 : 12}
                  direction={'row'}
                  style={gridContainerTreeMapStyle}>
              <MuiPaper
                elevation={1}
                style={{
                  height: windowHeight - 75,
                  borderWidth: '1px',
                  borderColor: '#EEEEEE',
                  marginRight: "2px"
                }}>
                <AudioControlProvider>
                  <Grid container direction='row'>
                    <Grid item xs={treeMapSize}
                          style={{
                            ...treeMapGridStyle,
                            height: windowHeight - 74,
                            paddingRight: '2px'
                          }}>
                      <MuiTabs
                        variant='scrollable'
                        scrollButtons='auto'
                        value={activeTab}
                        onChange={($event, value) => handleTabsChange($event, value)}
                        orientation='horizontal'
                        indicatorColor='secondary'
                      >
                        {tabs.map((t, index) => {
                          let treeFilterApplied = false;
                          if (localTreeMapFilter[t.policy_id] && localTreeMapFilter[t.policy_id][`${t.config.policy.answerVar}_label`] && localTreeMapFilter[t.policy_id][`${t.config.policy.answerVar}_label`].length > 0) {
                            treeFilterApplied = true;
                          }
                          return (
                            <MuiTab label={
                              (<div style={divTabStyle}>
                                {!!t.config.policy.parentPolicy && (
                                  <SubordinateQuestionIcon
                                    color={activeTab === index ? "primary" : "#424242"}/>
                                )}
                                <span style={titleTabStyle}>
                              {t.name}
                            </span>
                                {treeFilterApplied &&
                                  <FilterIcon style={{marginLeft: '5px'}}
                                              color={activeTab === index ? "primary" : "#424242"}/>}
                              </div>)
                            }
                                    value={index}
                                    key={t.policy_id + "_" + t.name}
                                    data-cy={t.name}
                            />
                          )
                        })}
                      </MuiTabs>
                      <ColorPaletteSelector/>
                      {tabs.map((tab, index) => (
                        <AnalysisPage
                          style={treePageStyle}
                          activeTab={activeTab}
                          index={index}
                          tabData={tab}
                          key={tab.policy_id + "_treemap"}
                          showProgressBar={anyAudio}
                        />
                      ))}
                    </Grid>
                    {(tabs[activeTab] !== undefined && Object.hasOwn(insights || {}, activeTab))
                      && (
                        <Grid
                          id='insights-container'
                          item
                          xs={4}
                          style={{
                            ...gridInsightsStyle,
                            right: showInsightsCard ? '5px' : '-100%',
                            maxHeight: windowHeight - 65,
                            opacity: showInsightsCard ? 1 : 0,
                            visibility: showInsightsCard ? 'visible' : 'hidden',
                          }}
                          ref={gridInsightsRef}
                        >
                          {
                            tabs.map((t, index) => {
                              return (
                                <div
                                  style={{
                                    display: activeTab === index ? 'block' : 'none',
                                    overflow: 'hidden',
                                    maxHeight: windowHeight - 85
                                  }}
                                  id={`${t.policy_id}_segment-${index}`}
                                  key={`${t.policy_id}_segment-${index}`}>

                                  <AIPanel
                                    widgets={insights[index]}
                                    activePolicyTab={activeTab}
                                    policyId={t.policy_id}
                                    isFilterCommon={false}
                                    height={windowHeight - 74} // 65 is the height of the mi
                                  />
                                </div>
                              )
                            })
                          }
                        </Grid>
                      )}
                  </Grid>
                </AudioControlProvider>
              </MuiPaper>
              <div style={{position: 'relative'}} id='toogle-insights-control'>
                <Fab
                  size='small'
                  sx={{
                    ...fabIconInsigthsStyle,
                    right: showInsightsCard ? `calc(${colapseInsightsRightPosition}%)` : '-1px'
                  }}
                  color="inherit"
                  onClick={handleToggleInsightsCard}
                >
                  {showInsightsCard ? (<ArrowRightIcon fontSize="large"
                                                       sx={{color: '#757575'}}/>) :
                    (<ArrowLeftIcon fontSize="large" style={arrowLeftStyle}/>)
                  }
                </Fab>
              </div>
            </Grid>
          </MuiToolbar>
        </CacheProvider>
      )}
    </MuiContainer>
  );
};

export default AnalysisView;
