import {Button, Chip, lighten, Paper, styled, TextField} from "@mui/material";
import {darken} from "@mui/material/styles";

export const MuiChip = styled(Chip)(({theme}) => {
  // Lighten and darken the primary color for background and border
  const lightenedColor = lighten(theme.palette.primary.main, 0.875);
  const textColor = darken(theme.palette.primary.main, 0.558);
  return {
    backgroundColor: lightenedColor,
    color: textColor,
    fontSize:'14px',
    fontFamily:'Montserrat',
    fontWeight: 400,
    maxWidth: '100%',
    overflow: 'visible',
    borderRadius: '8px',
    border: `1px solid ${lightenedColor}`,
    height: '30px'
  };
});

export const MuiSegmentContainer = styled(Paper)(({theme}) => ({
  padding: '16px',
  margin: '10px 10px 10px 0px',
  position: 'relative',
  borderRadius: '12px',
  border: '1px solid #E0E0E0'
}))
export const MuiTextField = styled(TextField)(({theme}) => ({
  height: '56px',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#143440'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
}));

export const MuiSecondaryButton = styled(Button)(({theme}) => ({
  gap: '8px',
  padding: '10px 24px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '37px',
  width: '177px',
  border: 'none',
  borderRadius: '20px',
  color: "#FFFFFF",
  fontFamily: 'Montserrat',
  fontStyle: 'medium',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  letterSpacing: '0.004em',
}));

export const MuiOutlinedButton = styled(Button)(({theme}) => ({
  gap: '8px',
  padding: '10px 24px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '26px',
  width: '169px',
  border: '1px solid #6793A5',
  borderRadius: '20px',
  color: darken(theme.palette.primary.main,0.2),
  fontFamily: 'Montserrat',
  fontStyle: 'medium',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '12px',
  letterSpacing: '0.0025em',
  textTransform:'uppercase'
}));

export const MuiCategoriesContainer = styled("div")(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const MuiCategoryTooltipWrapper = styled("div")(({theme}) => ({
  marginRight: '10px',
  marginBottom: '10px'
}));

