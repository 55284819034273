import * as React from 'react';

import axios from '../../api/axios/axiosInstance';
import axiosEngineInstance from '../../api/axios/axiosEngineInstance';
import {useAuth} from '../providers/AuthProvider';

// * Maybe we need more than one axios instance
const axiosInstances = [axios];


const TokenInterceptor = ({children, accessToken: getAccessToken,engineToken: getEngineToken}) => {
  const {logout, isAuthenticated} = useAuth();

  React.useEffect(() => {
    const addResponseInterceptor = (axiosInstances) =>
      axiosInstances.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          const shouldRefreshToken = isAuthenticated && error.response?.status === 401;

          if (shouldRefreshToken) {
            return logout();
          }

          return Promise.reject(error.response || error);
        }
      );

    const errorInterceptors = axiosInstances.map((instance) => addResponseInterceptor(instance));

    return () => {
      axiosInstances.forEach((instance, i) =>
        instance.interceptors.response.eject(errorInterceptors[i])
      );

    };
  }, [isAuthenticated, logout]);

  React.useEffect(() => {
    const addRequestInterceptor = (axiosInstance) =>
      axiosInstance.interceptors.request.use(
        (config) => {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${getAccessToken}`,
            },
          };
        },
        (error) => Promise.reject(error)
      );

    const authInterceptors = axiosInstances.map((instance) => addRequestInterceptor(instance));
    const engineAuthInterceptor= axiosEngineInstance.interceptors.request.use(
      (config) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${getEngineToken()}`,
          },
        };
      },
      (error) => Promise.reject(error)
    )
    return () => {
      axiosInstances.forEach((instance, i) =>
        instance.interceptors.request.eject(authInterceptors[i])
      );

      axiosEngineInstance.interceptors.request.eject(engineAuthInterceptor)
    };
  }, [getAccessToken, getEngineToken]);

  return children;
};

export default TokenInterceptor;
