import {
  Grid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography
} from "@mui/material";
import * as React from "react";
import {useTranslation} from "../../../../providers/TranslationProvider";

export const ConfigureLabelsDescriptions = ({variables, updateVariables}) => {
  const {t} = useTranslation();

  const changeVariableLabel = (index, value) => {
    const newVariables = [...variables];
    newVariables[index].label = value;
    updateVariables(newVariables);
  }

  const changeVariableDescription = (index, value) => {
    const newVariables = [...variables];
    newVariables[index].description = value;
    updateVariables(newVariables);
  }

  const insertBreakPoints = (str) => str.replace(/_/g, '_\u200B');
  return (
    <Grid item xs={true} sx={{height: '100%'}}>
      <TableContainer component={"div"}
                      sx={{maxHeight: '400px', height: '400px', width: '100%'}}>
        <MuiTable stickyHeader size="small" aria-label='simple table'
                  sx={{width: '100%'}}>
          <TableHead sx={{ '&:not(:last-child) td, &:not(:last-child) th': { border: 0 } }}>
            <TableRow sx={{ '&:not(:last-child) td, &:not(:last-child) th': { border: 0 } }}>
              <TableCell sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
              }}>{t('variable')}</TableCell>
              <TableCell sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
              }}>{t('custom_label')}</TableCell>
              <TableCell sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
              }}>{t('variable_description')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variables?.map((variable, vIndex) => {
              if(variable.category === 'ignore') return null;
              return (
                <TableRow key={'d-' + vIndex} data-cy='var_row' sx={{maxHeight:'52px','& td, & th': { border: 0 }}}>
                  <TableCell component='th' scope='row' sx={{maxHeight:'52px'}}>
                    <Typography sx={{
                      color: '#424242',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      letterSpacing: '0.0025em',
                      display: 'flex',
                      textAlign: 'left',
                      wordWrap: 'break-word', // Ensures words break when necessary
                      whiteSpace: 'normal',   // Allows the text to wrap naturally
                      width: '120px',         // Set the desired maximum width
                    }}>
                      {insertBreakPoints(variable.propName)}
                    </Typography>
                  </TableCell>
                  <TableCell component='th' scope='row'
                             sx={{minWidth: '250px', verticalAlign: 'middle',maxHeight:'52px'}}>
                    <MuiTextField
                      label=""
                      id="outlined-size-small"
                      defaultValue={variable.label ? variable.label : variable.propName}
                      variant="outlined"
                      onChange={(e) => changeVariableLabel(vIndex, e.target.value)}
                      size="small"
                      sx={{
                        width: '100%',
                        height:'52px',
                        maxHeight: '52px !important',
                        '& .MuiInputBase-input': {
                          fontFamily: 'Montserrat',
                          fontSize: '14px',
                          lineHeight:'16px',
                          color: '#424242',
                          maxHeight:'34px',
                          height:'34px'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell component='th' scope='row'
                             sx={{minWidth: '432px', verticalAlign: 'middle',maxHeight:'52px'}}>
                    <MuiTextField
                      label=""
                      multiline
                      maxRows={2}
                      id="outlined-size-small"
                      defaultValue={variable.description||''}
                      variant="outlined"
                      onChange={(e) => changeVariableDescription(vIndex, e.target.value)}
                      size="small"
                      sx={{
                        width: '100%',
                        height: '52px',
                        maxHeight:'52px',
                        '& .MuiInputBase-input': {
                          fontFamily: 'Montserrat',
                          fontSize: '14px',
                          lineHeight:'16px',
                          color: '#424242',
                        },
                        '& .MuiInputBase-root': {
                          height:'52px'
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Grid>
  )
}
