import React, {useState} from 'react';
import Popover from '@mui/material/Popover';
import { infoIcon, MuiIconClose } from './styles/navigationMenu';
import {InfoOutlined} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from '@mui/material';
import {MuiTypography} from "./styles/navigationMenu";
import {useTranslation} from "../providers/TranslationProvider";

const InfoButtonWithPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation();
  const {scheme, styles: sx} = props

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <InfoOutlined sx={infoIcon} onClick={handleClick}/>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction={'column'} alignItems={'flex-start'} spacing={3}
              justifyContent={'center'} sx={{width: '400px', padding:'10px 24px 24px 24px'}}>
          <Grid container item xs={true} justifyContent={'flex-end'} alignContent={'center'} >
            <Grid xs={true} alignContent={'center'} >
              <MuiTypography variant='h6' >{scheme.name}</MuiTypography>
            </Grid>
            <Grid container item xs={'auto'} alignContent={'center'} >
              <MuiIconClose aria-label="close" onClick={handleClose} sx={{marginTop:'0px'}}>
                <CloseIcon fontSize='small'/>
              </MuiIconClose>
            </Grid>
          </Grid>
          <Grid item xs={true} rowGap={'16px'}>
            <Box display="flex" sx={{width: '100%'}}>
              <MuiTypography variant='caption'>
                <span style={{fontFamily:'Montserrat', fontSize:'16px'}}>{t('objective_analysis')}: </span>
                {scheme.analysisObjective || t('not_provided')}
              </MuiTypography>
            </Box>
            <Box display="flex" sx={{width: '100%'}}>
              <MuiTypography variant='caption'>
                <span style={{fontFamily:'Montserrat', fontSize:'16px'}}>{t('created_date')}: </span>
                {scheme.createdAt || t('not_provided')}
              </MuiTypography>
            </Box>
            <Box display="flex" sx={{width: '100%'}}>
              <MuiTypography variant='caption'>
                <span style={{fontFamily:'Montserrat', fontSize:'16px'}}>{t('population_content')}: </span>
                {scheme.contextDescription || t('not_provided')}
              </MuiTypography>
            </Box>
            <Box display="flex" sx={{width: '100%'}}>
              <MuiTypography variant='caption'>
                <span style={{fontFamily:'Montserrat', fontSize:'16px'}}>{t('audience_description')}: </span>
                {scheme.audienceDescription || t('not_provided')}
              </MuiTypography>
            </Box>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default InfoButtonWithPopover;
