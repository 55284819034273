import * as React from 'react';
import {
  Button as MuiButton,
  Grid,
  MenuItem,
  ThemeProvider,
  Typography
} from '@mui/material';

import * as yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import useCreateOrganization from 'api/hooks/useCreateOrganization';
import useUpdateOrganization from 'api/hooks/useUpdateOrganization';
import useUploadImage from 'api/hooks/useUploadImage';
import {useTranslation} from 'components/providers/TranslationProvider';
import {usePermissions} from 'components/hooks/usePermissions';
import Toast from 'components/common/Toast';
import themeCrowdView from 'theme/crowdView';
import {
  NumericSelect,
  SelectField
} from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import {
  buttonActionStyle,
  colorCommonStyle,
  customDividerStyle,
  gridDividerStyle,
  gridPaddingStyle,
  MuiColorField,
  MuiGridColorPickers,
  MuiGridCommon,
  MuiGridInputText,
  MuiGridTextContainer,
  MuiTextField,
  MuiTypographyPicker
} from './styles/formModal';
import UploadImage from './UploadImage';
import {buttonCancelStyle} from './scenarios/styles/formModal';

const positiveRequiredNumber = yup
  .number()
  .integer('number_integer')
  .positive('number_positive')
  .typeError('field_number')
  .min(1, 'field_more_that_0')
  .required('required');

const schema = yup.object().shape({
  name: yup
    .string()
    .min(4)
    .max(80, 'This field can not exceed 80 characters')
    .required('A text is required'),
  shortName: yup
    .string()
    .min(2)
    .max(12, 'This field can not exceed 12 characters')
    .required('A text is required'),
  defaultRegion:yup.string(),
  primaryColor: yup.string(),
  secondaryColor: yup.string(),
  maxNumbUsers: positiveRequiredNumber,
  maxDataSetSize: positiveRequiredNumber,
  maxNumbDataSet: positiveRequiredNumber,
  maxNumbScenarios: positiveRequiredNumber,
});

const FormModal = ({organization, action, open, onClose}) => {
  const defaultProsperiaImage = 'https://prosperia-ds-platform.s3.amazonaws.com/1622640762526-logo.0c7a85ba.png';

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [previousOrganizationLogo, setPreviousOrganizationLogo] = React.useState('');
  const [previousOrganizationProfileImage, setPreviousOrganizationProfileImage] = React.useState('');
  const [organizationLogo, setOrganizationLogo] = React.useState(
    organization ? organization.attrs.logo_url : defaultProsperiaImage
  );
  const [organizationProfileImage, setOrganizationProfileImage] = React.useState(
    organization ? organization.attrs.profile_img_url : defaultProsperiaImage
  );

  const {t} = useTranslation();
  const {hasPermissionFor} = usePermissions();

  const {
    mutateAsync: uploadImage,
    isError: uploadIsError,
    error: uploadError
  } = useUploadImage();

  const {
    mutateAsync: updateOrganization,
    isSuccess: updateSuccess,
    isError: updateIsError,
    error: updateError,
    isLoading: updateIsLoading,
  } = useUpdateOrganization();
  const {
    mutateAsync: createOrganization,
    isSuccess: createSuccess,
    isError: createIsError,
    error: createError,
    isLoading: createIsLoading,
  } = useCreateOrganization();

  const getDefaultValues = () => {
    return organization
      ? {
        name: organization.name,
        shortName: organization.shortName,
        defaultRegion: organization.defaultRegion||"US",
        primaryColor: organization.attrs.primary_color,
        secondaryColor: organization.attrs.secondary_color,
        maxNumbUsers: organization.attrs.max_users,
        maxDataSetSize: organization.attrs.max_dataset_size,
        maxNumbDataSet: organization.attrs.max_datasets,
        maxNumbScenarios: organization.attrs.max_targeting_schemes,
      }
      : {
        name: '',
        shortName: '',
        defaultRegion: "US",
        primaryColor: '#00BBD4',
        secondaryColor: '#005F64',
        maxNumbUsers: 10,
        maxDataSetSize: 50,
        maxNumbDataSet: 10,
        maxNumbScenarios: 10,
      };
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: {errors, isValid, isDirty},
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleUploadLogo = async (e) => {
    const file = new FormData();
    file.append('image_file', e.target.files[0]);
    const imageUrl = await uploadImage(file);
    setOrganizationLogo(imageUrl);
  };

  const handleUploadProfileImage = async (e) => {
    const file = new FormData();
    file.append('image_file', e.target.files[0]);
    const imageUrl = await uploadImage(file);
    setOrganizationProfileImage(imageUrl);
  };

  const areImagesChanged = () => {
    return (previousOrganizationLogo !== organizationLogo) || (previousOrganizationProfileImage !== organizationProfileImage)
  }

  const organizationForm = (
    <ThemeProvider theme={themeCrowdView}>
      <Toast
        message={error}
        vertical='top'
        horizontal='center'
        severity='error'
        open={openToast}
        handleClose={handleCloseToast}
      />
      <Grid container spacing={2}>
        <MuiGridInputText item xs={12}>
          <MuiTextField
            autoFocus
            name='name'
            margin='dense'
            type='text'
            label={t('org_name')}
            variant='outlined'
            fullWidth
            error={t(errors.name?.message)}
          />
        </MuiGridInputText>
        <MuiGridInputText item xs={12}>
          <MuiTextField
            autoFocus
            name='shortName'
            margin='dense'
            type='text'
            label={t('org_shortName')}
            variant='outlined'
            fullWidth
            error={errors.shortName?.message}
          />
        </MuiGridInputText>
        <MuiGridInputText item xs={12}>
          <SelectField name='defaultRegion' variant='outlined'
                       fullWidth
                       id={'region-autocomplete'} label={t('default_region')||"Default Region"}>
            <MenuItem value="US">US</MenuItem>
            <MenuItem value="SA">SA</MenuItem>
          </SelectField>
        </MuiGridInputText>
        <Grid item xs={12} style={gridDividerStyle}>
          <hr style={customDividerStyle}/>
        </Grid>
        <Grid container justifyContent='center' item xs={6} direction='column'>
          <MuiGridCommon>
            <Typography variant="caption" style={colorCommonStyle} align='center'>{t('org_logo')}</Typography>
          </MuiGridCommon>
          <Grid item>
            <UploadImage
              handleChange={handleUploadLogo}
              image={organizationLogo}
              name='organizationLogo'
            />
          </Grid>
        </Grid>
        <Grid container justifyContent='center' item xs={6}>
          <MuiGridCommon item >
            <Typography variant="caption" style={colorCommonStyle}>{t('org_image')}</Typography>
          </MuiGridCommon>
          <Grid item>
            <UploadImage
              handleChange={handleUploadProfileImage}
              image={organizationProfileImage}
              name='organizationProfileImage'
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr style={customDividerStyle}/>
        </Grid>
        <Grid style={gridPaddingStyle} item xs={6}>
          <MuiGridColorPickers item xs={12}>
            <MuiGridTextContainer container alignContent='center' item xs={10}>
              <MuiTypographyPicker variant='caption'>
                {t('primary_color')}
              </MuiTypographyPicker>
            </MuiGridTextContainer>
            <Grid item xs={2}>
              <MuiColorField
                name='primaryColor'
                fullWidth
                data-cy='primaryColor'
              />
            </Grid>
          </MuiGridColorPickers>
        </Grid>
        <Grid style={gridPaddingStyle} item xs={6}>
          <MuiGridColorPickers item xs={12}>
            <MuiGridTextContainer container alignContent='center' item xs={10}>
              <MuiTypographyPicker variant='caption'>
                {t('secondary_color')}
              </MuiTypographyPicker>
            </MuiGridTextContainer>
            <Grid item xs={2}>
              <MuiColorField
                name='secondaryColor'
                data-cy='secondaryColor'
              />
            </Grid>
          </MuiGridColorPickers>
        </Grid>
        {hasPermissionFor('create_org') && (
          <>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxNumbUsers'
                label={t('max_users_label')}
                error={t(errors.maxNumbUsers?.message)}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxDataSetSize'
                label={t('max_dataset_size_label')}
                error={t(errors.maxDataSetSize?.message)}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxNumbDataSet'
                label={t('max_datasets_label')}
                error={t(errors.maxNumbDataSet?.message)}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxNumbScenarios'
                label={t('max_scenarios_label')}
                error={t(errors.maxNumbScenarios?.message)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );

  const updateOrg = async (data) => {
    const org = {
      name: data.name,
      shortName: data.shortName,
      defaultRegion: data.defaultRegion,
      org_id: organization._id,
      _id: organization._id,
      attrs: {
        logo_url: organizationLogo,
        profile_img_url: organizationProfileImage,
        primary_color: data.primaryColor,
        secondary_color: data.secondaryColor,
        max_dataset_size: data.maxDataSetSize,
        max_datasets: data.maxNumbDataSet,
        max_targeting_schemes: data.maxNumbScenarios,
        max_users: data.maxNumbUsers,
      },
    };
    await updateOrganization(org);
  };

  const newOrg = async (data) => {
    const org = {
      name: data.name,
      shortName: data.shortName,
      defaultRegion: data.defaultRegion,
      attrs: {
        logo_url: organizationLogo || defaultProsperiaImage,
        profile_img_url: organizationProfileImage || defaultProsperiaImage,
        primary_color: data.primaryColor,
        secondary_color: data.secondaryColor,
        max_dataset_size: data.maxDataSetSize,
        max_datasets: data.maxNumbDataSet,
        max_targeting_schemes: data.maxNumbScenarios,
        max_users: data.maxNumbUsers,
      },
    };
    await createOrganization(org);
  };

  React.useEffect(() => {
    if (updateSuccess) {
      onClose();
    }
    if (updateIsError) {
      setError(t(updateError.data.error));
      setOpenToast(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIsError, updateSuccess]);

  React.useEffect(() => {
    if (createSuccess) {
      onClose();
    }
    if (createIsError) {
      setError(t(createError.data?.error || JSON.stringify(createError)));
      setOpenToast(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createIsError, createSuccess]);

  React.useEffect(() => {
    if (uploadIsError) {
      setError(t(uploadError.data?.error || JSON.stringify(uploadError)));
      setOpenToast(true);
    }
  }, [uploadIsError, uploadError, t]);

  React.useEffect(() => {
    if (previousOrganizationLogo === '') {
      setPreviousOrganizationLogo(organizationLogo);
    }
    if (previousOrganizationProfileImage === '') {
      setPreviousOrganizationProfileImage(organizationProfileImage);
    }
  }, []);

  const onSubmit = handleSubmit((data) => (action === 'update' ? updateOrg(data) : newOrg(data)));

  const actions = (
    <>
      <MuiButton onClick={onClose} color='secondary' variant='text'
                 sx={buttonCancelStyle}>
        <Typography fontFamily='Montserrat' fontSize={14} letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </MuiButton>
      <Button
        style={buttonActionStyle}
        onClick={onSubmit}
        variant='outlined'
        type='submit'
        loading={createIsLoading || updateIsLoading}
        disabled={!isValid || createIsLoading || updateIsLoading || (!isDirty && !areImagesChanged()) }
        data-cy='btn_update_org'>
        <Typography fontFamily='Montserrat' fontSize={14} letterSpacing={0.25}>
          {t(action)}
        </Typography>
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={onClose}
          title={
            action === 'update' ? `${t('update')} ${organization.name}` : `${t('create_new_org')}`
          }
          actions={actions}
          widthInPixels={380}
        >
          {organizationForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
