import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useSnackbar} from "notistack";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import _ from "lodash";
import {
  MuiAutocomplete,
  MuiOutlinedButton,
  MuiSelectedToggleButton,
  MuiTextButton,
  MuiToggleButton,
  MUIToggleGroup,
  MuiTooltipTypography,
  MuiTopicAmountSelector,
  MuiTopicAmountSelectorTypography
} from "./styles/topicExtractionControls";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {generateID} from "../../../../../../../../utils/utils";
import axios from "../../../../../../../../api/axios/axiosInstance";
import axiosEngineInstance
  from "../../../../../../../../api/axios/axiosEngineInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import {
  MuiInfoIconButton,
  MuiInfoOutlinedIcon
} from "../../styles/segmentationParameters";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import enginePaths from "../../../../../../../../api/enginePaths";
import {labelInputStyle} from "../../styles/generalParameters";


export const TopicExtractionControls = ({
                                          question,
                                          parentQuestionCandidates,
                                          segmentCandidates,
                                          dataRegion,
                                          collection,
                                          weightVariable,
                                          handleQuestionChange,
                                          analysisObjective,
                                          contextDescription,
                                          stakeholderDescription,
                                        }) => {

  const [maxTopics, setMaxTopics] = useState(5);
  const [conditionalSegmentType, setConditionalSegmentType] = useState('None');//'None', 'Question', 'Segment'
  const [selectedConditionalSegment, setSelectedConditionalSegment] = useState(null);
  const [selectedParentPolicy, setSelectedParentPolicy] = useState(null);
  const [topicExtractionInfoAnchor, setTopicExtractionInfoAnchor] = useState(null);
  const [topicExtractionInfoText, setTopicExtractionInfoText] = useState('');
  const topicDiscoveryModalAnchor = useRef(null);
  const [openTopicDiscovery, setOpenTopicDiscovery] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const {t, lng} = useTranslation();
  const {
    setIsTopicInferenceLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  const initialize = () => {
    if (question.topics.length === 0) {
      setMaxTopics(5);
      question.maxTopics = 5;
    } else {
      setMaxTopics(question.topics.length);
    }
    if (question.selectedConditionalSegment) {
      setConditionalSegmentType('Segment');
      setSelectedConditionalSegment(question.selectedConditionalSegment);
      if (!question.topicHierarchyType) {
        handleQuestionChange({...question, topicHierarchyType: 'Segment'});
      }
    } else if (question.parentPolicy) {
      setConditionalSegmentType("Question");
      setSelectedParentPolicy(question.parentPolicy);
      if (!question.topicHierarchyType) {
        handleQuestionChange({...question, topicHierarchyType: 'Question'});
      }
    } else {
      setConditionalSegmentType(question.topicHierarchyType || "None");
      setSelectedConditionalSegment(null);
      setSelectedParentPolicy(null);
    }
  }

  useEffect(() => {
    initialize();
  }, [question]);

  const showTopicExtractionInfo = (e) => {
    const defaultInfo = "Automatically identify key themes in your " +
      "survey responses and categorize answers accordingly. " +
      "Choose from three methods to best suit your survey" +
      " structure and analysis needs."
    setTopicExtractionInfoText(t('quote_analysis_mode_description') || defaultInfo);
    setTopicExtractionInfoAnchor(e.currentTarget);
  }

  const getTopicExtractionDescriptionTxt = () => {
    const normal = t('topic_extraction_caption_normal') || "Specify the amount of topics you want us to extract from the interview responses."
    const segment = t('topic_extraction_caption_segments') || "Specify the amount of topics you’d like to explore within each category of \"{segment_name}\". This will help you discover contextualized and more specific insights for each category in the segment."
    const rootQuestion = t('topic_extraction_caption_root_question') || "Specify the amount of topics you'd like to explore for each group of responses to the root question. This will tightly link the follow-up responses to the main topics of the original questions, ensuring context is maintained."
    if (conditionalSegmentType === "None") {
      return normal;
    } else if (conditionalSegmentType === "Segment") {
      return segment.replace("{segment_name}", selectedConditionalSegment?.label || selectedConditionalSegment?.propName || 'segment');
    } else if (conditionalSegmentType === "Question") {
      return rootQuestion
    }
  }

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }

  const handleConditionalSegmentTypeChange = (e, value) => {
    if (value) {
      setConditionalSegmentType(value);
      let localQuestion = _.clone(question);
      localQuestion.topicHierarchyType = value;
      if (value === "None") {
        setSelectedParentPolicy(null);
        setSelectedConditionalSegment(null);
        localQuestion.parentPolicy = null;
        localQuestion.selectedConditionalSegment = null;
        localQuestion.enabledSegmentClassification = false;
      } else if (value === "Question") {
        setSelectedConditionalSegment(null)
        localQuestion.selectedConditionalSegment = null;
        localQuestion.enabledSegmentClassification = false;
      } else if (value === "Segment") {
        setSelectedParentPolicy(null);
        localQuestion.parentPolicy = null;
      }
      localQuestion.topics = [];
      localQuestion.classified = false;
      handleQuestionChange(localQuestion);
    }
  }

  const handleInferTopics = async (e) => {
    e.preventDefault();
    setOpenTopicDiscovery(false);
    if (!collection || !question.answerVar) return
    setShowProgressBar(true);
    setIsTopicInferenceLoading(true);
    setTitle("Extracting the relevant topics in your data...");
    setProgress(0);
    let requestId = null;
    if (conditionalSegmentType === "None") {
      requestId = await submitInferenceRequest()
    } else {
      requestId = await submitGlobalSubtopicInferenceRequest();
    }
    const topicsData = await pollTaskProgress(requestId);
    let inferredTopics = topicsData?.topics
    if (inferredTopics) {
      let localQuestion = {...question}
      localQuestion.topics = inferredTopics.map(t => {
        t.representativity = -1;
        if (t.subtopics?.length > 0) {
          t.subtopics = t.subtopics.map(st => {
            st.representativity = -1;
            return st
          });
        }
        return t;
      });
      localQuestion.classified = false;
      handleQuestionChange(localQuestion);
      successToast("Main topics extracted...");
    } else {
      errorToast("Failed to extract topics: " + topicsData?.message);
    }
    setShowProgressBar(false);
    setProgressMessage('');
    setProgress(0);
    setIsTopicInferenceLoading(false);
  }

  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 2400));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return {message: message, topics: null};

        } else if (status === 'success' && additionalData) {
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  const submitInferenceRequest = async () => {
    const requestId = generateID();
    const payload = {
      surveyId: collection,
      questionId: question.answerVar,
      maxTopics: maxTopics,
      weight: weightVariable,
      targetLang: lng,
      requestId: requestId,
      region: dataRegion || "US",
      summarizationModel: 'kmeans',
      analysisObjective:analysisObjective || '',
      contextDescription:contextDescription || '',
      stakeholderDescription:stakeholderDescription || '',
      originalQuestionText:question.longName
    }
    try {
      axiosEngineInstance.post(enginePaths.topic_inference, payload, status200).then((response) => {
        if (response.status === 200) {
          console.log("Topic inference completed")
        }
      }).catch((error) => {
        console.log(error)
        errorToast(`Error inferring topics for Question: ${question.name}`);
        setIsTopicInferenceLoading(false);
        setProgress(0);
        setProgressMessage('');
      });
    } catch (error) {
      console.log(error)
      errorToast(`Error inferring topics for Question: ${question.name}`);
      setIsTopicInferenceLoading(false);
      setProgress(0);
      setProgressMessage('');
      return null;
    }
    return requestId;
  };

  const submitGlobalSubtopicInferenceRequest = async () => {
    const requestId = generateID();
    let parentClassificationVar = question.parentPolicy?.answerVar || null;
    if (!parentClassificationVar) {
      parentClassificationVar = question.selectedConditionalSegment?.propName || null;
    } else {
      parentClassificationVar = parentClassificationVar + "_label";
    }
    const payload = {
      surveyId: collection,
      questionId: question.answerVar,
      maxTopics: maxTopics,
      weight: weightVariable,
      targetLang: lng,
      requestId: requestId,
      region: dataRegion || "US",
      parentTopics: question.topics,
      summarizationMode: 'kmeans',
      parentClassificationVariable: parentClassificationVar,
      analysisObjective:analysisObjective,
      contextDescription:contextDescription,
      stakeholdersDescription:stakeholderDescription,
      question:question.longName
    }
    try {
      axiosEngineInstance.post(enginePaths.global_subtopic_inference, payload, status200).then((response) => {
        console.log("Subtopic inference completed")
      });
    } catch (error) {
      console.error(error)
      errorToast(`Error inferring topics for Question: ${question.name}`);
      return null;
    }
    return requestId;
  }

  const openDiscoverTopicsModal = () => {
    setOpenTopicDiscovery(true);
  }

  const SegmentSelector = () => {
    if (conditionalSegmentType === "Segment") {
      return (
        <FormControl>
          <MuiAutocomplete
            options={segmentCandidates}
            getOptionLabel={(option) => option.label || option.propName}
            isOptionEqualToValue={(option, value) => option.propName === value.propName}
            value={selectedConditionalSegment || null}
            onChange={(event, newValue) => {
              setSelectedConditionalSegment(newValue);
              let localQuestion = _.clone(question);
              localQuestion.selectedConditionalSegment = newValue;
              localQuestion.enabledSegmentClassification = !!newValue;
              localQuestion.topics = [];
              localQuestion.classified = false;
              handleQuestionChange(localQuestion);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Segment"}
                sx={{'& .MuiInputBase-root':{
                width:'430px'
                }
                }}
                InputLabelProps={{style: labelInputStyle}}
              />)}
            sx={{minWidth: '260px'}}
          />
        </FormControl>
      )
    } else if (conditionalSegmentType === "Question") {
      return (
        <FormControl>
          <MuiAutocomplete
            options={parentQuestionCandidates}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={selectedParentPolicy || null}
            onChange={(event, newValue) => {
              setSelectedParentPolicy(newValue);
              let localQuestion = _.clone(question);
              localQuestion.parentPolicy = newValue;
              localQuestion.topics = [];
              localQuestion.classified = false;
              handleQuestionChange(localQuestion);
            }}
            sx={{minWidth: '260px'}}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Question"}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '4px 0px 4px 4px',
                  }
                }}
              />)}
          />
        </FormControl>
      )
    }
    return null;
  }

  const disableTopicDiscovery = ((conditionalSegmentType === "Question" && !question.parentPolicy) || (conditionalSegmentType === "Segment" && !question.selectedConditionalSegment));
  return (
    <Box sx={{width: '100%'}}>
      <Grid container direction='row' alignItems='center' alignContent='center'
            columnGap={'16px'} rowGap={'10px'}>
        <Grid
          item
          container
          direction='row'
          alignItems={'center'}
          alignContent={'center'}
          rowGap={'10px'}
          columnGap={'16px'}
          sx={{
            width: 'auto',
            border: '1px dashed #E0E0E0',
            borderRadius: '8px',
            padding: '12px 9px 9px 9px',
          }}
        >
          <Grid item container direction='column' id={'mode-container'} sx={{
            height: '56px', width: 'auto'
          }}>
            <Grid container item direction='row' alignItems={'center'}
                  sx={{marginBottom: '7px', height: '16px'}}
                  columnSpacing={"8px"}>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "15px",
                    letterSpacing: "0.004em",
                    color: "#424242",
                    height: '16px',
                    textAlign: 'center'
                  }}>
                  {t('quote_analysis_mode') || "Quote Analysis Mode"}:
                </Typography>
              </Grid>
              <Grid item>
                <MuiInfoIconButton onClick={showTopicExtractionInfo}>
                  <MuiInfoOutlinedIcon/>
                </MuiInfoIconButton>
              </Grid>
            </Grid>
            <Grid item>
              <MUIToggleGroup
                exclusive
                value={conditionalSegmentType}
                onChange={handleConditionalSegmentTypeChange}
              >
                <Tooltip title={(
                  <MuiTooltipTypography variant='body1'>
                    {t('topic_auto_discovery_mode_description')}
                  </MuiTooltipTypography>
                )} placement={"top"} arrow>
                  {(conditionalSegmentType === 'None') ? (
                    <MuiSelectedToggleButton
                      value="None">{t('topic_auto_discovery_mode') || "Auto - Discovery"}</MuiSelectedToggleButton>) : (
                    <MuiToggleButton
                      value="None">{t('topic_auto_discovery_mode') || "Auto - Discovery"}</MuiToggleButton>)}
                </Tooltip>
                <Tooltip title={
                  (<MuiTooltipTypography variant='body1'>
                    {t('topic_segment_guided_mode_description')}
                  </MuiTooltipTypography>)
                } placement={'top'} arrow>
                  {(conditionalSegmentType === 'Segment') ? (
                    <MuiSelectedToggleButton
                      value="Segment">{t('topic_segment_guided_mode')}</MuiSelectedToggleButton>) : (
                    <MuiToggleButton
                      value="Segment">{t('topic_segment_guided_mode')}</MuiToggleButton>)}
                </Tooltip>
                <Tooltip title={(
                  <MuiTooltipTypography variant={'body1'}>
                    {t('topic_follow_up_focused_method_description')}
                  </MuiTooltipTypography>
                )} placement={'top'} arrow>
                  {(conditionalSegmentType === 'Question') ? (
                    <MuiSelectedToggleButton
                      value="Question">{t('topic_follow_up_focused_method')}</MuiSelectedToggleButton>) : (
                    <MuiToggleButton
                      value="Question">{t('topic_follow_up_focused_method')}</MuiToggleButton>)}
                </Tooltip>
              </MUIToggleGroup>
            </Grid>
          </Grid>
          {conditionalSegmentType !== 'None' && (
            <Grid item>
              <SegmentSelector/>
            </Grid>
          )}
        </Grid>
        <Grid item ref={topicDiscoveryModalAnchor}>
          <Button
            variant={'contained'}
            disabled={disableTopicDiscovery}
            sx={{
              color: '#FFFFFF',
              backgroundColor: 'primary',
              height: '37px',
              maxWidth: '189px',
              borderRadius: '20px',
              padding: '10px 24px',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textTransform: 'uppercase',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '17px',
              letterSpacing: '0.0025em',
            }}
            onClick={openDiscoverTopicsModal}
          >
            {t('infer_topics')}
          </Button>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(topicExtractionInfoAnchor)}
        anchorEl={topicExtractionInfoAnchor}
        onClose={() => setTopicExtractionInfoAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{position: 'relative', width: '420px', padding: '10px'}}>
          <Grid container direction='row'>
            <Grid item sx={{padding: '5px', maxWidth: '374px'}}>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '15px',
                letterSpacing: '0.004em',
                color: '#616161',
                fontStyle: 'normal',
                marginLeft: '5px'
              }} variant="caption">
                {topicExtractionInfoText}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => setTopicExtractionInfoAnchor(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  width: '15px',
                  height: '15px'
                }}
              >
                <CloseIcon sx={{width: '15px', height: '15px'}}/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
      <Popover
        anchorEl={topicDiscoveryModalAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: '10px',
          '& .MuiPaper-root': {
            borderRadius: '28px'
          }
        }}
        open={openTopicDiscovery}
        onClose={() => {
          setOpenTopicDiscovery(false);
        }}
        disablePortal
      >
        <Box sx={{padding: '24px', width: '512px'}}
             justifyContent={'center'}>
          <Typography sx={{
            fontFamily: 'Raleway',
            fontSize: '25px',
            lineHeight: '29px',
            fontWeight: '400',
            color: '#212121',
          }}>
            {t('discover_topics')}
          </Typography>
          <Typography sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '0.005em',
            fontWeight: 400,
            color: '#757575',
            mt: '10px'
          }}>{getTopicExtractionDescriptionTxt()}</Typography>
          <Grid container direction="column" sx={{mt: '5px'}}>
            <Grid container item direction='row' columnGap={'16px'}
                  alignContent={'center'}>
              {Array(8).fill(1).map((_, index) => {
                const value = index + 3;
                return (
                  <MuiTopicAmountSelector
                    container
                    item
                    alignContent='center'
                    justifyContent='center'
                    key={`amount-${value}`}
                    isSelected={maxTopics === value}
                    sx={{mt: '16px'}}
                    onClick={() => setMaxTopics(value)}
                  >
                    <MuiTopicAmountSelectorTypography
                      isSelected={maxTopics === value}
                    >
                      {value}
                    </MuiTopicAmountSelectorTypography>
                  </MuiTopicAmountSelector>
                )
              })}
            </Grid>
            <Grid container item direction={'row'} columnGap={'8px'}
                  sx={{width: '100%', mt: '24px', pb: '24px'}}
                  justifyContent={'flex-end'}>
              <MuiTextButton variant='text' sx={{
                height: '37px',
                borderRadius: '20px',
                color: 'primary',
              }}
                             onClick={() => setOpenTopicDiscovery(false)}>{t('cancel_btn')}</MuiTextButton>
              <MuiOutlinedButton variant="outlined" onClick={handleInferTopics}>
                {t('ok_btn')}
              </MuiOutlinedButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  )
}
