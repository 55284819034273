import {
  Card,
  CardActions,
  Chip,
  IconButton, lighten, MenuItem,
  styled,
  Typography
} from '@mui/material';
import {darken} from "@mui/material/styles";

export const MuiTypographyLabel = styled(Typography)(({theme}) => ({
  marginLeft: 1,
  fontWeight: 700,
  fontStyle: 'bold',
  fontSize: 14,
  fontFamily: 'Raleway',
  color: '#424242',
  cursor: 'pointer',
}));

export const MuiCard = styled(Card)(({theme}) => ({
  marginBottom: 0,
  marginRight: "5px",
  width: "100%",
  border: '0px solid #EEEEEE',
  backgroundColor: '#FAFAFA',
  borderRadius: '0px',
  boxShadow: 'none'
}));

export const MuiChip = styled(Chip)(({theme}) => ({
  maxWidth: '100%',
  overflow: 'visible',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '12px',
  letterSpacing: '0.015em',
  textTransform: 'uppercase',
  fontSize: '10px',
  color: '#254957',
  borderColor: 'transparent',
  opacity: 1.0,
  borderRadius: '8px',
  height: '29px'
}));

export const boxUserQueryStyles = {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden'
};

export const boxAnswerStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: 0
};

export const gridContainerStyles = {
  width: '100%',
  marginTop: "3px"
};

export const gridFilterStyles = {
  width: "100%",
  flex: 1,
  marginBottom: "16px"
};

export const iconCommonStyles = {
  color: '#757575',
  width: '16px',
  height: '16px',
};

export const MuiCardActions = styled(CardActions, {shouldForwardProp: (prop) => prop !== 'expanded'})(({
                                                                                                         theme,
                                                                                                         expanded
                                                                                                       }) => ({
  minHeight: '56px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding:'8px 0px 8px 4px',
  '&:hover': {
    backgroundColor: expanded ? 'transparent' : lighten(theme.palette.primary.main, 0.8),
  }
}));

export const typographyAnswerStyles = {
  color: '#616161',
  textAlign: 'justify',
  fontFamily: 'Montserrat',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.0025em',
  fontStyle: 'normal',
  paddingTop: 0,
  'ul': {
    listStylePosition: "inside", /* Ensures the marker is closer to the text */
    paddingLeft: "10px", /* Adjust the padding of the list as needed */
    marginLeft: 0, /* Remove or reduce the left margin */
  },
  'li': {
    marginLeft: 0,
    paddingLeft: '5px',
  },
  'blockquote': {
    display: 'inline',
    margin: 0,
    padding: 0
  }
};

export const MuiMenuItem = styled(MenuItem)(({theme}) => ({
  height: '40px',
  padding: '8px 12px',
  width: '100%',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  lineHeight:'24px',
  letterSpacing:'0.5px',
  fontWeight: 400,
  textTransform: 'none',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
  }
}))

export const MUIFilterIconButton = styled(IconButton, {shouldForwardProp: (prop) => prop !== 'filtersApplied'})(({
                                                                                                                   theme,
                                                                                                                   filtersApplied
                                                                                                                 }) => ({
  height: '22px',
  width: '22px',
  borderRadius: '50%',
  color: filtersApplied ? darken(theme.palette.primary.main, 0.3) : '#757575',
  border: filtersApplied ? `1px solid ${darken(theme.palette.primary.main, 0.3)}` : '1px solid #EEEEEE',
  ':hover': {
    backgroundColor: 'transparent',
    borderColor: filtersApplied ? darken(theme.palette.primary.main, 0.3) : '#757575'
  },
  fontFamily: 'Montserrat',
  fontSize: '14px',
  textTransform: 'uppercase',
}))
