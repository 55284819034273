import {
  Box,
  Grid,
  InputAdornment, SvgIcon,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {
  MuiAutocomplete,
  MuiSelectedToggleButton,
  MuiToggleButton,
  MUIToggleGroup,
  MuiTooltipTypography,
  MuiUploadButton
} from "./styles/uploadStep";
import * as React from "react";
import {useCallback} from "react";
import BackUpIcon from "@mui/icons-material/Backup";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkIcon from "@mui/icons-material/Link";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {Description} from "@mui/icons-material";
import {ReactComponent as CSV_ICON}from '../../../../../assets/img/csv_file_icon.svg';
import {ReactComponent as JSON_ICON} from '../../../../../assets/img/json_file_icon.svg';
import {ReactComponent as PARQUET_ICON} from '../../../../../assets/img/parquet_icon_file.svg'

const regions = [
  {label: "United States", value: "US"},
  {label: "Saudi Arabia", value: "SA"},
];
export const UploadStep = ({
                             datasetName,
                             updateDatasetName,
                             region,
                             updateRegion,
                             sourceType,
                             updateSourceType,
                             uploadedFile,
                             updateUploadedFile,
                             url,
                             updateUrl
                           }) => {

  const fileInputRef = React.useRef(null);
  const [fileError, setFileError] = React.useState('');
  const [urlError, setUrlError] = React.useState('');
  const [datasetNameError, setDatasetNameError] = React.useState('');
  const {t} = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    // Handle the dropped files
    handleFileChange(acceptedFiles[0]);
  }, []);

  const handleFileChange = (file) => {
    if (!file || file.name.endsWith('.json') || file.name.endsWith('.csv') || file.name.endsWith('.parquet')) {
      updateUploadedFile(file);
      setFileError('');
    } else {
      setFileError('The file uploaded is not supported. Please upload a JSON, CSV or PARQUET file')
    }
  }

  const handleUrlChange = (event) => {
    //validate url format
    const inputUrlValue = event.target.value;
    // Validar la URL
    let isValid = false;
    const allowedURLPatterns = [/^https?:\/\/.+/, /^s3?:\/\/.+/, /^http?:\/\/.+/];
    for (const pattern of allowedURLPatterns) {
      if (pattern.test(inputUrlValue)) {
        isValid = true;
        break;
      }
    }
    // Establecer el error si la URL no es válida
    if (!isValid) {
      setUrlError('The URL is not valid. Please enter a valid URL');
    } else {
      setUrlError('');
    }
    updateUrl(inputUrlValue);
  }

  const handleDatasetNameChange = (event) => {
    const newName = event.target.value;
    if (!newName) {
      setDatasetNameError('The dataset name is required');
    } else if (newName.length < 4) {
      setDatasetNameError('The dataset name must be at least 4 characters long');
    } else {
      setDatasetNameError('')
    }
    updateDatasetName(newName);
  }


  const {getRootProps, getInputProps} = useDropzone({onDrop});

  const UploadedFileIcon = () => {
    if (uploadedFile?.name?.toLowerCase().endsWith('.csv')) {
      return (<SvgIcon component={CSV_ICON} inheritViewBox/>)
    } else if (uploadedFile?.name?.toLowerCase().endsWith('.json')) {
      return (<SvgIcon component={JSON_ICON} inheritViewBox/>)
    } else if (uploadedFile?.name?.toLowerCase().endsWith('.parquet')) {
      return (<SvgIcon component={PARQUET_ICON} inheritViewBox/>)
    }
    return <Description
      sx={{color: 'primary.main', width: '36px', height: '36px'}}/>
  }

  return (
    <Grid container item direction='column' rowGap={'16px'}
          justifyContent='flex-start' alignContent='flex-start'
          sx={{maxHeight: '450px', overflowY: 'auto', width: '575px'}}>
      <Grid item xs={true} sx={{maxHeight: '47px'}}>
        <MUIToggleGroup
          exclusive
          value={sourceType}
          onChange={(evt, newValue) => updateSourceType(newValue)}
        >
          <Tooltip title={(
            <MuiTooltipTypography variant='body1'>
              Upload the dataset from a local file in your computer
            </MuiTooltipTypography>
          )} placement={"top"} arrow>
            {(sourceType === 'local') ? (
              <MuiSelectedToggleButton
                value="local"
              >
                <UploadFileIcon sx={{width: '16px', height: '16px'}}/>
                {t('upload_from_local')}
              </MuiSelectedToggleButton>
            ) : (
              <MuiToggleButton
                value="local"
              >
                <UploadFileIcon sx={{width: '16px', height: '16px'}}/>
                {t('upload_from_local')}
              </MuiToggleButton>)}
          </Tooltip>
          <Tooltip title={
            (<MuiTooltipTypography variant='body1'>
              Upload the dataset from a public server
            </MuiTooltipTypography>)
          } placement={'top'} arrow>
            {(sourceType === 'link') ? (
              <MuiSelectedToggleButton
                value="link"
              >
                <AddLinkIcon sx={{width: '16px', height: '16px'}}/>
                {t('load_from_remote')}
              </MuiSelectedToggleButton>) : (
              <MuiToggleButton
                value="link"
              >
                <AddLinkIcon sx={{width: '16px', height: '16px'}}/>
                {t('load_from_remote')}
              </MuiToggleButton>
            )}
          </Tooltip>
        </MUIToggleGroup>
      </Grid>
      <Grid container item direction={'column'} rowGap={'16px'}
            xs={true}>
        {(sourceType === 'local') && (
          <Grid container item xs={true} direction='row'>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '10px',
              lineHeight: '12px',
              letterSpacing: '0.015em',
              color: '#000000',
              textTransform: 'uppercase'
            }}>
              {t('supported_files')}:&nbsp;
            </Typography>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: '12px',
              letterSpacing: '0.015em',
              color: '#000000',
              textTransform: 'uppercase'
            }}>
              CSV, JSON, PARQUET
            </Typography>
          </Grid>
        )}
        {(sourceType === 'link') && (
          <Grid container item xs={true} direction='row'>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '10px',
              lineHeight: '12px',
              letterSpacing: '0.015em',
              color: '#000000',
              textTransform: 'uppercase'
            }}>
              {t('supported_remote_src')}:&nbsp;
            </Typography>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: '12px',
              letterSpacing: '0.015em',
              color: '#000000',
              textTransform: 'uppercase'
            }}>
              S3 URI, Public Link
            </Typography>
          </Grid>
        )}
        {(sourceType === 'local') && (
          <Grid
            container
            item
            {...getRootProps()}
            direction='column'
            rowGap={'10px'}
            sx={{
              height: '128px',
              borderRadius: '12px',
              border: '1px dashed #757575',
              padding: '16px',
            }}
            alignContent='center'
          >
            <input ref={fileInputRef} {...getInputProps()}
                   style={{display: 'none'}}/>
            <Grid item xs={true}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '56px',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
              }}>
                {uploadedFile ? (
                  <UploadedFileIcon/>
                ) : (
                  <BackUpIcon
                    sx={{color: '#9E9E9E', width: '36px', height: '36px'}}/>
                )}
                <Typography sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  lineHeight: '26px',
                  letterSpacing: '0.005em',
                  fontWeight: 400,
                  color: '#616161',
                  textTransform: uploadedFile ? 'uppercase' : 'none'
                }}>
                  {uploadedFile?.name || t('drag_and_drop')}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={true} alignContent={'center'}
                  justifyContent={'center'}>
              <MuiUploadButton variant='outlined'
                               onClick={() => fileInputRef.current?.click()}
              >
                {t('choose_file')}
              </MuiUploadButton>
            </Grid>
          </Grid>
        )}
        {(sourceType === 'link') && (
          <Grid item xs={true}>
            <TextField
              variant={'outlined'}
              label={t('public_link')}
              value={url || ''}
              error={!!urlError}
              helperText={urlError}
              onChange={updateUrl ? handleUrlChange : () => {
              }}
              fullWidth
              sx={{
                height: '56px',
                maxHeight: '56px',
                borderRadius: '4px 4px 0px 0px',
                '& .MuiFormHelperText-root': {
                  fontFamily: 'Montserrat',
                  fontSize: '10px'
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontStyle: 'normal',
                  letterSpacing: '0.005em',
                  fontWeight: 400,
                  color: '#424242',
                  minHeight: '56px',
                  alignContent: 'center',
                },
                '& .MuiOutlinedInput-root': {
                  maxHeight: '56px'
                },
                '& .MuiInputLabel-root': {
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '15px',
                  letterSpacing: '0.004em',
                  color: '#254957'
                },
              }}
              InputProps={
                {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon/>
                    </InputAdornment>
                  ),
                }
              }
            />
          </Grid>
        )}
        <Grid item xs={true} sx={{mt: urlError ? '16px' : 0}}>
          <TextField
            variant={'outlined'}
            label={t('dataset_name')}
            value={datasetName || ''}
            disabled={!uploadedFile && !url}
            onChange={updateDatasetName ? handleDatasetNameChange : () => {
            }}
            error={!!datasetNameError}
            helperText={datasetNameError}
            fullWidth
            sx={{
              height: '56px',
              maxHeight: '56px',
              borderRadius: '4px 4px 0px 0px',
              '& .MuiFormHelperText-root': {
                fontFamily: 'Montserrat',
                fontSize: '10px'
              },
              '& .MuiInputBase-input': {
                fontFamily: 'Montserrat',
                fontSize: '16px',
                lineHeight: '20px',
                fontStyle: 'normal',
                letterSpacing: '0.005em',
                fontWeight: 400,
                color: '#424242',
                minHeight: '56px',
                alignContent: 'center',
              },
              '& .MuiOutlinedInput-root': {
                maxHeight: '56px'
              },
              '& .MuiInputLabel-root': {
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '15px',
                letterSpacing: '0.004em',
                color: '#254957'
              },
            }}
          />
        </Grid>
        <Grid item xs={true} sx={{mt: datasetNameError ? '16px' : 0}}>
          <MuiAutocomplete
            options={regions}
            disabled={!uploadedFile && !url || !datasetName}
            getOptionLabel={(option) => option?.label || ''}
            defaultValue={regions[0]}
            value={regions.find(r => r.value === region)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(evt, value) => updateRegion(value?.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('region')}
                sx={{
                  '& .MuiInputBase-root': {
                    width: '100%'
                  }
                }}
                InputLabelProps={{
                  sx: {
                    color: '#424242',
                    fontSize: 12,
                    fontFamily: 'Montserrat'
                  }
                }}
              />)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
