import {Button, lighten, styled} from "@mui/material";
import {darken} from "@mui/material/styles";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";

export const MuiEmptyTopicsHeadingContainer=styled('div')(({theme}) => ({
  fontFamily:"Raleway",
  fontStyle:"normal",
  fontWeight:500,
  fontSize:'14px',
  lineHeight:'16px',
  display:'inline',
  alignItems:'center',
  textAlign:'center',
  letterSpacing:'0.001em',
  color: "#616161",
  whiteSpace: 'normal', // Ensure text can wrap when needed
  wordWrap: 'break-word', // Ensure long words can break onto the next line
}));

export const MuiHighlightedText = styled('span')(({theme}) => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase'
}));

const MuiAddTopicIcon = styled(AddIcon)(({theme}) => ({
  width: '18px',
  height: '18px',
  color: darken(theme.palette.primary.main, 0.3)
}));

export const MuiAddTopicButton = styled((props) => {
  return (
    <Button
      {...props}
      variant='outlined'
      startIcon={<MuiAddTopicIcon/>}
    ></Button>
  )
})(({theme}) => ({
  color: darken(theme.palette.primary.main, 0.3),
  height: '38px',
  width:'100%',
  padding: '10px 24px 10px 16px',
  alignContent: 'center',
  borderRadius: '20px',
  border: `1px dashed ${lighten(theme.palette.primary.main, 0.3)}`
}))
