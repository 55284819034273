import {
  Grid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography
} from "@mui/material";
import * as React from "react";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {MuiAutocomplete} from "./styles/uploadStep";
import {useCallback, useEffect, useState} from "react";

export const ConfigureAudio = ({variables, updateVariables}) => {
  const {t} = useTranslation();
  const [availableAudioVariables, setAvailableAudioVariables] = useState(variables.filter(d => d.category === 'audio'));

  const insertBreakPoints = (str) => str.replace(/_/g, '_\u200B');

  const linkAudioToTextVariable = (tvIndex, avPropName) => {
    const newVariables = [...variables];
    newVariables[tvIndex].linkedAudio = avPropName;
    updateVariables(newVariables);
  }

  const callback = useCallback(() => {
    const usedAudioVariables = new Set();
    variables.forEach((variable) => {
      if (variable.category === 'answer' && variable.linkedAudio) {
        usedAudioVariables.add(variable.linkedAudio);
      }
    });
    setAvailableAudioVariables(variables.filter(v => v.category === 'audio' && !usedAudioVariables.has(v.propName)));

  }, [variables])

  useEffect(() => {
    callback();
  }, [callback])
  return (
    <Grid item xs={12} sx={{height: '100%'}}>
      <TableContainer component={"div"}
                      sx={{maxHeight: '400px', height: '400px', width: '100%'}}>
        <MuiTable stickyHeader size="small" aria-label='simple table'
                  sx={{width: '100%'}}>
          <TableHead>
            <TableRow>
              <TableCell sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
              }}>{t('survey_answer')}</TableCell>
              <TableCell sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
              }}>{t('custom_label')}</TableCell>
              <TableCell sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
              }}>{t('audio_variable')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variables?.map((variable, answerIndex) => {
              if (variable.category !== 'answer') return null;
              return (
                <TableRow key={'d-' + answerIndex} data-cy='var_row'>
                  <TableCell component='th' scope='row'
                             sx={{maxWidth: '140px'}}>
                    <Typography sx={{
                      color: '#424242',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      letterSpacing: '0.0025em',
                      display: 'flex',
                      textAlign: 'left',
                      wordWrap: 'break-word', // Ensures words break when necessary
                      whiteSpace: 'normal',   // Allows the text to wrap naturally
                      width: '120px',         // Set the desired maximum width
                      // wordBreak:'break-all',
                      // overflow: 'hidden',     // Ensure no horizontal scrolling if necessary
                    }}>
                      {insertBreakPoints(variable.propName)}
                    </Typography>
                  </TableCell>
                  <TableCell component='th' scope='row'
                             sx={{minWidth: '250px', verticalAlign: 'middle'}}>
                    <Typography sx={{
                      color: '#424242',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      letterSpacing: '0.0025em',
                      display: 'flex',
                      textAlign: 'left',
                      wordWrap: 'break-word', // Ensures words break when necessary
                      whiteSpace: 'normal',   // Allows the text to wrap naturally
                      width: '120px',         // Set the desired maximum width
                      // wordBreak:'break-all',
                      // overflow: 'hidden',     // Ensure no horizontal scrolling if necessary
                    }}>
                      {insertBreakPoints(variable.label || variable.propName)}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <MuiAutocomplete
                      clearable
                      filterSelectedOptions
                      value={variables.find(av => av.category === 'audio' && av.propName === variable.linkedAudio)}
                      options={availableAudioVariables}
                      getOptionLabel={(option) => option?.label || option.propName || ''}
                      isOptionEqualToValue={(option, value) => option?.propName === value?.propName}
                      onChange={(evt, newValue) => linkAudioToTextVariable(answerIndex, newValue?.propName || null)}
                      renderInput={(params) => <MuiTextField {...params}
                                                             sx={{width: '433px'}}/>}

                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Grid>
  )
}
