import {Box, Button, darken, lighten, styled} from "@mui/material";

export const IconContainer = styled(Box)(({theme}) => ({
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '16px',
  backgroundColor: lighten(theme.palette.primary.main, 0.8)
}))


export const MuiCancelActionButton = styled(Button)(({theme}) => ({
  padding: '10px 24px',
  borderRadius: '20px',
  height: '37px',
  width: '112px',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  letterSpacing: '0.0025em',
  color: darken(theme.palette.primary.main, 0.3),
}));

export const MuiNextActionButton = styled(Button)(({theme}) => ({
  padding: '10px 24px',
  borderRadius: '20px',
  height: '37px',
  width: '126px',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  letterSpacing: '0.0025em',
  color: darken(theme.palette.primary.main, 0.3),
  borderColor: darken(theme.palette.primary.main, 0.3)
}))
