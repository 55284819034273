import * as _ from "lodash";
import {Box, Grid, Typography} from "@mui/material";
import {Topic} from "./Topic";
import {
  ReactComponent as DiscoverTopicsSvg
} from '../../../../../../../../assets/img/discover_topics.svg';
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {
  MuiAddTopicButton,
  MuiEmptyTopicsHeadingContainer,
  MuiHighlightedText
} from "./styles/topics";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";

export const Topics = ({
                         question,
                         handleQuestionChange,
                         collection,
                         weightVariable,
                         dataRegion
                       }) => {

  const {t, lng} = useTranslation();
  const highlightedText = {
    "spanish": "DESCUBRIR TEMAS",
    "english": "DISCOVER TOPICS",
    "portuguese": "DESCUBRA TOPIÇOS",
    "french": "DECOUVREZ DES TOPICS",
    "arabic": "DISCOVER TOPICS"
  }

  const getEmptyTopicsText = () => {
    const text = t('empty_topics_heading')
    const [before, after] = text.split(highlightedText[lng])
    return (
      <MuiEmptyTopicsHeadingContainer>
        {before}
        <MuiHighlightedText>&nbsp;"{highlightedText[lng]}"&nbsp;</MuiHighlightedText>
        {after}
      </MuiEmptyTopicsHeadingContainer>
    )
  }
  const handleTopicChange = (updatedTopic, index) => {
    let updatedQuestion = {...question};  // Shallow copy of the question
    const checkedSubtopics = new Set();     // Tracks subtopics that have been handled

    // Loop over topics to update subtopics, avoiding duplication
    updatedQuestion.topics?.forEach((existingTopic, i) => {
      // Skip the topic at the current index
      if (i !== index && existingTopic.subtopics?.length > 0) {
        updatedTopic.subtopics?.forEach((subtopic) => {
          // Check if subtopic matches the current topic or its subtopics
          if (!checkedSubtopics.has(subtopic.topic) &&
            (subtopic.topic === existingTopic.topic ||
              existingTopic.subtopics.some(tst => tst.topic === subtopic.topic))) {

            // Update subtopic with a unique name
            subtopic.topic = `${existingTopic.topic} - ${subtopic.topic}`;
            checkedSubtopics.add(subtopic.topic);  // Mark subtopic as checked
          }
        });
      }
    });

    // Replace the topic at the specified index
    updatedQuestion.topics[index] = updatedTopic;
    updatedQuestion.classified = false;  // Mark question as not classified

    // Pass the updated question to the parent handler
    handleQuestionChange(updatedQuestion);
  }

  const handleDeleteTopic = (index) => {
    let localQuestion = _.clone(question);
    let localQuestionTopics = [...localQuestion.topics];
    localQuestionTopics.splice(index, 1);
    localQuestion.topics = localQuestionTopics;
    localQuestion.classified = false;
    handleQuestionChange(localQuestion);
  }

  const handleAddTopic = () => {
    let localQuestion = _.clone(question);
    localQuestion.topics = localQuestion.topics || [];
    localQuestion.topics.push({
      topic: '',
      description: '',
      isParent: true
    });
    localQuestion.classified = false;
    handleQuestionChange(localQuestion);
  }

  return (
    <Box
      sx={{
        maxHeight: '510px',
        width: '100%',
        overflowY: 'auto',
        mt: '16px',
      }}
    >
      <Grid container item direction='column' justifyContent={'flex-start'}
            sx={{width: '100%'}} id="topics-list-container">
        {question.topics && question.topics.length > 0 ? (
          <>
            {(question.topicHierarchyType === 'None' || !question.topicHierarchyType) && (
              <Grid container item direction='row' columnGap={'8px'} sx={{mb:'8px'}}>
                <Grid item sx={{width: '36.5%', pl: '40px'}}>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14px',
                    letterSpacing: '0.004em'
                  }}>
                    {t('name')}:
                  </Typography>
                </Grid>
                <Grid item xs={true}>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14px',
                    letterSpacing: '0.004em'
                  }}>
                    {t('description')}:
                  </Typography>
                </Grid>
              </Grid>
            )}
            {
              question.topics.map((topic, index) => (
                <Grid item key={`topic container-${index}`}>
                  <Topic
                    topic={topic}
                    index={index}
                    key={`topic-${index}`}
                    hierarchyMode={question.topicHierarchyType || "None"}
                    showQuotesSample={question.classified}
                    rootClassificationVariable={question.parentPolicy?.answerVar + '_label' || question.selectedConditionalSegment?.propName || null}
                    handleTopicChange={handleTopicChange}
                    handleDelete={handleDeleteTopic}
                    collection={collection}
                    weightVariable={weightVariable}
                    dataRegion={dataRegion}
                    answerVariable={question.answerVar}
                  />
                </Grid>
              ))
            }
            <Grid item>
              {(question.topicHierarchyType === 'None' || !question.topicHierarchyType) && (
                <Grid item sx={{
                  padding: '16px 12px'
                }}>
                  <MuiAddTopicButton variant="outlined"
                                     sx={{
                                       fontFamily:'Montserrat',
                                       fontSize:'14px',
                                       fontWeight:500
                                     }}
                                     onClick={handleAddTopic}
                                     startIcon={<AddIcon/>}>
                    {t('add_topic')}
                  </MuiAddTopicButton>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item sx={{
              width: '100%',
              paddingTop: '66px',
              paddingLeft: '157px',
              paddingRight: '150px'
            }}>
              <DiscoverTopicsSvg width={'100%'}
                                 height={'auto'}/>
            </Grid>
            <Grid item sx={{paddingLeft: '157px'}}>
              {getEmptyTopicsText()}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}
