import {
  Box,
  Button,
  Grid,
  lighten,
  Paper,
  styled,
  SvgIcon,
  Typography
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";

export const MuiPaperContainer = styled(Paper)(({theme}) => ({
  border: '1px solid #E0E0E0',
  padding: "0px",
  paddingLeft: "5px",
  borderRadius: '20px',
  boxShadow: 'none'
}));

export const MuiBoxTabs = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '17px'
}));

export const MuiAddIcon = styled(AddIcon)(({theme}) => ({
  width: '18px !important',
  height: '18px !important',
  color: lighten(theme.palette.primary.main, 0.2),
}));

export const MuiDeleteIcon = styled(DeleteOutlinedIcon)(({theme}) => ({
  color: '#F63B2B',
  marginTop: '0px',
  marginLeft: '4px',
  minWidth: 25,
  width: '26px',
  height: '26px',
  padding: 3,
  borderRadius: '50%',
  border: `1px solid ${lighten('#F63B2B', 0.6)}`,
  align: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: lighten('#F63B2B', 0.9),
  },
  backgroundColor: 'transparent'
}));

export const WarningContainer = styled(Grid)(({theme}) => ({
  backgroundColor: lighten(theme.palette.warning.main, 0.9),
  padding: '2px 4px',
  height: '16px',
  borderRadius: '8px',
  width: 'fit-content'
}))
export const MuiGridTabContent = styled(Grid)(({theme}) => ({
  borderBottomRightRadius: '12px',
  borderColor: '#E0E0E0'
}));

export const MuiButton = styled(Button)(({theme}) => ({
  justifyContent: 'center',
  borderRadius: '15px',
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontSize: 14,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: '4px 12px 4px 8px',
  height: '30px',
  width: '90%',
  marginTop:'16px',
  marginRight: '15px',
  marginLeft: '10px',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    borderColor: theme.palette.primary.main,
  }
}));

export const MuiCommonTypography = styled(Typography)(({theme}) => ({
  fontSize: '11px',
  fontFamily: 'Montserrat',
  textAlign: 'left',
  fontWeight: 500,
  color: lighten(theme.palette.primary.main, 0.2),
}));

export const MuiQuestionText = styled(Typography)(({theme}) => ({
  fontSize: '14px',
  fontFamily: 'Raleway',
  textAlign: 'left',
  fontWeight: 700,
  color: '#424242',
  marginTop: '17px',
  marginLeft: '7px',
}));


export const dragHandleStyle = {
  cursor: 'row-resize',
  marginRight: 8,
  marginTop: '5px',
}

export const customTabStyle = {
  borderTopLeftRadius: '12px',
  borderBottomLeftRadius: '12px',
  paddingRight: '10px',
  paddingLeft: '10px',
  opacity: 1,
}

export const addQuestionButtonStyle = {
  margin: '5px 0px 5px 4px !important',
  width: '99%',
  maxWidth: '99%'
};

export const SubordinateQuestionIcon = (props) => {

  return (
    <SvgIcon
      style={{width: '20px', height: '20px', marginTop: '7px'}} {...props}>
      <path
        d="M5.83333 1.33325H11.1667V5.99992H9.16667V7.33325H11.1667H12.5V8.66659V9.99992H14.5V14.6666H9.16667V9.99992H11.1667V8.66659L9.16667 8.66658H7.83333L5.83333 8.66659V9.99992H7.83333V14.6666H2.5V9.99992H4.5V8.66659V7.33325H5.83333H7.83333V5.99992H5.83333V1.33325Z"
        fill={props.color || "#00828F"}/>
    </SvgIcon>
  );
}
