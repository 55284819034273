import {Grid} from "@mui/material";
import * as _ from "lodash";
import * as React from "react";
import {MuiTextField} from "./styles/segmentModal";


export const CategoryForm = ({
                               category,
                               index,
                               handleCategoryChange
                             }) => {

  const getNumberSuffix = (value) => {
    switch (value) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
  return (
    <Grid container alignItems="center" alignContent={'center'} direction="row"
          columnGap={'18px'} wrap={'nowrap'} sx={{width: '100%'}}>
      <Grid item xs={"auto"} sx={{height: '50px'}}>
        <MuiTextField
          label={""}
          type="text"
          fullWidth
          onChange={(evt) => {
            let localCategory = _.clone(category);
            localCategory.topic = evt.target.value;
            handleCategoryChange(localCategory, index);
          }}
          sx={{width: '300px'}}
          placeholder={`${index + 1}${getNumberSuffix(index + 1)} Category's name`}
          value={category.topic}
          variant="outlined"
        />
      </Grid>
      <Grid item xs sx={{height: '50px'}}>
        <MuiTextField
          label={""}
          type="text"
          placeholder={`${index + 1}${getNumberSuffix(index + 1)} Category's description`}
          onChange={(evt) => {
            let localCategory = _.clone(category);
            localCategory.description = evt.target.value;
            handleCategoryChange(localCategory, index);
          }}
          value={category.description}
          sx={{width: '100%'}}
          variant="outlined"
          multiline
          rows={1}
        />
      </Grid>

    </Grid>
  )
}
