import {
  Box,
  Grid,
  IconButton,
  Popover, Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { popoverStyles } from '../styles/popover';

const PopoverDescription = ({ showDescription, setShowDescription,
                              position, description }) => {

  const handleClose = () => setShowDescription(false)

  return (
    <Popover
      open={showDescription}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position.y + 30,
        left: position.x
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: popoverStyles,
      }}
    >
      <Box style={{position: 'relative', width: '340px', padding: '10px'}}>
        <Grid container direction={'row'} alignItems={'center'}
              justifyContent={'center'}>
          <Grid item xs={11} style={{padding: '5px', maxWidth: '340px'}}>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '15px',
              letterSpacing: '0.004em',
              color: '#616161',
              fontStyle: 'normal',
              marginLeft: '5px'
            }} variant="caption">
              {description}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                width: '15px',
                height: '15px'
              }}
            >
              <CloseIcon sx={{width: '15px', height: '15px'}}/>
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default PopoverDescription
