import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SimilarityHistogramWidgetUI from './SimilarityHistogramWidgetUI'
import {status200} from '../../../../api/status.utils';
import {
  setSimilarityFilter,
  setSimilarityQuery,
} from '../../../../store/appSlice';
import {useTheme} from "../../../providers/CustomThemeProvider";
import LoadingProvider from "../../../providers/LoadingProvider";
import {useTranslation} from "../../../providers/TranslationProvider";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import PopoverUI from "../Popover";
import {MuiLinearProgress} from "../../styles/similarityHistogramWidgetUI";
import ClearIcon from "@mui/icons-material/Clear"

import {
  containerSimilarityStyles,
  dividerStyles, MuiSearchIconButton,
  MuiTextField
} from './styles/SimilarityWidget';
import {MuiSearchIcon} from '../../styles/popover';
import {useCache} from "../../../providers/CacheContext";
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";
import WrapperWidgetUI from "../WrapperWidgetUI";

/**
 * Renders a <HistogramWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {Function} [props.formatter] - Function to format Y axis values.
 * @param  {boolean} [props.tooltip=true] - Whether to show a tooltip or not
 * @param  {Function} [props.tooltipFormatter] - Function to return the HTML of the tooltip.
 */
function SimilaritySearchWidget({
                                  title,
                                  id,
                                  dataSource,
                                  dataRegion,
                                  column,
                                  xAxisFormatter,
                                  formatter,
                                  tooltip,
                                  tooltipFormatter,
                                  weight,
                                  policyId,
                                  wrapperProps
                                }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [queryText, setQueryText] = useState("");
  const [tempQueryText, setTempQueryText] = useState("");
  const [selectedBars, setSelectedBars] = useState([]);
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeInsightsFilters = useSelector(state => state.app.insightsFilter);
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSegmentationFilters = useSelector(state => state.app.segmentationFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const [bins, setBins] = useState(15);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [showBinSlider, setShowBinSlider] = useState(false);
  const [binSliderLocation, setBinSliderLocation] = useState(null);
  const [currentAnswerCount, setCurrentAnswerCount] = useState(0);
  const [initRangeBar, setInitRangeBar] = React.useState([70, 100]);
  const {theme} = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const activePolicyTab = useSelector(state => state.app.selectedScenarioTab);
  const {t} = useTranslation();
  const gearRef = useRef(null);
  const {cache, setCacheData} = useCache();

  const fetchData = async (data) => {
    const cache_key = JSON.stringify(data);
    if (cache[cache_key]) {
      return cache[cache_key]
    }
    const response = await axiosEngineInstance.post(enginePaths.query_similarity, data, status200).then((resp) => {
      return resp.data
    });
    setCacheData(cache_key, response);
    return response;
  }


  const loadBins = async () => {
    if (!queryText || queryText === "") {
      setData([]);
      return;
    }
    let localSegmentationFilters = {...activeSegmentationFilters, ...(activeInsightsFilters[policyId] || {})};
    Object.keys(treeMapFilter).forEach(key => {
      Object.assign(localSegmentationFilters, treeMapFilter[key]);
    })
    let localFilters = {
      statistic: {...activeStatisticFilters},
      segmentation: localSegmentationFilters
    };

    try {
      let localData = await fetchData({
        surveyId: dataSource,
        questionId: column,
        weight: weight,
        query: queryText || " ",
        statistics: localFilters.statistic,
        segmentation: localFilters.segmentation,
        similarity: {},
        bins: bins,
        mode: "search",
        region: dataRegion || "US"
      });

      let rows = localData.rows;
      setData(rows);

      if (rows && rows.length > 0 && selectedBars.length === 0) {
        updateMinMax(rows);
        initializeBarSelection(rows);
      }
      return rows;
    } catch (err) {
      console.error("Error fetching similarity data", err);
      setData([]);
    }
    return []
  };


  const updateMinMax = (rows) => {
    if (rows && rows.length) {
      const minBin = Math.min(...rows.map(({binStart}) => binStart));
      setMin(minBin);
      const maxBin = Math.max(...rows.map(({binEnd}) => binEnd));
      setMax(maxBin);
      return [minBin, maxBin];
    }
    return [min, max];
  };

  useEffect(() => {
    const localSimilarityQuery = similarityQuery[policyId];
    if (localSimilarityQuery !== queryText) {
      setQueryText(localSimilarityQuery);
      setTempQueryText(localSimilarityQuery || "");
    }

  }, [similarityQuery]);

  useEffect(async () => {
    if (activePolicyTab === policyId) {
      if (queryText) {
        setIsLoading(true);
        await loadBins(false);
        setIsLoading(false);
      }
    }
  }, [queryText, bins, activeSegmentationFilters, activeStatisticFilters, treeMapFilter, activeInsightsFilters]);


  useEffect(() => {
    setTempQueryText(queryText || "");
  }, [queryText]);


  function setRangeBarSelection(localData, minRange, maxRange) {
    let left = Infinity;
    let right = -Infinity;
    let localSelectedBars = [];
    if (localData && localData.length && queryText) {
      let answerCount = 0;
      for (let i = 0; i < localData.length; i++) {
        let {binStart, binEnd, value} = localData[i];
        binStart = parseFloat(binStart.toFixed(3));
        binEnd = parseFloat(binEnd.toFixed(3));
        if (binStart >= minRange && binEnd <= maxRange) {
          localSelectedBars.push(i);
          if (binStart <= left) {
            left = binStart;
          }
          if (binEnd >= right) {
            right = binEnd;
          }
          answerCount += value;
        }
      }
      setCurrentAnswerCount(answerCount);
      let similarityFilter = {...activeSimilarityFilters}
      let localSimilarityFilter = activeSimilarityFilters[policyId] ? {...activeSimilarityFilters[policyId]} : {}
      localSimilarityFilter["distance"] = {
        low: parseFloat(left.toFixed(3)),
        high: parseFloat(right.toFixed(3)),
      }
      similarityFilter[policyId] = localSimilarityFilter
      dispatch(
        setSimilarityFilter(similarityFilter)
      );
    }
    setSelectedBars(localSelectedBars);
  }

  const initializeBarSelection = (rows) => {
    if (queryText) {
      let localData = data;
      if (rows && rows.length) {
        localData = rows;
      }
      let minRange = initRangeBar[0];
      let maxRange = initRangeBar[1];
      let localSimilarityFilter = activeSimilarityFilters[policyId] ? {...activeSimilarityFilters[policyId]} : {}
      if (localSimilarityFilter?.distance) {
        minRange = localSimilarityFilter?.distance?.low
        maxRange = localSimilarityFilter?.distance?.high
      }
      setRangeBarSelection(localData, minRange, maxRange);
    }
  }

  useEffect(async () => {
    let localData = data;
    if (!data || data?.length === 0) {
      localData = await loadBins(false);
    }
    if (localData?.length > 0 && queryText) {
      let localSimilarityFilter = activeSimilarityFilters[policyId] ? {...activeSimilarityFilters[policyId]} : {}
      if (localSimilarityFilter?.distance) {
        let minRange = localSimilarityFilter?.distance?.low
        let maxRange = localSimilarityFilter?.distance?.high
        let localSelectedBars = [];
        let answerCount = 0;
        let left = Infinity;
        let right = -Infinity;
        for (let i = 0; i < data.length; i++) {
          let {binStart, binEnd, value} = data[i];
          binStart = parseFloat(binStart.toFixed(3));
          binEnd = parseFloat(binEnd.toFixed(3));
          if (binStart >= minRange && binEnd <= maxRange) {
            localSelectedBars.push(i);
            if (binStart < left) {
              left = binStart;
            }
            if (binEnd > right) {
              right = binEnd;
            }
            answerCount += value;
          }
        }
        setCurrentAnswerCount(answerCount);
        setSelectedBars(localSelectedBars);
      }
    }
  }, [activeSimilarityFilters]);

  const handleSelectedBarsChange = useCallback(
    async (selectedBars) => {
      let localSelectedBars = selectedBars.bars;
      let similarityFilter = {...activeSimilarityFilters}
      let policySimilarityFilter = {...activeSimilarityFilters[policyId] || {}}
      if (localSelectedBars?.length && data) {
        setIsLoading(true);
        let minBin = +Infinity;
        let maxBin = -Infinity;
        let answerCount = 0;
        const thresholds = localSelectedBars.map((i) => {
          let index = Math.max(0, i);
          let left = parseFloat(data[index].binStart.toFixed(3));
          let right = parseFloat(data[index].binEnd.toFixed(3));
          if (left < minBin) {
            minBin = left
          }
          if (right > maxBin) {
            maxBin = right
          }
          answerCount += data[index].value;
          return [left, right];
        });

        if (thresholds.length) {
          setCurrentAnswerCount(answerCount);
          const low = thresholds[0][0];
          const high = thresholds[thresholds.length - 1][thresholds[thresholds.length - 1].length - 1];
          if (low !== undefined && low !== null && !isNaN(low) && high) {
            policySimilarityFilter["distance"] = {
              low: parseFloat(low.toFixed(3)),
              high: parseFloat(high.toFixed(3)),
            }
          }
        }

        similarityFilter[policyId] = policySimilarityFilter
        dispatch(
          setSimilarityFilter(similarityFilter)
        );
        setSelectedBars(localSelectedBars);
        setIsLoading(false);
      } else {
        delete policySimilarityFilter["distance"]
        similarityFilter[policyId] = policySimilarityFilter
        dispatch(
          setSimilarityFilter(similarityFilter)
        );
        setSelectedBars([])
      }
    },
    [column, data, id]
  );

  const handleSearchChange = (event) => {
    const inputText = event.target.value;
    setTempQueryText(inputText);
    if (inputText === '' && inputText !== queryText) {
      setQueryText(inputText);
    }
  };

  useEffect(() => {
    if (tempQueryText !== queryText && tempQueryText === '') {
      setQueryText(tempQueryText);
      dispatch(setSimilarityQuery({[policyId]: ''}))
    }
  }, [tempQueryText]);

  const submitQuery = async () => {
    if (queryText !== tempQueryText) {
      setQueryText(tempQueryText);
      dispatch(setSimilarityQuery({[policyId]: tempQueryText}));
    }
  }


  const handleWidgetConfig = () => {
    setShowBinSlider(prevState => !prevState);
    if (gearRef && gearRef.current) {
      const rect = gearRef.current.getBoundingClientRect();
      setBinSliderLocation({x: rect.x, y: rect.y});
    }
  }


  const SearchInputProp = () => {
    const handleClearQuery = () => {
      setQueryText('');
      setTempQueryText('');
      dispatch(setSimilarityQuery({[policyId]: ''}));
    };

    return (
      <InputAdornment position="end">
        <MuiSearchIconButton
          disabled={isLoading}
          onClick={!queryText ? ((e) => submitQuery()) : (() => handleClearQuery())}
        >
          {(!queryText) ? (
            <MuiSearchIcon sx={{
              width: '20px',
              height: '20px',

            }}/>) : (
            <ClearIcon
              sx={{
                width: '20px',
                height: '20px',

              }}
              color='primary'
            />)}
        </MuiSearchIconButton>
      </InputAdornment>
    )

  }

  return (
    <Box
      id={'similarity_' + id}
      sx={{width: '100%', maxHeight: '320px'}}>
      <LoadingProvider>
        {(isLoading) ? <MuiLinearProgress/> : null}
        <Grid item container direction='row' xs={12}
              alignItems='center' justifyContent={'flex-start'}
              sx={{width: '100%', marginTop: '18px'}}>
          <Grid item xs={11} alignItems={'flex-start'} alignContent={'center'}>
            <MuiTextField
              variant="standard"
              value={tempQueryText}
              onChange={(!isLoading) ? handleSearchChange : () => {
              }}
              placeholder={t('search_field')}
              disabled={isLoading}
              onKeyDown={async (evt) => {
                if (evt.key === 'Enter') {
                  await submitQuery();
                }
              }}
              maxRows={3}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    {SearchInputProp()}
                  </InputAdornment>
                ),
                style: {alignItems: 'center', minHeight: '30px'},
                disableUnderline: true,
              }}
              style={{flexGrow: 1, width: '100%'}}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton ref={gearRef}
                        onClick={handleWidgetConfig}>
              <TuneIcon color="primary"/>
            </IconButton>
          </Grid>
        </Grid>
        <Container
          sx={{...containerSimilarityStyles, padding: '0px !important'}}>
          {(queryText) && (
            <>
              <Divider sx={{...dividerStyles}}/>
              <WrapperWidgetUI title={title} widgetId={id}
                               wrapperProps={wrapperProps}>
                <Box sx={{paddingLeft: '10px', paddingRight: '10px'}}>
                  <SimilarityHistogramWidgetUI
                    id={id}
                    data={data?.sort((a, b) => a.binStart - b.binStart).map((d) => d.value)}
                    dataAxis={data?.sort((a, b) => a.binStart - b.binStart).map((d) => d.binEnd)}
                    onSelectedBarsChange={handleSelectedBarsChange}
                    selectedBars={selectedBars}
                    tooltip={tooltip}
                    formatter={formatter}
                    tooltipFormatter={tooltipFormatter}
                    xAxisFormatter={xAxisFormatter}
                    yAxisFormatter={formatter}
                    color={theme.palette.primary.main}
                    query={queryText}
                    answerCount={currentAnswerCount}
                    isLoading={isLoading}
                  />
                </Box>
              </WrapperWidgetUI>
            </>
          )
          }
          <Divider sx={{...dividerStyles, marginTop: '5px'}}/>
        </Container>
        {(showBinSlider) && (
          <PopoverUI
            isSimilarityHistogram={true}
            initRangeBar={initRangeBar}
            handleInitRangeCommited={setInitRangeBar}
            minBarRange={min}
            maxBarRange={max}
            showBinsSlider={showBinSlider}
            setShowBinSlider={setShowBinSlider}
            amountBins={bins}
            handleBinSizeCommited={setBins}
            position={binSliderLocation}
          />
        )}
      </LoadingProvider>
    </Box>
  );
}


export default SimilaritySearchWidget;
