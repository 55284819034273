import React, {createRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Collapse,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

import {useDispatch, useSelector} from 'react-redux';
import {setWidgetFoldedState} from '../../../store/appSlice';
import FilterIcon from '@mui/icons-material/FilterList';
import {
  MuiBoxContent,
  MuiBoxRoot,
  MuiExpandLess,
  MuiExpandMore,
  MuiGridActions,
  MuiGridHeader,
  MuiIconButton,
  MuiLinearProgress,
  MuiPaper,
  MuiTypography
} from '../styles/wrapperWidgetUI';
import {useTheme} from "../../providers/CustomThemeProvider";
import PopoverDescription from "./PopoverDescription";

function WrapperWidgetUI(props) {
  const wrapper = createRef();
  const dispatch = useDispatch();
  const {
    disabled = false,
    options = [],
    actions = [],
    optionsIcon = <MoreVert/>,
    disabledWidget = false,
    widgetId = '',
    expandable = true,
    expanded,
    filterEnabled = false,
    showDescription = false,
    setShowDescription,
    location = '',
    description = ''
  } = props;

  const foldedState = useSelector(state => state.app.widgetFoldedState);
  const [anchorEl, setAnchorEl] = useState(null);
  const gearRef = useRef(null);
  const [expand, setExpand] = useState(expanded);
  const open = Boolean(anchorEl);
  const {theme} = useTheme();

  const handleExpandClick = () => {
    if (expandable) {
      dispatch(setWidgetFoldedState({widgetId: widgetId, foldedState: expand}));
      setExpand(!expand);
    }
  };

  useEffect(() => {
    let globalExpanded = !foldedState[widgetId]
    if (expand !== globalExpanded) {
      setExpand(globalExpanded);
    }
  }, [foldedState]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionAction = (action) => {
    if (action) {
      action();
    }

    handleClose();
  };


  const iconButtonTooltip = (action) => {
    return (
      action.show ? (
        <MuiIconButton
          key={action.id}
          aria-label={action.label}
          onClick={() => {
            if (action.action) {
              action.action();
            }
            if (action.setModalLocation) {
              if (gearRef && gearRef.current) {
                const rect = gearRef.current.getBoundingClientRect();
                action.setModalLocation({x: rect.x, y: rect.y});
              }
            }
          }}
          disabled={action.disabled}
          color={action.color ? action.color : 'primary'}
          ref={gearRef}
          style={{
            marginTop: '5px',
            backgroundColor: !!action.backgroundColor ? action.backgroundColor : 'transparent'
          }}
          sx={{
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            backgroundColor: !!action.backgroundColor ? action.backgroundColor : 'transparent'
          }}
        >
          {action.icon}
        </MuiIconButton>
      ) : null
    );
  };

  if (disabled) {
    return props.children;
  }

  return (
    <MuiBoxRoot component='section' aria-label={props.title}
                style={{height: "100%", maxWidth: '100%'}}>
      {props.isLoading ? <MuiLinearProgress/> : null}
      <MuiGridHeader container
                     style={(expand ? {minHeight: theme.spacing(4)} : {height: theme.spacing(6)})}>
        <Button
          endIcon={
            props.expandable && (
              <Icon>
                {expand ? (
                  <MuiExpandLess/>
                ) : (
                  <MuiExpandMore/>
                )}
              </Icon>
            )
          }
          onClick={handleExpandClick}
          disabled={disabledWidget}
          sx={{
            backgroundColor:'transparent',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Tooltip title={props.title} placement='top' arrow>
            <MuiTypography
              align='left'
              variant='subtitle1'
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...(expand ? {
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical'
                } : {
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }),
              }}>
              {props.title}
            </MuiTypography>
          </Tooltip>
        </Button>

        <MuiGridActions item>
          {actions.length > 0 &&
            actions.map((action) => {
              return action.tooltip ? (
                <Tooltip
                  key={action.id}
                  title={action.tooltip.text}
                  placement={action.tooltip.placement || 'top'}
                >
                  {iconButtonTooltip(action)}
                </Tooltip>
              ) : (
                iconButtonTooltip(action, disabledWidget)
              );
            })}

          {options.length > 0 && (
            <div>
              <MuiIconButton
                aria-label='options'
                aria-controls='options-menu'
                aria-haspopup='true'
                onClick={handleClick}
              >
                {optionsIcon}
              </MuiIconButton>
              <Menu
                id='options-menu'
                elevation={3}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right'
                }}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{component: MuiPaper}}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.id}
                    selected={option.selected}
                    onClick={() => handleOptionAction(option.action)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
          {(filterEnabled) && (
            <MuiIconButton
              style={{
                marginTop: '5px',
                backgroundColor:'transparent'
              }}
              sx={{
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                backgroundColor: 'transparent'
              }}
            >
              <FilterIcon color='secondary'
                          style={{
                            width: '16px',
                            height: '16px'
                          }}/>
            </MuiIconButton>
          )
          }
        </MuiGridActions>
      </MuiGridHeader>
      {(showDescription) && (
        <PopoverDescription
          showDescription={showDescription}
          setShowDescription={setShowDescription}
          position={location}
          description={description}
        />
      )}
      <Collapse ref={wrapper} in={expand} timeout='auto' unmountOnExit>
        <MuiBoxContent>{props.children}</MuiBoxContent>
      </Collapse>
    </MuiBoxRoot>
  );
}

WrapperWidgetUI.defaultProps = {
  expandable: true,
  isLoading: false,
  disabled: false
};

WrapperWidgetUI.propTypes = {
  title: PropTypes.string.isRequired,
  expandable: PropTypes.bool,
  onExpandedChange: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      icon: PropTypes.element,
      action: PropTypes.func
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired
    })
  ),

  margin: PropTypes.number
};

export default WrapperWidgetUI;
