import {Grid} from "@mui/material";
import CustomAISegment from "./CustomAISegment";
import * as React from "react";
import {useEffect} from "react";
import _ from "lodash";
import {generateRandomString} from "../../../../../../../../utils/text";
import {
  MuiAddButton,
  MuiEmptyTopicsHeadingContainer,
  MuiHighlightedText,
  MuiStartIcon
} from "./styles/aiSegments";
import {
  ReactComponent as AISegmentImage
} from "../../../../../../../../assets/img/ai_segments.svg";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";

export const AISegments = ({
                             question,
                             collection,
                             handleQuestionChange,
                             dataRegion
                           }) => {
  const [enableCustomSegmentations, setEnableCustomSegmentations] = React.useState(false);

  const {t, lng} = useTranslation();
  const highlightedText = {
    "spanish": "AGREGAR SEGMENTO",
    "english": "ADD SEGMENT",
    "portuguese": "ADICIONAR SEGMENTO",
    "french": "AJOUTER UN SEGMENT",
    "arabic": "ADD SEGMENT"
  }

  const getEmptyTopicsText = () => {
    const text = t('empty_segment_heading')
    const [before, after] = text.split(highlightedText[lng])
    return (
      <MuiEmptyTopicsHeadingContainer>
        {before}
        <MuiHighlightedText>&nbsp;"{highlightedText[lng]}"&nbsp;</MuiHighlightedText>
        {after}
      </MuiEmptyTopicsHeadingContainer>
    )
  }

  const initialize = () => {
    if (question.customSegmentations && question.customSegmentations?.segmentations?.length > 0) {
      setEnableCustomSegmentations(true);
    } else {
      setEnableCustomSegmentations(false);
    }
  }


  const addCustomSegmentation = () => {
    let placeHolderCategories = [];
    for (let i = 0; i < 3; i++) {
      placeHolderCategories.push({
        topic: `Custom category ${i + 1}`,
        description: `Custom category ${i + 1} description`,
        representativity: 0
      })
    }
    let localQuestion = _.cloneDeep(question);
    if (!localQuestion.customSegmentations?.segmentations) {
      localQuestion.customSegmentations = {
        enabled: true,
        segmentations: []
      }
    }
    localQuestion.customSegmentations.segmentations.push({
      categories: placeHolderCategories,
      label: "Custom segmentation",
      classificationVariable: `${question.answerVar}_custom_${generateRandomString(10)}_label`,
      classificationPerformed: false
    });
    localQuestion.customSegmentations.enabled = true;
    handleQuestionChange(localQuestion);
  }


  useEffect(() => {
    initialize();
  }, [question]);


  const handleDeleteSegment = (deleteIndex) => {
    let localQuestion = _.cloneDeep(question);
    let localSegmentDefinitions = [...(localQuestion.customSegmentations?.segmentations||[])];
    localSegmentDefinitions.splice(deleteIndex, 1);
    if (localSegmentDefinitions.length === 0) {
      setEnableCustomSegmentations(false);
      localQuestion.customSegmentations.enabled = false;
    }
    localQuestion.customSegmentations.segmentations = localSegmentDefinitions;
    handleQuestionChange(localQuestion);
  };

  const handleUpdateSegment = (newSegment, index) => {
    let localQuestion = _.cloneDeep(question);
    localQuestion.customSegmentations.segmentations[index] = newSegment;
    handleQuestionChange(localQuestion);
  };


  return (
    <Grid item container direction='column'>
      <>
        {(enableCustomSegmentations && question.customSegmentations?.segmentations?.length > 0) && (
          question.customSegmentations.segmentations.map((segment, index) => {

            return (
              <CustomAISegment
                key={`custom-segmentation-cmp-${index}`}
                segment={segment}
                segmentIndex={index}
                collection={collection}
                answersVariable={question.answerVar}
                dataRegion={dataRegion}
                handleDeleteSegment={handleDeleteSegment}
                handleUpdateSegment={handleUpdateSegment}
              />
            )
          })
        )
        }
        <Grid item>
          <MuiAddButton
            variant="outlined"
            onClick={addCustomSegmentation}
            startIcon={<MuiStartIcon/>}
          >
            {t('add_segment')}
          </MuiAddButton>
        </Grid>
        {(!enableCustomSegmentations || question.customSegmentations?.segmentations?.length === 0) && (
          <Grid container item alignContent='center' justifyContent='center'
                direction='column'>
            <Grid container item direction='row' justifyContent='center' sx={{
              pr: '179px',
              pl: '179px',
              pt: '70px',
              pb: '0px'
            }}>
              <AISegmentImage width={'100%'} height={'auto'}/>
            </Grid>
            <Grid container item sx={{
              paddingLeft: '179px',
              paddingRight: '179px',
              mt: '22px'
            }}>
              {getEmptyTopicsText()}
            </Grid>
          </Grid>
        )}
      </>
    </Grid>
  );
}
