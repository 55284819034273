import * as React from 'react';

import {
  Box,
  Divider,
  Typography,
} from '@mui/material';
import 'tippy.js/dist/tippy.css';

import QAWidget from './qa/QAWidget';
import SimilaritySearchWidget from './similarity/SimilaritySearchWidget';
import ListWidget from './list/ListWidget';
import {useTranslation} from "../../providers/TranslationProvider";
import {useTheme} from "../../providers/CustomThemeProvider";
import FilterIcon from '@mui/icons-material/FilterList';
import {useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {
  divTabStyle,
  titleTabStyle,
  MuiTabsInsights,
  MuiTab
} from './similarity/styles/SimilarityWidget';
import SegmentsWidget from "./category/SegmentsWidget";

const Widget = ({widget, activeTab, index, activePolicyTab}) => {
  const {t} = useTranslation();

  return (
    <Box style={{maxHeight: '100%', height: '100%', marginBottom: '5px'}}>
      {widget.type === 'similarity' && (
        <>
          <SimilaritySearchWidget
            mode={'search'}
            id={widget.id}
            widgetId={widget.id}
            title={t('semantic_analysis')}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            ticks={[]}
            formatter={(v) => Number(v).toFixed(3)}
            xAxisformatter={(v) => Number(v).toFixed(3)}
            color={{
              enabled: false,
              type: 'simple',
              customColors: [],
            }}
            global={true}
            description='not_description'
            wrapperProps={{
              description: widget.description,
              expanded: !widget.folded_state,
              disabledWidget: false,
              widgetId: widget.id,
              actions: [],
            }}
            weight={widget.weight}
            policyId={widget.policyId}
            statVars={widget.statisticVariables}
            segVars={widget.segmentationVariables}
            answerVarLabel={widget.answerVarLabel}
          />
        </>
      )}
      {widget.type === 'qa' && (
        <>
          <QAWidget
            mode={'query'}
            id={widget.id}
            widgetId={widget.id}
            title={t('similarity_qa_mode')}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            ticks={[]}
            formatter={(v) => Number(v).toFixed(3)}
            xAxisformatter={(v) => Number(v).toFixed(3)}
            color={{
              enabled: false,
              type: 'simple',
              customColors: [],
            }}
            global={true}
            description='not_description'
            wrapperProps={{
              description: widget.description,
              expanded: !widget.folded_state,
              disabledWidget: false,
              widgetId: widget.id,
              actions: [],
            }}
            weight={widget.weight}
            policyId={widget.policyId}
            statVars={widget.statisticVariables}
            segVars={widget.segmentationVariables}
            answerVarLabel={widget.answerVarLabel}
            originalQuestionText={widget.originalQuestionText}
            analysisObjective={widget.analysisObjective}
            contextDescription={widget.contextDescription}
            audienceDescription={widget.audienceDescription}
            mainTopics={widget.mainTopics}
            hints={widget.hints}
          />
        </>
      )}
      {widget.type === 'list' && (
        <>
          <ListWidget
            id={widget.id}
            title={t('quotes')}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            audioColumn={widget.audioColumn || null}
            policyId={widget.policyId}
            style={{width: '100%'}}
            wrapperProps={{
              expanded: true,
              description: widget.description,
              widgetId: widget.id,
              disabledWidget: false,
              actions: [],
            }}
            activeTab={activeTab}
            index={index}
            activePolicyTab={activePolicyTab}
            policyTab={widget.policyTabIndex}
          />
        </>
      )}
      {widget.type === 'segments' && (
        <>
          <SegmentsWidget
            id={widget.id}
            title={widget.name}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            answerVar={widget.answerVar}
            weight={widget.weight}
            policyId={widget.policyId}
            widgetType={'segments'}
            predefinedCategories={widget.categories}
            wrapperProps={{
              expanded: !widget.folded_state,
              description: widget.description,
              disabledWidget: false,
              actions: [],
            }}
          />
          <Divider style={{marginTop: '10px'}}/>
        </>
      )}
    </Box>
  );
};

const AIPanel = ({widgets, policyId, height, activePolicyTab}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeInsightsFilters = useSelector(state => state.app.insightsFilter);
  const [filtersEnable, setFiltersEnable] = React.useState(false);
  const [searchEnable, setSearchEnable] = React.useState(false);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const analysisVisualizationType = useSelector(state => state.app.analysisVisualizationType);
  const {t} = useTranslation();
  const {theme} = useTheme();

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabDisplacement = useMemo(() => {
    return widgets.some(w => w.type === 'segments') ? 0 : 1;
  }, [widgets]);

  useEffect(() => {
    const hasSegmentationFilters = !!activeInsightsFilters[policyId] && Object.keys(activeInsightsFilters[policyId] || {}).length > 0;
    const hasSimilarityFilters = !!activeSimilarityFilters[policyId] && Object.keys(activeSimilarityFilters[policyId] || {}).length > 0 && !!similarityQuery[policyId];
    setFiltersEnable(hasSegmentationFilters);
    setSearchEnable(hasSimilarityFilters);
  }, [activeInsightsFilters, activeSimilarityFilters, policyId, similarityQuery]);

  useEffect(() => {
    if (analysisVisualizationType[policyId] === "bars") {
      setActiveTab(2 - tabDisplacement);
    } else {
      setActiveTab(0);
    }
  }, [analysisVisualizationType]);

  if (widgets?.length) {
    const definedSegments = widgets?.filter(w => w.type === 'segments');
    return (
      <Box style={{
        flexDirection: 'column',
        maxHeight: height,
        height: height,
        maxWidth: '100%',
        overflowY: 'hidden'
      }}
           id={'widgets-box'}
      >
        <MuiTabsInsights
          value={activeTab}
          onChange={handleChangeTab}
          aria-label="widget tabs"
          TabIndicatorProps={{
            sx: {
              backgroundColor: theme.palette.secondary.main,
              marginBottom: '10px'
            }
          }}
          variant={'scrollable'}
          scrollButtons="auto"
        >
          {(definedSegments?.length > 0) &&
            <MuiTab key={'ai-search-tab'}
                    label={<TabLabel title={`AI ${t('search')}`}
                                     enable={filtersEnable}/>}/>}
          <MuiTab key={'qa-tab'}
                  label={<TabLabel title='Q&A' enable={false}/>}/>
          {/*<MuiTab key={'search-tab'}*/}
          {/*        label={<TabLabel title='Search' enable={searchEnable}/>}/>*/}
          <MuiTab key={'answers-tab'}
                  label={<TabLabel title={t('answers')}
                                   enable={searchEnable}/>}/>
        </MuiTabsInsights>

        <Box style={{height: '95%', maxHeight: '100%', overflow: 'hidden'}}
             id={'ai-tabs-box'}>
          {/* Tab content with conditional rendering */}
          <div style={{
            display: activeTab === 0 && (definedSegments?.length > 0) ? 'block' : 'none',
            padding: '10px 0px 10px 10px', maxHeight: '100%', overflowY: 'auto'
          }}>
            {(definedSegments && definedSegments.length > 0) ? (
              definedSegments?.map(w => {
                return (
                  <TabContent key={w.id} widget={w} activeTab={activeTab}
                              index={0} activePolicyTab={activePolicyTab}/>
                )
              })
            ) : (
              <div>
                <Typography variant='subtitle2'>
                  No segments
                </Typography>
              </div>
            )}
          </div>

          <div style={{
            display: activeTab === 1 - tabDisplacement ? 'block' : 'none',
            overflow: 'hidden', maxHeight: '100%', height: height
          }} id={'qa-div-box'}>
            {widgets?.filter(w => w.type === 'qa').map(w => (
              <TabContent key={w.id} widget={w} activeTab={activeTab}
                          index={1 - tabDisplacement}
                          activePolicyTab={activePolicyTab}/>
            ))}
          </div>
          <div style={{
            display: activeTab === 2 - tabDisplacement ? 'block' : 'none',
            overflowY: 'hidden', height: height
          }}>
            {widgets?.filter(w => w.type === 'similarity' || w.type === 'list')
              .sort((a, b) => a.type === 'similarity' ? -1 : 1)
              .map(w => {
                return (
                  <TabContent key={w.id} widget={w} activeTab={activeTab}
                              index={2 - tabDisplacement}
                              activePolicyTab={activePolicyTab}/>
                )
              })}
          </div>
        </Box>
      </Box>

    );
  }
  return null;
};

function TabLabel({title, enable}) {
  return (
    <div style={divTabStyle}>
      <span style={titleTabStyle}>{title}</span>
      {enable && <FilterIcon style={{marginLeft: '5px'}} color='secondary'/>}
    </div>
  );
}

function TabContent({widget, tabHeight, activeTab, index, activePolicyTab}) {
  return (
    <div style={{backgroundColor: '#FAFAFA'}}
         key={`widget-content-${widget.id}`}>
      {widget.visible &&
        <Widget widget={widget} height={tabHeight} activeTab={activeTab}
                index={index} activePolicyTab={activePolicyTab}/>}
    </div>
  );
}

export default AIPanel;
