import {Button, IconButton, lighten, styled} from "@mui/material";
import Color from "color";
import SplitIcon from "@mui/icons-material/CallSplit";
import AddIcon from "@mui/icons-material/Add";
import {darken} from "@mui/material/styles";
import * as React from "react";

export const MuiSplitIconButton = styled(IconButton)(({theme}) => ({
  borderColor: Color(theme.palette.primary.main).lighten(0.8).hex(),
  borderWidth: '1px',
  borderStyle: 'solid',
  width: '26px',
  height: '26px'
}));

export const MuiSplitIcon = styled(SplitIcon)(({theme}) => ({
  rotate: '180deg',
  height: '16px', width: '16px', color: theme.palette.primary.main
}));

const MuiAddTopicIcon = styled(AddIcon)(({theme}) => ({
  width: '18px',
  height: '18px',
  color: darken(theme.palette.primary.main, 0.3)
}))

export const MuiBadgeIndicator = styled('span')(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color:'#FFFFFF',
  borderRadius: '8px',
  width: '16px',
  height: '16px',
  minWidth:'16px',
  minHeight:'16px',
  fontFamily: 'Montserrat',
  fontSize: '10px',
  padding:'2px 4px',
  fontWeight: 500,
  marginLeft:'4px'
}));

export const MuiSecondaryDiscoverTopicsButton = styled((props) => {
  return (
    <Button startIcon={<MuiSplitIcon/>} variant='outlined' {...props}>
    </Button>
  )
})(({theme}) => ({
  color: darken(theme.palette.primary.main, 0.3),
  border: `1px solid ${darken(theme.palette.primary.main, 0.3)}`,
  width:'155px',
  textWrap:'nowrap',
  height: '26px',
  fontFamily:'Montserrat',
  fontSize:'10px',
  textTransform:'uppercase',
  fontWeight:500,
  lineHeight:'12px',
  display:'flex',
  alignItems:'center',
  letterSpacing:'0.0025em',
  fontStyle:'normal',
  borderRadius:'13px',
  marginRight:'5px'
}))
export const MuiAddSubtopicButton = styled((props) => {
  return (
    <Button
      {...props}
      variant='outlined'
      startIcon={<MuiAddTopicIcon/>}
    ></Button>
  )
})(({theme}) => ({
  color: darken(theme.palette.primary.main, 0.3),
  height: '38px',
  padding: '10px 24px 10px 16px',
  alignContent: 'center',
  borderRadius: '20px',
  border: `1px solid ${lighten(theme.palette.primary.main, 0.3)}`
}))
