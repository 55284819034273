import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  SvgIcon,
  TextField, Typography
} from "@mui/material";
import {lighten} from "@mui/material/styles";
import {
  ReactComponent as SmartLightBulb
} from '../../../../../assets/img/smart_lightbulb.svg'
import HistoryIcon from '@mui/icons-material/History';

export const MuiQueryTextField = styled((props) => (<TextField
  {...props}
  variant="standard"
  fullWidth
  multiline
  maxRows={4}
/>))(({theme}) => ({
  fontSize: '14px',
  fontFamily: 'Montserrat',
  marginLeft: '1px',
  width: '100%',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '22px',
    minHeight: '42px',
    padding: '10px 5px 10px 24px', // Adjust padding to center content
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    display: 'flex', // Flexbox for alignment
    alignItems: 'center', // Align text to the top for multiline
  },
  '& .MuiInputBase-inputMultiline': {
    padding: '0px', // Ensure no padding conflicts with the content alignment
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.005em',
    boxSizing: 'border-box',
    color: '#143440',
    lineHeight: '18px', // Ensure proper line height for readability
    minHeight: 'auto', // Allow multiline to grow with content
    display: 'flex',
    alignItems: 'center', // Centers the text inside each row
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '16px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    },
  },
  '& input': {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    padding: '0px', // Ensure no vertical padding
    height: '100%', // Take full height of the container
    display: 'flex', // Flexbox for alignment
    alignItems: 'center', // Centers text vertically
    letterSpacing: '0.005em',
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    lineHeight: '18px', // Adjust line height for proper centering
    color: '#143440',
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '16px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    }
  },
}));

export const MuiTextField = styled(TextField)(({theme}) => ({
  marginBottom: theme.spacing(0),
  fontSize: '14px',
  fontFamily: 'Montserrat',
  marginLeft: '1px',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '22px',
    minHeight: '42px',
    padding: '10px 10px 10px 38px', // Adjust padding to center content
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    display: 'flex', // Flexbox for alignment
    alignItems: 'flex-start', // Align text to the top for multiline
  },
  '& .MuiInputBase-inputMultiline': {
    padding: '0px', // Ensure no padding conflicts with the content alignment
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.005em',
    boxSizing: 'border-box',
    color: '#143440',
    lineHeight: '1.5', // Ensure proper line height for readability
    minHeight: 'auto', // Allow multiline to grow with content
    display: 'flex',
    alignItems: 'center', // Centers the text inside each row
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '16px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    },
  },
  '& input': {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    padding: '0px', // Ensure no vertical padding
    height: '100%', // Take full height of the container
    display: 'flex', // Flexbox for alignment
    alignItems: 'center', // Centers text vertically
    letterSpacing: '0.005em',
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    lineHeight: '1.5', // Adjust line height for proper centering
    color: '#143440',
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '16px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    }
  },
}));

export const MuiLinearProgress = styled(LinearProgress)(({theme}) => ({
  top: 0,
  left: 0,
  width: '100%',
  height: theme.spacing(0.25)
}));

export const MuiQAGrid = styled(Grid)(({theme}) => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto'
}));

export const dividerStyles = {
  width: '100%',
  marginTop: '16px',
  marginBottom: 0
};

const QAPathIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M17.5 5H15.8333V12.5H5V14.1667C5 14.625 5.375 15 5.83333 15H15L18.3333 18.3333V5.83333C18.3333 5.375 17.9583 5 17.5 5Z"
      fill="white"/>
    <path
      d="M13.3337 1.6665H2.50033C2.08366 1.6665 1.66699 2.08317 1.66699 2.49984V14.1665L5.00033 10.8332H13.3337C13.7503 10.8332 14.167 10.4165 14.167 9.99984V2.49984C14.167 2.08317 13.7503 1.6665 13.3337 1.6665ZM8.89824 6.81442L7.91699 8.95817L6.93574 6.81442L4.79199 5.83317L6.93574 4.85192L7.91699 2.70817L8.89824 4.85192L11.042 5.83317L8.89824 6.81442Z"
      fill="white"/>
  </SvgIcon>
);

// Then, wrap it with styled-components to apply styles
export const QAIcon = styled(QAPathIcon)(({theme}) => ({
  width: '20px',
  height: '20px',
}));

export const QAButton = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  }
}));

export const MuiGridFullHistory = styled(Grid)(({theme}) => ({
  width: '100%',
  overflowY: 'auto',
  maxHeight: '100%',
  paddingLeft: '8px'
}));

export const MuiTypography = styled(Typography)(({theme}) => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  color: '#424242',
  fontSize: '16px'
}));

export const MuiTextDate = styled(Typography)(({theme}) => ({
  marginTop: '0px',
  marginBottom: '8px',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  color: '#757575',
  fontSize: '10px',
  letterSpacing: '0.015em'
}));

export const MuiContent = styled(Typography)(({theme}) => ({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  color: '#424242',
  fontSize: '14px'
}));

export const MuiInfoFullHistory = styled(Typography)(({theme}) => ({
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontWeight: 400,
  color: '#616161',
  fontSize: '12px'
}));

export const MuiBoxContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  borderRadius: '12px',
  padding: '8px 8px 8px 12px',
  flexDirection: 'row',
  backgroundColor: '#f5f5f5',
  width: '100%',
  cursor: 'pointer'
}));

export const NuiHistoryButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'showFullHistory'
})(({theme, showFullHistory}) => ({
  backgroundColor: lighten(showFullHistory ? theme.palette.primary.main : '#616161', 0.9),
  marginTop: '5px',
  padding: '6px',
  border: '1px solid',
  borderColor: lighten(showFullHistory ? theme.palette.primary.main : '#616161', 0.6),
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    borderColor: lighten(theme.palette.primary.main, 0.6),
  }
}));

export const MuiSwitchButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({theme, selected}) => ({
  backgroundColor: lighten(selected ? theme.palette.primary.main : '#616161', 0.9),
  padding: '6px',
  border: '1px solid',
  borderColor: lighten(selected ? theme.palette.primary.main : '#616161', 0.6),
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  '&:hover': {
    backgroundColor: lighten(selected ? theme.palette.primary.main : '#616161', 0.9),
    borderColor: lighten(selected ? theme.palette.primary.main : '#616161', 0.6),
  }
}));

export const MuiSmartLightBulb = styled(SmartLightBulb, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({theme, selected}) => ({
  width: '20px',
  height: '20px',
  '& path': {
    fill: selected ? theme.palette.primary.main : '#616161',
  },
}));

export const MuiHistoryIcon = styled(HistoryIcon, {
  shouldForwardProp: (prop) => prop !== 'showFullHistory'
})(({theme, showFullHistory}) => ({
  color: showFullHistory ? theme.palette.primary.main : '#616161',
}));
