import {DialogActions, styled, Typography} from '@mui/material';

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  textAlign:'left',
  marginBottom:'5px',
  fontWeight:500,
  fontStyle:'normal',
  fontFamily:'Raleway',
  color:'#212121'
}));

export const MuiDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '24px 24px',
}));

export const TypographyStyle = {
  flexGrow: 1,
  fontSize: '25px',
  textAlign: 'left',
  fontFamily: 'Raleway'
}

export const DialogStyle = {
  margin: 'auto',
  borderTop:'none',
  '& .MuiPaper-root': {
    borderRadius: '28px',
  },
}

export const DialogTitleStyle = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between'
}
