import {Box, Grid, IconButton, Modal, Typography} from "@mui/material";
import {
  MuiAddCategoryButton,
  MuiModalControls,
  MuiOutlinedButton
} from "./styles/segmentModal";
import {Download} from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import * as React from "react";
import {useEffect, useState} from "react";
import {CategoryForm} from "./CategoryForm";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {normalizeString} from "../../../../../../../../utils/text";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";

export const SegmentsModal = ({
                                open,
                                handleClose,
                                segment,
                                handleUpdateSegment
                              }) => {

  const [segmentCategories, setSegmentCategories] = useState(segment?.categories || []);
  const {t} = useTranslation();

  const handleExportCategories = () => {
    const jsonData = JSON.stringify(segment.categories, null, 2);
    const blob = new Blob([jsonData], {type: 'application/json'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${normalizeString(segment.label).replace(/ /g, "_")}_categories.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedData = JSON.parse(e.target.result);
        setSegmentCategories(importedData);
      } catch (error) {
        console.error('Error parsing JSON file', error);
      }
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    setSegmentCategories(segment?.categories || []);
  }, []);

  const onSave = () => {
    const localSegment = {...segment, categories: segmentCategories};
    handleUpdateSegment(localSegment);
    handleClose();
  }

  const onCategoryChange = (category, index) => {
    const newCategories = [...segmentCategories];
    newCategories[index] = category;
    setSegmentCategories(newCategories);
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '940px',
          maxHeight: '519px',
          border: '1px solid #FFFFFF',
          backgroundColor: '#FFFFFF',
          borderRadius: '28px',
          padding: '24px'
        }}>
        <Grid container direction='column' rowGap={'16px'}>
          <Grid container item direction='row' alignContent='center'
                justifyContent='space-between'>
            <Grid item xs={'auto'}>
              <Typography sx={{
                fontFamily: 'Raleway',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '25px',
                lineHeight: '29px',
                color: '#000000',
              }}>
                {t('segment_categories') || 'Segment categories'}
              </Typography>
            </Grid>
            <Grid xs={true} container item direction='row' alignContent='center'
                  justifyContent='flex-end' columnGap={'16px'}>
              <MuiOutlinedButton
                variant='outlined'
                startIcon={<Download/>}
                onClick={handleExportCategories}
              >
                {t('export')}
              </MuiOutlinedButton>
              <input type="file" onChange={handleImport}
                     style={{display: 'none'}}
                     id="importFile" accept=".json"/>
              <label htmlFor="importFile">
                <MuiOutlinedButton
                  variant='outlined'
                  type={'file'}
                  component={'span'}
                  startIcon={<UploadIcon/>}
                >
                  {t('import')}
                </MuiOutlinedButton>
              </label>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} sx={{mt: '25.5px'}}>
            <Grid container item sx={{width: '35%'}} alignContent='flex-start'>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14px',
                letterSpacing: '0.004em'
              }}>
                {t('name') || 'Name'}:
              </Typography>
            </Grid>
            <Grid container sx={{width: '55%', paddingLeft: '8px'}} item
                  alignContent='flex-start'>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14px',
                letterSpacing: '0.004em'
              }}>
                {t('description') || 'Description'}:
              </Typography>
            </Grid>
          </Grid>
          {
            segmentCategories.map((category, index) => {
              return (
                <Grid container direction={"row"} item columnGap={'10px'}
                      alignContent='center'>
                  <Grid container item xs={true} alignContent='center'>
                    <CategoryForm category={category} index={index}
                                  handleCategoryChange={onCategoryChange}/>
                  </Grid>
                  <Grid container item xs={'auto'} alignContent='center'>
                    <IconButton
                      color="error"
                      sx={{
                        '&:hover': {backgroundColor: "#FFCFD2"},
                        width: '26px',
                        height: '26px',
                        border: '1px solid #FFCFD2',
                        borderRadius: '18px',
                      }}
                      disabled={segmentCategories.length === 1}
                      onClick={() => {
                        const newCategories = [...segmentCategories];
                        newCategories.splice(index, 1);
                        setSegmentCategories(newCategories);
                      }}
                    >
                      <DeleteIcon
                        sx={{color: '#F63B2B', width: '16px', height: '16px'}}/>
                    </IconButton>
                  </Grid>
                </Grid>
              )
            })
          }
          <Grid container item direction='row'>
            <MuiAddCategoryButton onClick={() => {
              const newCategories = [...segmentCategories];
              newCategories.push({
                topic: `Custom category ${segmentCategories.length + 1}`,
                description: `Custom category ${segmentCategories.length + 1} description`,
                representativity: 0
              });
              setSegmentCategories(newCategories);
            }}>{t('add_category')}</MuiAddCategoryButton>
          </Grid>
          <Grid container item justifyContent='flex-end' columnGap={'16px'}
                sx={{mt: '16px'}}>
            <MuiModalControls
              variant='outlined'
              onClick={handleClose}
            >
              {t('cancel_btn')}
            </MuiModalControls>
            <MuiModalControls
              variant='outlined'
              onClick={onSave}
              disabled={!segmentCategories || segmentCategories.length === 0}
            >
              {t('save')}
            </MuiModalControls>
          </Grid>
        </Grid>

      </Box>
    </Modal>
  )
}
