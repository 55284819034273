import {Button, styled} from "@mui/material";
import {darken} from "@mui/material/styles";

export const MuiCancelActionButton = styled(Button)(({ theme }) => ({
  padding: '10px 24px',
  borderRadius: '20px',
  height: '37px',
  width: '112px',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  letterSpacing: '0.0025em',
  color:darken(theme.palette.primary.main,0.3),
}));

export const MuiNextActionButton = styled(Button)(({ theme }) => ({
  padding: '10px 24px',
  borderRadius: '20px',
  height: '37px',
  width: '126px',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  letterSpacing: '0.0025em',
  color:darken(theme.palette.primary.main,0.3),
  borderColor:darken(theme.palette.primary.main,0.3)
}))
