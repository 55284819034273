import {useLocation} from "react-router-dom";
import {useAuth} from "../../../../providers/AuthProvider";
import useOrganization from "../../../../../api/hooks/useOrganization";
import * as React from "react";
import {useEffect} from "react";
import useCreateScheme from "../../../../../api/hooks/useCreateScheme";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {useLoading} from "../../../../providers/LoadingProvider";
import {Grid, Typography} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {UploadStep} from "./UploadStep";
import axios from "../../../../../api/axios/axiosInstance";
import {
  getImportJob,
  uploadCollectionS3
} from "../../../../../api/uploadCollection";
import {useSnackbar} from "notistack";
import {CategorizeVariables} from "./CategorizeVariables";
import {ConfigureAudio} from "./ConfigureAudio";
import {ConfigureLabelsDescriptions} from "./ConfigureLabelsDescriptions";
import {setTargetCollection} from "../../../../../store/appSlice";
import {useDispatch} from "react-redux";
import {
  MuiCancelActionButton,
  MuiNextActionButton
} from "./styles/createDataset";

export const CreateDataset = ({onClose, setOpenFinishModal}) => {
  const location = useLocation();
  const {user} = useAuth();
  const userId =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const {data: organization} = useOrganization(location.pathname.split('/')[2] === 'workspace' ? user.org_id : userId);
  const [datasetName, setDatasetName] = React.useState('')
  const [region, setRegion] = React.useState(organization?.defaultRegion || 'US')
  const [uploadOption, setUploadOption] = React.useState('local');
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [url, setUrl] = React.useState('');
  const [collectionName, setCollectionName] = React.useState('');
  const [variables, setVariables] = React.useState([]);
  const {mutateAsync: createScheme} = useCreateScheme();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const {
    setIsLoading,
    setProgress,
    setProgressMessage,
    setTitle
  } = useLoading();
  const [activeStep, setActiveStep] = React.useState(0);
  const [lastStep, setLastStep] = React.useState(1);

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  useEffect(() => {
    setLastStep(variables?.some(v => v.category === 'audio') ? 3 : 2);
  }, [variables]);

  const handleNext = async () => {
    if (activeStep === 0 && datasetName && (uploadedFile !== null)) {
      await handleUpload({
        datasetName: datasetName,
        region: region,
        file: uploadedFile,
        url: url
      })
    } else if (activeStep === lastStep) {
      await handleSave();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleUpload = async (data) => {
    setTitle('Uploading your data to our servers...')
    setIsLoading(true);
    setProgress(0);
    if (datasetName) {
      if (uploadOption === 'local') {
        let file = uploadedFile;
        let formData = new FormData();
        formData.append('image_file', file);
        let axiosResp = await axios.post(`${process.env.REACT_APP_API_URL}/collection/upload`, formData).then((resp) => resp.data);
        if (axiosResp) {
          let dataUrl = {
            url: axiosResp.file
          };
          await handleFileUploadFromS3(dataUrl);
        }
      } else if (uploadOption === 'link') {
        await handleFileUploadFromS3(data);
      }
    }
  };

  const handleFileUploadFromS3 = async (formData) => {
    setIsLoading(true);
    setProgress(0);
    setProgressMessage('Processing your data...');
    let importJobId;
    if (datasetName) {
      const data = {
        user_id: userId,
        datasetName: [userId, datasetName.trim()].join('_'),
        s3Info: {
          url: formData.url.trim(),
        },
        region: region,
      };


      uploadCollectionS3(data).then((res) => {
        if (res && res.status === 200) {
          setProgressMessage(t('starting_file_process'));
          importJobId = res.data.data;
          let isFirstTimeQueryingJobStatus = true;

          // To control failed requests.
          let intervalMaxFailedRequest = 10;
          let intervalFailedRequestCount = 0;

          // Periodically query import job status.
          let intervalHandler = setInterval(() => {
            if (intervalFailedRequestCount === intervalMaxFailedRequest) {
              setIsLoading(false);
              clearInterval(intervalHandler);
            }

            getImportJob(importJobId)
              .then(async (res) => {
                if (res && res.status === 200) {
                  if (isFirstTimeQueryingJobStatus) {
                    setProgressMessage('');
                    isFirstTimeQueryingJobStatus = false;
                  }

                  let importJob = res.data.data;

                  if (importJob.status === 'in_progress') {
                    setProgressMessage(t('importing'));
                  }

                  if (importJob.status === 'error') {
                    setProgressMessage('');
                    // setUploadErrors(importJob.jobErrors);
                    errorToast(importJob.jobErrors?.join('\n'));
                    setIsLoading(false);
                    clearInterval(intervalHandler);
                  }

                  if (importJob.status === 'completed' || importJob.status === 'success' || importJob.status === 'warning') {
                    setIsLoading(false);
                    setProgressMessage(t('import_complete'));
                    clearInterval(intervalHandler);

                    // Get the dataset variables from the backend.
                    await getDatasetVariables(importJob.collectionName, importJobId, importJob.region);

                    setCollectionName(importJob.collectionName);
                    setRegion(importJob.region)
                  }
                } else {
                  intervalFailedRequestCount++;
                }
              })
              .catch((error) => {
                console.error(error);
                intervalFailedRequestCount++;
              });
          }, 2000);
        }
      });
    }
  };

  const getDatasetVariables = async (collectionName, importJobId, region) => {
    try {
      let axiosResp = await axios.get(
        `/collection/get-dataset-variables?datasetName=${collectionName}&idImportedJob=${importJobId}&region=${region}`
      );

      if (axiosResp && axiosResp.status === 200) {
        let response = axiosResp.data;
        let currentVariables = null;

        if (response.status === 'ok') {
          currentVariables = Object.entries(response.data).map((v) => {
            let category = 'ignore'
            if (v[1].label?.toLowerCase().includes('weight') || v[1].propName?.toLowerCase().includes('weight')) {
              category = 'weight'
            } else if (v[1].classification !== 'any') {
              category = v[1].classification
            } else if (v[1].type === 'object') {
              category = 'audio'
            }
            return {
              label: v[1].label,
              propName: v[1].propName,
              category: category,
              type: v[1].type
            };
          });
          setVariables(currentVariables);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleSave = async () => {
    let filterVars = variables.filter((d) => d.category !== 'ignore');
    if (filterVars.length === 0) {
      errorToast(t('no_variables_warning'));
      return;
    }
    const datasetDefinition = {
      name: datasetName,
      collection: collectionName,
      variables: filterVars,
      region: region,
    }
    const payload = {
      collection: datasetDefinition,
      region: region,
    };
    if (userId) {
      payload.user_id = userId;
    }
    dispatch(setTargetCollection(datasetDefinition))
    setIsLoading(true);
    await createScheme(payload);
    setIsLoading(false);
    onClose();
    setOpenFinishModal(true);
  };

  const isStepValid = () => {
    if (activeStep === 0) {
      return datasetName?.length > 3 && ((uploadOption === 'local' && uploadedFile) || (uploadOption === 'link' && url?.startsWith("https://") || url?.startsWith("http://") || url?.startsWith("s3://")));
    } else if (activeStep === 1) {
      return variables?.length > 0 && variables.some(v => v.category === 'answer') && variables.some(v => v.category === 'weight');
    }
    return true;
  }
  return (
    <Grid container direction='column'
          sx={{width: '100%', height: '100%'}} alignContent='flex-start'>
      <Grid item>
        <Typography sx={{
          fontFamily: 'Raleway',
          fontSize: '25px',
          lineHeight: '29px',
          color: '#000000'
        }}>
          {t('upload_new_dataset')}
        </Typography>
      </Grid>
      <Grid container item xs={true} direction='row'
            justifyContent='flex-start'
            sx={{maxHeight: '46px'}}>
        <Stepper activeStep={activeStep} orientation='horizontal'
                 sx={{width: '100%'}}>
          <Step key={'upload-step'}>
            <StepLabel
              data-cy={0}
              sx={{
                '& .MuiStepIcon-text': {
                  fill: '#FFFFFF',
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  fontFamily: 'Montserrat'
                }
              }}
            >
              <Typography style={{
                textTransform: 'uppercase',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: 12,
                color: activeStep === 0 ? '#212121' : '#9E9E9E'
              }}
              >
                {t('upload_data')}
              </Typography>
            </StepLabel>
          </Step>
          <Step key={'configure-variables'}>
            <StepLabel
              data-cy={1}
              sx={{
                '& .MuiStepIcon-text': {
                  fill: '#FFFFFF',
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  fontFamily: 'Montserrat'
                }
              }}
            >
              <Typography style={{
                textTransform: 'uppercase',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: 12,
                color: activeStep === 1 ? '#212121' : '#9E9E9E'
              }}
              >
                {t('dataset_variable_configuration')}
              </Typography>
            </StepLabel>
          </Step>
          <Step key={'configure-labels'}>
            <StepLabel sx={{
              '& .MuiStepIcon-text': {
                fill: '#FFFFFF',
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '20px',
                fontFamily: 'Montserrat'
              }
            }}>
              <Typography style={{
                textTransform: 'uppercase',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: 12,
                color: activeStep === 1 ? '#212121' : '#9E9E9E'
              }}
              >
                {t('labels_descriptions')}
              </Typography>
            </StepLabel>
          </Step>
          {lastStep > 2 && (
            <Step key={'configure-audio'}>
              <StepLabel
                data-cy={2}
                sx={{
                  '& .MuiStepIcon-text': {
                    fill: '#FFFFFF',
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    fontFamily: 'Montserrat'
                  }
                }}
              >
                <Typography style={{
                  textTransform: 'uppercase',
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  fontSize: 12,
                  color: activeStep === 2 ? '#212121' : '#9E9E9E'
                }}
                >
                  {t('audio_bindings')}
                </Typography>
              </StepLabel>
            </Step>
          )}
        </Stepper>
      </Grid>
      <Grid container xs={true} item justifyContent='flex-start'
            id={'step-controls-container'} direction='column'
            sx={{
              maxWidth: '800px',
              maxHeight: '450px',
              overflowY: 'hidden',
              mt: '8px'
            }}>
        {activeStep === 0 && (
          <UploadStep datasetName={datasetName}
                      updateDatasetName={setDatasetName} region={region}
                      updateRegion={setRegion} sourceType={uploadOption}
                      updateSourceType={setUploadOption}
                      uploadedFile={uploadedFile}
                      updateUploadedFile={setUploadedFile}
                      url={url}
                      updateUrl={setUrl}
          />
        )}
        {activeStep === 1 && (
          <CategorizeVariables variables={variables}
                               updateVariables={setVariables}/>
        )}
        {activeStep === 2 && (
          <ConfigureLabelsDescriptions variables={variables}
                                       updateVariables={setVariables}/>
        )}
        {activeStep === 3 && (
          <ConfigureAudio variables={variables}
                          updateVariables={setVariables}/>
        )}
      </Grid>
      <Grid container item direction='row' xs={true}
            justifyContent='flex-end' sx={{
        maxHeight: '37px',
        gap: '8px',
        mt: '24px'
      }}>
        <MuiCancelActionButton
          onClick={() => onClose()}
        >
          {t('cancel_generic_btn')}
        </MuiCancelActionButton>
        <MuiCancelActionButton
          disabled={activeStep <= 1}
          onClick={handleBack}
        >
          {t('go_back')}
        </MuiCancelActionButton>
        <MuiNextActionButton
          onClick={handleNext}
          variant='outlined'
          disabled={!isStepValid()}
        >
          {activeStep === lastStep ? t('finish') : t('next_step')}
        </MuiNextActionButton>
      </Grid>
    </Grid>
  )
}
