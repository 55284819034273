import * as React from 'react';
import {useEffect, useState} from 'react';
import _ from 'lodash';
import {useLoading} from '../../../../providers/LoadingProvider';
import {useDispatch, useSelector} from 'react-redux';
import axiosEngineInstance from "../../../../../api/axios/axiosEngineInstance";
import {status200} from '../../../../../api/status.utils';
import enginePaths from "../../../../../api/enginePaths";
import {Grid, SvgIcon, ToggleButton} from '@mui/material';
import NivoTreeMap from "./tree/NivoTreeMap";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import {Leaderboard} from "@mui/icons-material";
import {
  setAnalysisVisualizationType,
  setSegmentationFilter,
  setTreemapLoadingProgress
} from "../../../../../store/appSlice";
import BarGraph from "./bars/BarGraph";
import {normalizeString} from "../../../../../utils/text";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {MUIToggleGroup} from "./styles/treePage";


export default function AnalysisPage(
  {activeTab, index, tabData, showProgressBar}) {
  const [treeAggregationData, setTreeAggregationData] = useState([]);
  const [barAggregationData, setBarAggregationData] = useState(null);
  const [policyTopics, setPolicyTopics] = useState(tabData?.config?.policy?.topics);
  const [lastPayload, setLastPayload] = useState(null);
  const [lastBarAggPayload, setLastBarAggPayload] = useState(null);
  const {
    setIsLoading,
    setTreeMapLoading,
    setTitle,
    setShowProgressBar,
    setProgress,
    setProgressMessage
  } = useLoading();
  const {t} = useTranslation();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const categoryFilter = useSelector(state => state.app.segmentationFilter)

  const statisticFilter = useSelector(state => state.app.statisticFilter);

  const similarityQuery = useSelector(state => state.app.similarityQuery);

  const similarityFilter = useSelector(state => state.app.similarityFilter)

  const activeInsightsFilter = useSelector(state => state.app.insightsFilter[tabData?.config?.policy?.id]);

  const treeMapFilter = useSelector(state => state.app.treeMapFilter);

  const referenceAggTopicSegment = useSelector(state => state.app.referenceAggTopicSegment);

  const analysisVisualizationType = useSelector(state => state.app.analysisVisualizationType);

  const [policyAnalysisVisualizationType, setPolicyAnalysisVisualizationType] = useState(analysisVisualizationType[tabData?.config?.policy?.id] || 'tree');
  const dispatch = useDispatch();

  const fetchDataEngine = async (url, payload, updateProgress = false) => {
    try {
      // if (updateProgress) {
      //   if (payload.size) {
      //     dispatch(setTreemapTotalLoadingProgress({
      //       id: tabData.config.policy.id,
      //       progress: payload.size || 0
      //     }));
      //     delete payload.size
      //   }
      // }

      const response = await axiosEngineInstance.post(url, payload, {
        ...status200,
        onDownloadProgress: (progressEvent) => {
          dispatch(setTreemapLoadingProgress({
            id: tabData.config.policy.id,
            progress: (progressEvent.loaded / tabData.config.policy.size) || 0
          }));
        }
      });
      return response.data;
    } catch (err) {
      console.log("Error fetching data", err);
      return [];
    }
  };


  const fetchEngineTreeMapData = (payload) => fetchDataEngine(enginePaths.aggregate_answers, payload, showProgressBar);

  const fetchEngineBarData = (payload) => fetchDataEngine(enginePaths.aggregate_topics_segments, payload);

  async function loadEngineTreeAggData() {
    if (tabData.config) {
      const answerVar = tabData.config.policy.answerVar;
      const localSegmentationFilter = {...categoryFilter, ...(activeInsightsFilter || {})}
      delete localSegmentationFilter[answerVar]
      Object.keys(treeMapFilter).forEach(key => {
        if (key !== tabData.config.policy.id) {
          Object.assign(localSegmentationFilter, treeMapFilter[key])
        }
      })
      const localSimilarityQuery = similarityQuery[tabData.config.policy.id] || ""
      let localSimilarityFilter = {}
      if (localSimilarityQuery) {
        localSimilarityFilter = similarityFilter[tabData.config.policy.id] || {}
      }


      const iconsInPolicyTopics = tabData.config.policy.topics.map((topic) => {
        return topic.icon ? 1 : 0
      }).reduce((acc, curr) => acc + curr) === tabData.config.policy.topics.length;
      let payload = {
        surveyId: tabData.config.collection_name,
        questionId: answerVar,
        audioVariable: tabData.config.policy.audioVar || null,
        weight: tabData.config.weight,
        slice: tabData.config.policy.amountSamples ? tabData.config.policy.amountSamples : 5,
        segmentation: localSegmentationFilter,
        statistics: statisticFilter,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilter,
        pick_closest: parseBoolean(process.env.REACT_APP_PICK_CLOSEST_ANSWER),
        includeIcons: !iconsInPolicyTopics,
        topics: tabData.config.policy.topics,
        customSegments: tabData.config.policy.customSegmentations,
        maxClassificationTopics: tabData.config.policy.maxClassificationTopics,
        size: tabData.config.policy.size,
        region: tabData.config.collection_region || "US"
      }
      if (treeAggregationData?.length > 0 && lastPayload) {
        if (_.isEqual(payload, lastPayload)) {
          return;
        }
      }
      if (analysisVisualizationType[tabData.config.policy.id] !== 'bars') {
        setTitle(t('treemap_loader_title'));
        setShowProgressBar(showProgressBar);
        setProgressMessage('')
        setProgress(0);
        setTreeMapLoading(prevState => ({
          ...prevState,
          [tabData.config.policy.id]: true
        }));
        setLastPayload(payload);
      }
      let localAggregationData = await fetchEngineTreeMapData(payload);
      if (localAggregationData && Array.isArray(localAggregationData)) {
        localAggregationData = localAggregationData.map((item) => {
          try {
            if (Array.isArray(item[tabData.config.policy.answerVar + "_label"])) {
              item[tabData.config.policy.answerVar + "_label"] = item[tabData.config.policy.answerVar + "_label"].join(", ")
            }
            item["weight"] = item[tabData.config.weight]
          } catch (e) {
            console.log(tabData.config.policy.answerVar);
            console.log(item);
          }
          return item;
        });
        setTreeAggregationData(localAggregationData);
      }

      if (analysisVisualizationType[tabData.config.policy.id] !== 'bars') {
        setTreeMapLoading(prevState => ({
          ...prevState,
          [tabData.config.policy.id]: false
        }));
      }
      return localAggregationData;
    }
    return [];
  }

  const loadBarsAggData = async () => {
    if (tabData.config && analysisVisualizationType[tabData.config.policy.id] === 'bars' || (!barAggregationData || barAggregationData.data.length === 0)) {
      const answerVar = tabData.config.policy.answerVar;
      const localSegmentationFilter = {...categoryFilter, ...(activeInsightsFilter || {})}
      Object.keys(treeMapFilter).forEach(key => {
        if (key !== tabData.config.policy.id) {
          Object.assign(localSegmentationFilter, treeMapFilter[key])
        }
      });
      delete localSegmentationFilter[answerVar + "_label"]
      const localSimilarityQuery = similarityQuery[tabData.config.policy.id] || ""
      let localSimilarityFilter = {}
      if (localSimilarityQuery) {
        localSimilarityFilter = similarityFilter[tabData.config.policy.id] || {}
      }
      const policyReferenceAggSegment = referenceAggTopicSegment[tabData.config.policy.id] || null
      if (policyReferenceAggSegment) {
        delete localSegmentationFilter[policyReferenceAggSegment];
      }
      const iconsInPolicyTopics = tabData.config.policy.topics.map((topic) => {
        return topic.icon ? 1 : 0
      }).reduce((acc, curr) => acc + curr) === tabData.config.policy.topics.length;
      let payload = {
        surveyId: tabData.config.collection_name,
        region: tabData.config.collection_region || "US",
        questionId: answerVar,
        weight: tabData.config.weight,
        segmentation: localSegmentationFilter,
        statistics: statisticFilter,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilter,
        includeIcons: !iconsInPolicyTopics,
        mainTopics: tabData.config.policy.topics,
        segmentAttribute: policyReferenceAggSegment
      }
      if (barAggregationData?.data.length > 0 && lastBarAggPayload) {
        if (_.isEqual(payload, lastBarAggPayload)) {
          return;
        }
      }
      setLastBarAggPayload(payload);
      if (analysisVisualizationType[tabData.config.policy.id] === 'bars') {
        setIsLoading(true);
      }

      let localAggregationData = await fetchEngineBarData(payload);
      if (analysisVisualizationType[tabData.config.policy.id] === 'bars') {
        setIsLoading(false);
      }
      setBarAggregationData(localAggregationData);
      return localAggregationData;
    }
    return {data: [], keys: [], indexBy: "topic"};
  }


  const updateAggData = React.useCallback(async () => {
    if (treeAggregationData.length > 0 && activeTab === index) {
      await loadEngineTreeAggData();
    }
  }, [categoryFilter, statisticFilter, similarityFilter, treeMapFilter, activeInsightsFilter, activeTab, analysisVisualizationType]);

  const updateBarAggData = React.useCallback(async () => {
    await loadBarsAggData();
  }, [categoryFilter, statisticFilter, similarityFilter, treeMapFilter, activeInsightsFilter, activeTab, referenceAggTopicSegment, analysisVisualizationType]);


  React.useEffect(async () => {
    await updateAggData()
  }, [updateAggData]);


  React.useEffect(async () => {
    await updateBarAggData();
  }, [updateBarAggData]);

  React.useEffect(async () => {
    if (treeAggregationData?.length === 0) {
      const localAggregationData = await loadEngineTreeAggData();
      if (localAggregationData) {
        let sortedTopics = representativitySortedTopics(localAggregationData, tabData.config.policy.topics, tabData.config.policy.answerVar)
        setPolicyTopics(sortedTopics)
      }
    }
  }, []);

  function representativitySortedTopics(localData, policyTopics, answerVar) {
    let localPolicyTopics = policyTopics.map((pt) => {
      let ptData = findTopicInData(pt.topic, answerVar, localData);
      if (ptData) {
        return {
          ...pt,
          representativity: ptData.weight
        }
      } else if (pt.subtopics?.length > 0) {
        let totalRepresentativity = 0;
        pt.subtopics.forEach((st) => {
          let stData = findTopicInData(st.topic, answerVar, localData);
          if (stData) {
            totalRepresentativity += stData.weight;
          }
        });
        return {
          ...pt,
          representativity: totalRepresentativity
        }
      }
      return {
        ...pt,
        representativity: 0
      }
    });
    return localPolicyTopics.sort((a, b) => a.representativity - b.representativity);
  }

  const findTopicInData = (topicName, answerVar, data) => {
    function isTopicInData(dataTopic, policyTopicName) {
      const normalizedDataTopic = normalizeString(dataTopic[answerVar + "_label"])
      const normalizedName = normalizeString(policyTopicName);
      return normalizedDataTopic === normalizedName;
    }

    return data.find((dataTopic) => isTopicInData(dataTopic, topicName))
  }


  function parseBoolean(str) {
    try {
      return JSON.parse(str.toLowerCase());
    } catch (e) {
      console.error('Invalid value for parsing to boolean:', str);
      return null;  // or throw an error, or return a default value, etc.
    }
  }


  useEffect(() => {
    // Function to update window height
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const value = analysisVisualizationType[tabData.config.policy.id];
    if (value !== policyAnalysisVisualizationType && value === 'bars') {
      updateAnalysisType(value)
    }
  }, [analysisVisualizationType]);

  function updateAnalysisType(value) {
    if (analysisVisualizationType[tabData.config.policy.id] !== "bars") {
      let localSegmentationFilter = {...categoryFilter}
      const policyReferenceAggSegment = referenceAggTopicSegment[tabData.config.policy.id] || null
      if (policyReferenceAggSegment) {
        delete localSegmentationFilter[policyReferenceAggSegment];
        dispatch(setSegmentationFilter(localSegmentationFilter));
      }
    }
    setPolicyAnalysisVisualizationType(value)
    const updatedVisualizationType = _.clone(analysisVisualizationType)
    updatedVisualizationType[tabData.config.policy.id] = value
    dispatch(setAnalysisVisualizationType(updatedVisualizationType))
  }

  const ToggleGraph = () => {
    const selectedStyle = {
      '&.Mui-selected': {
        borderRadius: '12px',
        height: '24px',
        width: '32px',
        margin: '4px 8px 4px 4px'
      },

    }
    const hasImages = tabData.config.policy.topics.filter(t => !!t.image_url).length === tabData.config.policy.topics.length;
    return hasImages || (!tabData.config.isSegmentedPolicy || !!tabData.config.policy.parentPolicy) ? (
      <MUIToggleGroup exclusive value={policyAnalysisVisualizationType} sx={{
        height: '32px',
        width: ((tabData.config.isSegmentedPolicy && !tabData.config.policy.parentPolicy) || !hasImages) ? '75px' : '125px',
        margin: '0px',
        padding: '0px 0px 0px 0px',
        borderRadius: '16px',
        border: '1px solid #E0E0E0',
      }}
                      onChange={(event, value) => {
                        updateAnalysisType(value);
                      }}
      >
        <ToggleButton value={'tree'}
                      sx={policyAnalysisVisualizationType === 'tree' ? selectedStyle : {
                        borderRadius: '16px',
                        border: '0px solid #E0E0E0',
                        backgroundColor: 'transparent',
                        height: '24px',
                        width: '32px',
                        margin: '3px 4px 4px 4px',
                      }}>
          <SpaceDashboardIcon sx={{
            width: '16px',
            height: '16px',
          }}/>
        </ToggleButton>
        {(!tabData.config.isSegmentedPolicy || !!tabData.config.policy.parentPolicy) && (
          <ToggleButton
            value={'bars'}
            sx={policyAnalysisVisualizationType === 'bars' ? selectedStyle : {
              borderRadius: '16px',
              border: '0px solid #E0E0E0',
              backgroundColor: 'transparent',
              height: '24px',
              width: '32px',
              margin: '4px 4px 4px 4px',
            }}
          >
            <Leaderboard
              style={{
                transform: 'rotate(90deg)'
              }}
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </ToggleButton>
        )}
        {hasImages && (
          <ToggleButton
            value={'art'}
            sx={policyAnalysisVisualizationType === 'art' ? selectedStyle : {
              borderRadius: '16px',
              border: '0px solid #E0E0E0',
              backgroundColor: 'transparent',
              height: '24px',
              width: '32px',
              margin: '3px 4px 4px 4px',
            }}
          >
            <SvgIcon sx={{
              width: '18px',
              height: '18px',
            }} viewBox="0 0 18 18">
              <path
                d="M11.9997 9.99984V13.3332L2.66634 13.3332L2.66634 3.99984L5.99967 3.99984C7.33301 3.33317 7.33301 3.33317 7.33301 3.33317C7.97301 2.6665 7.65301 2.99984 7.97301 2.6665L2.66634 2.6665C1.93301 2.6665 1.33301 3.2665 1.33301 3.99984L1.33301 13.3332C1.33301 14.0665 1.93301 14.6665 2.66634 14.6665L11.9997 14.6665C12.733 14.6665 13.333 14.0665 13.333 13.3332V10.6665H12.6663L11.9997 9.99984ZM10.9997 11.9998L3.66634 11.9998L5.49967 9.6465L6.80634 11.2198L8.63967 8.85984L10.9997 11.9998Z"
                fill={policyAnalysisVisualizationType === 'art' ? "#212121" : "#757575"}/>
              <path
                d="M12.475 7.72083L11.3333 8.26683L12.475 8.81283L13 10.0002L13.525 8.81283L14.6667 8.26683L13.525 7.72083L13 6.5335L12.475 7.72083ZM10.5 6.5335L11.2833 4.74816L13 3.9335L11.2833 3.11883L10.5 1.3335L9.71667 3.11883L8 3.9335L9.71667 4.74816L10.5 6.5335Z"
                fill={policyAnalysisVisualizationType === 'art' ? "#212121" : "#757575"}/>
            </SvgIcon>
          </ToggleButton>
        )}

      </MUIToggleGroup>
    ) : null;
  }
  const treeHeight = windowHeight - 130;
  const treeMaxHeight = windowHeight - 120;
  return (
    <div
      role='tabpanel'
      hidden={activeTab !== index}
      id={`simple-tabpanel-${tabData.id}`}
      aria-labelledby={`simple-tab-${index}`} style={{width: '100%'}}>

      <Grid container style={{
        minWidth: '60%',
        height: treeHeight,
        maxHeight: treeMaxHeight,
        width: '100%',
        flexGrow: 1,
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 5,
        position: 'relative',
        // overflowY:policyAnalysisVisualizationType === 'tree' ? 'hidden':'scroll'
      }}>

        <div
          id='graph-toggle-container'
          style={{
            width: '100px',
            height: '35px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-40px',
            marginTop: '10px',
            position: 'absolute',
            padding: 0,
            top: 0,
            right: 15,
            zIndex: 10 // Ensure it is on top
          }}>
          <ToggleGraph/>
        </div>

        {policyAnalysisVisualizationType !== 'bars' ? (
          <NivoTreeMap
            data={treeAggregationData}
            policy={tabData.config.policy}
            topics={policyTopics}
            visualizationType={policyAnalysisVisualizationType}
          />
        ) : (
          <BarGraph
            data={barAggregationData}
            policy={tabData.config.policy}
            segmentationVariables={tabData.config.segmentationVars}
          />
        )}
      </Grid>
    </div>
  );
}


