// External library imports
import {Grid, styled, Typography} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon
  from '@mui/icons-material/VisibilityOffOutlined';

// Internal component and function imports
import {TextField} from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';

export const MuiDivPaper  = styled("div")(({ theme }) => ({
  margin: theme.spacing(10, 1, 1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width:'556px'
}));

export const MuiForm = styled("form")(({ theme }) => ({
  width: '60%',
  marginTop: theme.spacing(0),
}));

export const MuiGridContainerButtons = styled(Grid)(({ theme }) => ({
  display: 'flex',
}));

export const MuiGoogleButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: 'black !important',
  border: '1px solid #6793A5',
  borderRadius: '20px',
  width:'312px',
  paddingLeft:'10px !important',
  '&:hover': {
    backgroundColor: '#EEEEEE',
  },
  marginBottom: '15px',
}));

export const buttonSocialMicrosoft = {
  fontSize: '13px',
  textTransform:'none',
  width: '100%',
  textAlign: 'left',
  marginLeft: '3em',
  fontStyle: 'normal',
  textColor: '212121',
  lineHeight: '20px'
}

export const MuiGridRoot = styled(Grid)(({ theme }) => ({
  height: '100vh',
}));

export const MuiDivImageGrid  = styled("div")(({ theme }) => ({
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Raleway',
  textAlign: 'center',
  width: '100%',
  marginLeft: '8px'
}));

export const MuiTypographyReset = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Raleway',
  width: 'auto',
  textAlign: 'left',
  paddingBottom: '20px',
  fontSize: '26px',
  marginLeft: '0px !important',
  fontWeight: 'bold' ,
}));

export const MuiButtonColored = styled(Button)(({ theme }) => ({
  color: 'white !important',
  marginTop:'5px !important',
  marginBottom: '15px',
  width: '312px',
  height: '37px',
  borderRadius: '20px',
  background: '#00BBD4',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Montserrat',
  padding: '10px 24px 10px 24px',
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  height: 'auto',
  width: '312px',
  fontSize: '12px',
  fontFamily: 'Montserrat',
  textTransform: 'none',
  color: '#143440',
  '& .MuiInputBase-root':{
    height:'56px',
    // paddingLeft: '16px',
    // paddingRight: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline':{
    borderColor:'#325D6C'
  },
  '& input::placeholder': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily:'Montserrat',
    letterSpacing:'0.5px',
    color:'#143440'
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily:'Montserrat',
    letterSpacing:'0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily:'Montserrat',
    letterSpacing:'0.4px'
  },
  '& input': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily:'Montserrat',
    letterSpacing:'0.5px'
  },
  '& legend': {
    fontSize: '14px',
  },
}));

export const MuiVisibilityIcon = styled(VisibilityOutlinedIcon)(({ theme }) => ({
  color:'#254957'
}));

export const MuiVisibilityOffIcon = styled(VisibilityOffOutlinedIcon)(({ theme }) => ({
  color:'#254957'
}));

export const circularProgressStyle = {
  color: 'black'
}

export const spacingStyle = {
  marginTop:'-40px'
}

export const buttonStyle = {
  width:'100%'
}

export const forgotPassStyle = {
  fontFamily:'Montserrat',
  color:'#143440',
  width:'100%',
  fontWeight: 400,
  height: '16px',
  fontSize: '12px',
  textTransform:'initial',
}

export const subTitleStyle = {
  fontSize: '21px',
  fontFamily: 'Raleway',
  fontWeight:500,
  width: '100%',
  textAlign: 'center',
  lineHeight: '20px'
}

export const buttonSocial = {
  fontSize: '13px',
  textTransform:'none',
  width: '100%',
  textAlign: 'left',
  marginLeft: '3em',
  fontStyle: 'normal',
  textColor: '212121',
  lineHeight: '20px'
}


export  const langContainer ={
  position: 'absolute',
  right: '10%',
  top: '28px'
}

export const fieldsForm = {
  height: 'auto',
  width: '312px',
  fontSize: '12px',
  fontFamily: 'Monserrat',
  textTransform: 'none',
  color: '#143440',
}
export const styleTitle = {
  color: '#00BBD4',
  textTransform: 'uppercase',
  fontSize: '45px'
}

export const gridResetContainerStyle = {
  width: '328px',
  marginTop:'-200px'
}

export const inputAdornmentStyle = {
  color: '#6793A5',
  fontSize:'24px'
}

export const inputPasswordConfirmation = {
  '& .MuiInputBase-root': {
    paddingLeft: '16px',
    paddingRight: '12px',
  }
}
