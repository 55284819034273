import {Button, styled} from "@mui/material";
import {darken} from "@mui/material/styles";
import BackupIcon from "@mui/icons-material/Backup";

const ImportIcon=styled(BackupIcon)(({theme}) => ({
  color: darken(theme.palette.primary.main,0.3),
  width:'18px',
  height:'18px'
}));

export const MuiImportConfigButton = styled((props) => {
  return (
    <Button
      variant='outlined'
      startIcon={<ImportIcon/>}
      {...props}
    >
      {props.children}
    </Button>
  )
})(({theme}) => ({
  height:'26px',
  borderRadius:'13px',
  fontFamily:'Montserrat',
  fontSize:'10px',
  lineHeight:'12px',
  letterSpacing:'0.0025em',
  textTransform:'uppercase',
  color: darken(theme.palette.primary.main,0.3),
  borderColor: darken(theme.palette.primary.main,0.3)
}));
