import {Button, Card, CardActions, Grid, styled} from "@mui/material";

export const MuiGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'start',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color:'#005F64',
  border: '1px solid #005F64',
  justifyContent: 'center',
  borderRadius: '20px',
  width:'106px',
  height:'38px',
  padding:'10px 24px 10px 24px',
  '&:hover': {
    border: '1px solid #005F64',
  }
}));

export const MuiCard = styled(Card)(({ theme }) => ({
  width: 106,
  height: 106,
  borderRadius: '12px !important',
  border: `1px solid #E0E0E0`,
}));

export const MuiGridCommon = styled(Grid)(({ theme }) => ({
  display:'flex',
  justifyContent:'center'
}));

export const MuiCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 106,
  height: 106,
  marginButton: 8,
}));

export const MuiImg= styled("img")(({ theme }) => ({
  objectFit: 'scale-down',
  width: 106,
  height: 106,
}));
