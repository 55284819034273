import {
  Grid,
  Radio,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import * as React from "react";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {MuiImportConfigButton} from "./styles/categorizeVariables";
import {useSnackbar} from "notistack";

export const CategorizeVariables = ({variables, updateVariables}) => {

  const {t} = useTranslation();
  const uploadConfigRef = React.useRef();

  const {enqueueSnackbar} = useSnackbar();

  const changeVariableCategory = (index, value) => {
    const newVariables = [...variables];
    newVariables[index].category = value;
    updateVariables(newVariables);
  }

  const handleConfigFileChange = (evt) => {
    // Check if file is selected
    if (!evt.target.files.length) return;

    const file = evt.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const configuration = JSON.parse(e.target.result);

      if (Object.keys(configuration).length === 0) {
        return enqueueSnackbar('Invalid configuration file', { variant: 'error' });
      }

      // Validate configuration
      const invalidConfigKeys = findInvalidConfigs(configuration);
      if (invalidConfigKeys.length > 0) {
        return enqueueSnackbar(
          'Invalid configuration for these variables: ' + invalidConfigKeys.join(', '),
          { variant: 'error' }
        );
      }

      // Update valid configurations
      const updatedVariables = updateValidConfigurations(configuration, variables);
      updateVariables(updatedVariables);

      // Reset the file input
      evt.target.value = null;
    };

    reader.readAsText(file);
  };

  const findInvalidConfigs = (configuration) => {
    return Object.entries(configuration)
      .filter(([_, config]) => !config.hasOwnProperty('category'))
      .map(([key]) => key);
  };

// Function to update valid configurations
  const updateValidConfigurations = (configuration, currentVariables) => {
    return currentVariables.map(variable => {
      const config = configuration[variable.propName];
      if (config) {
        return {
          ...variable,
          category: config.category,
          label: config.label || variable.propName,
          linkedAudio: config.linkedAudio || null,
          description: config.description || null,
        };
      }
      return variable;
    });
  };


  const insertBreakPoints = (str) => str.replace(/_/g, '_\u200B');
  return (
    <Grid container direction='column' item xs={true} sx={{height: '100%'}}>
      <Grid container xs={true} item justifyContent={'flex-end'}>
        <input type='file' onChange={handleConfigFileChange}
               ref={uploadConfigRef} style={{display:'none'}}/>
        <MuiImportConfigButton
          onClick={() => uploadConfigRef.current?.click()}
        >
          {t('import_variable_config')}
        </MuiImportConfigButton>
      </Grid>
      <Grid item xs={true}>
        <TableContainer component={"div"}
                        sx={{
                          maxHeight: '370px',
                          height: '370px',
                          width: '100%'
                        }}>
          <MuiTable stickyHeader size="small" aria-label='simple table'
                    sx={{width: '100%'}}>
            <TableHead>
              <TableRow>
                <TableCell sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                }}>{t('variable')}</TableCell>
                <TableCell sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                }}>{t('survey_answer')}</TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px'
                  }}>{t('audio_variable') || 'Audio'}</TableCell>
                <TableCell sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                }}>{t('categorical')}</TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                  }}>{t('statistic_column')}</TableCell>
                <TableCell sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px'
                }}>{t('weight_column')}</TableCell>
                <TableCell sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                }}>{t('ignore')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variables?.map((variable, index) => (
                <TableRow key={'d-' + index} data-cy='var_row'>
                  <TableCell component='th' scope='row'
                             sx={{maxWidth: '200px'}}>
                    <Typography sx={{
                      color: '#424242',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      letterSpacing: '0.0025em',
                      display: 'flex',
                      textAlign: 'left',
                      wordWrap: 'break-word', // Ensures words break when necessary
                      whiteSpace: 'normal',   // Allows the text to wrap naturally
                      width: '200px',         // Set the desired maximum width
                      // wordBreak:'break-all',
                      overflow: 'hidden',     // Ensure no horizontal scrolling if necessary
                    }}>
                      {insertBreakPoints(variable.propName)}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'
                             data-cy={'td_numeric_' + variable.propName}>
                    <Radio
                      sx={{width: '24px', height: '24px'}}
                      checked={variable.category === 'answer'}
                      value='answer'
                      data-cy={'radio_numeric_' + variable.propName}
                      name={variable.propName}
                      onChange={(e) => changeVariableCategory(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell align='center'
                             data-cy={'td_audio_' + variable.propName}>
                    <Radio
                      sx={{width: '24px', height: '24px'}}
                      checked={variable.category === 'audio'}
                      value='audio'
                      data-cy={'radio_audio_' + variable.propName}
                      name={variable.propName}
                      onChange={(e) => changeVariableCategory(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell align='center'
                             data-cy={'td_categorical_' + variable.propName}>
                    <Radio
                      sx={{width: '24px', height: '24px'}}
                      checked={variable.category === 'categorical'}
                      value='categorical'
                      data-cy={'radio_categorical_' + variable.propName}
                      name={variable.propName}
                      onChange={(e) => changeVariableCategory(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell align='center'
                             data-cy={'td_statistic_' + variable.propName}>
                    <Radio
                      sx={{width: '24px', height: '24px'}}
                      checked={variable.category === 'statistic'}
                      value='statistic'
                      data-cy={'radio_statistic_' + variable.propName}
                      name={variable.propName}
                      onChange={(e) => changeVariableCategory(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell align='center'
                             data-cy={'td_weight_' + variable.propName}>
                    <Radio
                      sx={{width: '24px', height: '24px'}}
                      checked={variable.category === 'weight'}
                      value='weight'
                      data-cy={'radio_weight_' + variable.propName}
                      name={variable.propName}
                      onChange={(e) => changeVariableCategory(index, e.target.value)}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <Radio
                      sx={{width: '24px', height: '24px'}}
                      checked={variable.category === 'ignore'}
                      value='ignore'
                      name={variable.propName}
                      onChange={(e) => changeVariableCategory(index, e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
